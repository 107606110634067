import { Field, FieldArray } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { RadioField, ReactAsyncSelect, ReactSelect, SelectField, TextArea, TextField, ReactAsyncCreatableSelect, ReactCreatableSelect } from '../../components/fields';
import { AlertMessage, RedAlert } from '../../components/helpers';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DoParties from '../Parties/DoParties';
import DoCase from './DoCase';
import memoize from 'memoize-one';
import UserProfile from '../../lib/UserProfile';
import { CaseTypes } from '../../lib/helpers';

const defaultConfig = {Title: "Title", ArtUnit: "Art Unit", PatentNo: "Patent No", PriorityDate: "Priority Date",
                        ProvisionalSN: "Provistional SN", IssueDate: "Issue Date",
                        Assignee: true, IntlClass: false, Reel: true, Frame: true, ApplicationNo: true,
                        RecordationDate: true, PubDate: true, PubNo: true};
const FieldsConfig = {
    patent: {...defaultConfig, Type: CaseTypes},
    mark: {...defaultConfig, Type: {Use: 4, ITU: 5}, Title: "Mark", ArtUnit: "Law Office ID", GoodsServices: true, IntlClass: true,
            PatentNo: "Reg No", PriorityDate: "Date first use", ProvisionalSN: false, IssueDate: "Grant Date",
            },
    other: {...defaultConfig, Type: false, ApplicationNo: false, ArtUnit: false, PatentNo: false, PriorityDate: false, PubNo: false,
            PubDate: false, Assignee: false, Reel: false, Frame: false, ProvisionalSN: false, RecordationDate: false}
};

export const CaseFields = props => {
    const {
        values,
        errors,
        handleChange,
        types,
        statuses,
        countries,
        typeTag
    } = props;

    const size = "sm";
    const [config, setConfig] = useState(FieldsConfig[typeTag]);
    const [tag, setTag] = useState(typeTag);

    const handleCaseTypeChange = evt => {
        handleChange(evt);
        let tag = evt.target.getAttribute("data-tag");
        setConfig(FieldsConfig[tag]);
        setTag(tag);
    };

    return (
        <Fragment>
            {errors && errors.length > 0 && <RedAlert>
                <p className="font-weight-bold">Something is wrong:</p>
                <ul>
                    {errors.map( (x, i) => <li key={`a${i}`}>{x}</li>)}
                </ul>
            </RedAlert>}
            {tag !== typeTag && <AlertMessage variant="warning">
                <span>Warning: </span>By changing the case type, some fields are hidden to match the selected type.
            </AlertMessage>}
            <Form.Row>
                <Col xs={8}>
                    <Form.Row>
                        <TextField required label="Matter #" type="text" as={Col} name="Case.Matter" value={values.Case.Matter} size={size} />
                        {config.ApplicationNo && <TextField type="text" as={Col} label="Application No" name="Case.ApplicationNo" value={values.Case.ApplicationNo} size={size} />}
                        <ReactSelect name="Case.Country" label="Country" value={values.Case.Country} isClearable={true} isSearchable={true} options={countries}/>
                    </Form.Row>
                    <TextArea name="Case.Title" label={config.Title} value={values.Case.Title} rows={2} />
                    <Form.Row>
                        <TextField label="Confirmation No" type="text" as={Col} name="Case.ConfirmationNo" value={values.Case.ConfirmationNo} size={size} />
                        <ReactCreatableSelect as={Col} name="Case.Status" label="Status" value={values.Case.Status} isClearable={false} isSearchable={true} options={statuses}/>
                    </Form.Row>
                    <Form.Row>
                        <TextField type="date" as={Col} label="Filing Date" name="Case.FilingDate" value={values.Case.FilingDate} size={size} />
                        {config.PriorityDate && <TextField type="date" as={Col} label={config.PriorityDate} name="Case.PriorityDate" value={values.Case.PriorityDate} size={size} /> }
                        {config.ProvisionalSN && <TextField type="text" as={Col} label={config.ProvisionalSN} name="Case.ProvisionalSN" value={values.Case.ProvisionalSN} size={size} />}
                    </Form.Row>
                    <Form.Row>
                        <div className="my-3 p-3 rounded bg-light border shadow-sm w-100 mr-2">
                            <Form.Label className="text-primary font-weight-bolder">Priority Cases</Form.Label>
                            <Form.Row>
                                <Col xs={1} className="text-center"></Col>
                                <Col xs={2} className="text-center text-primary text-nowrap">NatCode</Col>
                                <Col xs={4} className="text-center text-primary">Application No</Col>
                                <Col xs={4} className="text-center text-primary">Filing Date</Col>
                                <Col xs={1}></Col>
                            </Form.Row>
                            <FieldArray name="Priorities">
                            {({ insert, remove, push }) => (
                                <Fragment>
                                {values.Priorities && values.Priorities.map((x, index) => (
                                    <Form.Row key={`pr-${index}`}>
                                        <Col xs={1}><label className="text-right w-100" >Case</label></Col>
                                        <Col xs={2}><TextField size={size} name={`Priorities.${index}.NatCode`} value={x.NatCode} /></Col>
                                        <Col xs={4}><TextField size={size} name={`Priorities.${index}.AppNo`} value={x.AppNo} /></Col>
                                        <Col xs={4}><TextField type="date" size={size} name={`Priorities.${index}.FilingDate`} value={x.FilingDate} /></Col>
                                        <Col xs={1}>{UserProfile.canEdit() && <Button className="xs" variant="outline-secondary" size="sm" onClick={() => remove(index)}><FontAwesomeIcon icon={faMinus}/></Button>}</Col>
                                    </Form.Row>
                                ))}
                                {UserProfile.canEdit() && <Button className="btn-falcon-default mt-3" size="sm" onClick={() => push({ NatCode: '', AppNo: '', FilingDate: '' })}><FontAwesomeIcon icon={faPlus} /> Add More</Button>}
                                </Fragment>
                            )}
                            </FieldArray>
                        </div>
                    </Form.Row>
                    <Form.Row>
                        {config.PubNo && <TextField type="text" as={Col} label="PubNo" name="Case.PubNo" value={values.Case.PubNo} size={size} />}
                        {config.PubDate && <TextField type="date" as={Col} label="Pub Date" name="Case.PubDate" value={values.Case.PubDate} size={size} />}
                    </Form.Row>
                    <Form.Row>
                        <TextField type="date" as={Col} label={config.IssueDate} name="Case.IssueDate" value={values.Case.IssueDate} size={size} />
                        {config.PatentNo && <TextField type="text" as={Col} label={config.PatentNo} name="Case.PatentNo" value={values.Case.PatentNo} /> }
                        <TextField type="date" as={Col} label="Expiration Date" name="Case.ExpirationDate" value={values.Case.ExpirationDate} size={size} />
                    </Form.Row>
                    <Form.Row>
                        {config.Assignee && <TextField type="text" as={Col} label="Assignee" name="Case.assignee" value={values.Case.assignee} size={size} />}
                        <Col xs={3}>
                            <Form.Row>
                            {config.Reel && <TextField type="text" as={Col} label="Reel" name="Case.Reel" value={values.Case.Reel} size={size} /> }
                            {config.Frame && <TextField type="text" as={Col} label="Frame" name="Case.Frame" value={values.Case.Frame} size={size} />}
                            </Form.Row>
                        </Col>
                        {config.RecordationDate && <TextField type="date" as={Col} label="Recordation Date" name="Case.RecordationDate" value={values.Case.RecordationDate} size={size} />}
                    </Form.Row>
                    <Form.Row>
                        {config.ArtUnit && <TextField type="text" as={Col} label={config.ArtUnit} name="Case.ArtUnit" value={values.Case.ArtUnit} size={size} />}
                        <TextField type="text" as={Col} label="Examiner" name="Case.Examiner" value={values.Case.Examiner} size={size} />
                    </Form.Row>
                    <Form.Row>
                        {config.IntlClass && <div className="my-3 p-3 rounded bg-light border shadow-sm w-100 mr-2">
                            <Form.Label className="text-primary font-weight-bolder">Int'l Classes</Form.Label>
                            <Form.Row>
                                <Col xs={4} className="text-center text-primary text-nowrap">Class</Col>
                                <Col xs={7} className="text-center text-primary">Description</Col>
                                <Col xs={1}></Col>
                            </Form.Row>
                            <FieldArray name="IntClasses">
                            {({ insert, remove, push }) => (
                                <Fragment>
                                {values.IntClasses && values.IntClasses.map((x, index) => (
                                    <Form.Row key={`pr-${index}`}>
                                        <Field type="hidden" name={`IntClasses.${index}.Id`} value={x.Id} />
                                        <Col xs={4}><TextField disabled={x.Id === 0} size={size} name={`IntClasses.${index}.Name`} value={x.Name} /></Col>
                                        <Col xs={7}><TextArea rows={x.Description ? 2 : 1} disabled={x.Name === "" || x.Id === 0} name={`IntClasses.${index}.Description`} value={x.Description} /></Col>
                                        <Col xs={1}>{x.Id !== 0 && UserProfile.canEdit() && <Button className="xs" variant="outline-secondary" size="sm" onClick={() => remove(index)}><FontAwesomeIcon icon={faMinus}/></Button>}</Col>
                                    </Form.Row>
                                ))}
                                {UserProfile.canEdit() && <Button className="btn-falcon-default mt-3" size="sm" onClick={() => push({ Id: '', Name: '', Description: '' })}><FontAwesomeIcon icon={faPlus} /> Add More</Button>}
                                </Fragment>
                            )}
                            </FieldArray>
                        </div>}
                        {/* {config.IntlClass && <TextField type="text" as={Col} label="Int'l Class" name="Case.IntlClass" value={values.Case.IntlClass} size={size} />} */}
                    </Form.Row>
                    <Form.Row>
                        {config.GoodsServices && <TextArea as={Col} name="Case.GoodsServices" label="Goods / Services" rows={6} value={values.Case.GoodsServices}/> }
                    </Form.Row>
                    <Form.Row>
                        <TextArea as={Col} name="Case.ReportedNotes" label="Reported Notes" rows={6} value={values.Case.ReportedNotes}/>
                    </Form.Row>
                </Col>
                <Col>
                    <div className="border p-3 rounded form-group shadow-sm bg-light position-sticky" style={{top: 0}}>
                        <Form.Group>
                            <Form.Label className="mb-2">
                                <Field type="checkbox" name="Case.Active" disabled={!UserProfile.canEdit()} /> Active
                            </Form.Label>
                            <br />
                            <Form.Label className="font-weight-bolder">Protection Type</Form.Label>
                            {types.map(x=> (
                                <RadioField data-tag={x.tag} type="radio" key={`ct-${x.typeNo}`} onChange={handleCaseTypeChange}
                                    id={`${x.typeNo}`} value={`${x.typeNo}`} name="Case.TypeNo" label={x.name} />
                            ))}
                        </Form.Group>
                        {config.Type && <Fragment>
                            <Form.Label className="font-weight-bolder">Case Type</Form.Label>
                            <SelectField name="Case.UtilityDesign" size={size} value={values.Case.UtilityDesign}>
                                {Object.keys(config.Type).map( (x, i) => (
                                    <option key={`ud-${i}`} value={config.Type[x]}>{x}</option>
                                ))}
                            </SelectField>
                        </Fragment>}
                    </div>
                </Col>
            </Form.Row>
        </Fragment>
    )
};

export const PartiesFields = props => {
    const {
        values,
        errors,
        typeTag
    } = props;
    const size = "sm";

    const [newInv, setNewInv] = useState(false);
    const [newAgt, setNewAgt] = useState(false);
    // const [newAttr, setNewAttr] = useState(false);
    const [inventors, setIngentors] = useState();
    const [allInvs, setAllInvs] = useState([]);

    useEffect(() => {
        DoParties.getInventors().then(invs => setAllInvs(invs)).then(() => setIngentors(allInvs));
    }, []);

    useEffect(() => {
        setIngentors(allInvs.filter(x => {
            return values.ClientList && values.ClientList.length > 0 ?
                values.ClientList.find(c => {
                    let org = c.label.split(" ");
                    org.pop();
                    return org.join(" ") === x.Organization || !x.Organization;
                }) : true;
        }));
    }, [values.ClientList, allInvs]);

    return (
        <Fragment>
            {errors && errors.length > 0 && <RedAlert>
                <p className="font-weight-bold">Something is wrong:</p>
                <ul>
                    {errors.map( (x, i) => <li key={`a${i}`}>{x}</li>)}
                </ul>
            </RedAlert>}
            <Card className="mb-3">
                <Card.Header><h5 className="mb-0">Clients/Depts</h5></Card.Header>
                <Card.Body className="bg-light">
                    <ReactAsyncSelect
                        label="Client(s)"
                        name="ClientList"
                        closeMenuOnSelect={false}
                        value={values.ClientList}
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={DoParties.getClients}
                    />
                    <TextField size={size} label="Client Matter" name="Case.ClientMatter" value={values.Case.ClientMatter} />
                    <Form.Label>Billing Client</Form.Label>
                    <ReactSelect
                        name="Case.ClientNo"
                        value={values.ClientList && values.ClientList.length > 0 && values.ClientList.find(x => x.value === values.Case.ClientNo)}
                        isClearable={false}
                        isSearchable={false}
                        options={values.ClientList}/>
                </Card.Body>
            </Card>
            { typeTag === "patent" && <Card className="mb-3">
                <Card.Header><h5 className="mb-0">Inventors</h5></Card.Header>
                <Card.Body className="bg-light">
                    <ReactSelect
                        label="Inventor List"
                        name="InventorList"
                        closeMenuOnSelect={false}
                        value={values.InventorList}
                        isClearable isSearchable isMulti
                        options={inventors}/>
                    {UserProfile.canEdit() && <Button size="sm" className="btn-falcon-default" onClick={evt => setNewInv(true)}><FontAwesomeIcon icon={faPlus} /> Add New Inventor</Button>}
                    <div className={`mt-3 p-3 rounded bg-white border ${newInv ? "d-block" : "d-none"}`}>
                        <Form.Label className="text-primary font-weight-bolder">New Inventor</Form.Label>
                        <Form.Row>
                            <TextField as={Col} size={size} label="First Name" name="NewInventor.FirstName" value={values.NewInventor.FirstName} />
                            <TextField as={Col} size={size} label="Last Name" name="NewInventor.LastName" value={values.NewInventor.LastName} />
                            <TextField as={Col} size={size} label="Phone" name="NewInventor.Phone" value={values.NewInventor.Phone} />
                        </Form.Row>
                        <TextArea size={size} label="Address" name="NewInventor.Address1" value={values.NewInventor.Address1} rows={2}></TextArea>
                    </div>
                </Card.Body>
            </Card> }
            <Card className="mb-3">
                <Card.Header><h5 className="mb-0">Attorneys</h5></Card.Header>
                <Card.Body className="bg-light">
                    <ReactAsyncSelect
                        name="AttorneyList"
                        closeMenuOnSelect={false}
                        value={values.AttorneyList}
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={DoParties.getAttorneys}/>
                    {/* <Button size="sm" className="btn-falcon-default" onClick={evt => setNewAttr(true)}><FontAwesomeIcon icon={faPlus} /> Add New Attorney</Button> */}
                    {/* <div className={`mt-3 p-3 rounded bg-white border ${newAttr ? "d-block" : "d-none"}`}>
                        <Form.Label className="text-primary font-weight-bolder">Ad Hoc</Form.Label>
                        <Form.Row>
                            <TextField as={Col} size={size} label="First Name" name="NewAttorney.FirstName" value={values.NewAttorney.FirstName} />
                            <TextField as={Col} size={size} label="Last Name" name="NewAttorney.LastName" value={values.NewAttorney.LastName} />
                            <TextField as={Col} size={size} label="Phone" name="NewAttorney.Phone" value={values.NewAttorney.Phone} />
                        </Form.Row>
                        <TextArea size={size} label="Address" name="NewAttorney.Address1" value={values.NewAttorney.Address1} rows={2}></TextArea>
                    </div> */}
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header><h5 className="mb-0">Foreign Agent / Outside Counsel</h5></Card.Header>
                <Card.Body className="bg-light">
                    <ReactAsyncSelect
                        name="AgentList"
                        closeMenuOnSelect={false}
                        value={values.AgentList}
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={DoParties.getAgents}/>
                    {UserProfile.canEdit() && <Button size="sm" className="btn-falcon-default" onClick={evt => setNewAgt(true)}><FontAwesomeIcon icon={faPlus} /> Add New Agent</Button>}
                    <div className={`mt-3 p-3 rounded bg-white border ${newAgt ? "d-block" : "d-none"}`}>
                        <Form.Label className="text-primary font-weight-bolder">New Agent</Form.Label>
                        <Form.Row>
                            <TextField as={Col} size={size} label="First Name" name="NewAgent.FirstName" value={values.NewAgent.FirstName} />
                            <TextField as={Col} size={size} label="Last Name" name="NewAgent.LastName" value={values.NewAgent.LastName} />
                            <TextField as={Col} size={size} label="Phone" name="NewAgent.Phone" value={values.NewAgent.Phone} />
                        </Form.Row>
                        <TextArea size={size} label="Address" name="NewAgent.Address1" value={values.NewAgent.Address1} rows={2}></TextArea>
                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export const RelatedCases = props => {
    const {
        values,
        errors,
    } = props;

    const ParentCases = memoize(async inputValue => {
        return await DoCase.getCaseAppNosByName(inputValue).then(cases => cases.filter(x => x.value !== values.Case.ApplicationNo));
    });

    const getCaseAppNosByName = inputValue =>
        new Promise(resolve => {
            resolve(ParentCases(inputValue));
        });

    return (
        <Fragment>
            {errors && errors.length > 0 && <RedAlert>
                <p className="font-weight-bold">Something is wrong:</p>
                <ul>
                    {errors.map( (x, i) => <li key={`a${i}`}>{x}</li>)}
                </ul>
            </RedAlert>}
            <Card body>
                <Row>
                    <Col xs={7}>
                        <Form.Row>
                            <ReactAsyncCreatableSelect
                                as={Col}
                                label="Parent Case" name="Case.ParentCase"
                                value={values.Case.ParentCase}
                                isSearchable={true}
                                isClearable={true}
                                cacheOptions defaultOptions loadOptions={getCaseAppNosByName}/>
                            <TextField as={Col} type="date" label="Parent Filing Date" name="Case.parentFilingDate" value={values.Case.parentFilingDate} />
                        </Form.Row>
                        <div className="p-3 my-3 shadow-sm border rounded">
                            <Form.Label className="text-primary font-weight-bolder d-block">Child Cases</Form.Label>
                            <FieldArray name="ChildCases">
                                {({ insert, remove, push }) => (
                                    <Fragment>
                                        {values.ChildCases.map((x, index) => (
                                            <Form.Row key={`chd-${index}`}>
                                                <Col xs={11}>
                                                    <ReactAsyncCreatableSelect
                                                        label="Case" name={`ChildCases.${index}`}
                                                        value={x}
                                                        isSearchable
                                                        isClearable
                                                        helper="Search for an Application No or add a new one*"
                                                        cacheOptions defaultOptions loadOptions={getCaseAppNosByName}/>
                                                </Col>
                                                <Col>{UserProfile.canEdit() && <Button className="select-btn xs" variant="outline-secondary" size="sm" onClick={() => remove(index)}><FontAwesomeIcon icon={faMinus}/></Button>}</Col>
                                            </Form.Row>
                                        ))}
                                        {values.ChildCases && values.ChildCases.length > 0 && <small className="form-text text-muted">* You can search by Application No, Matter or Title.<br/>Choose 'Create' option if it's a new Application No (this won't create a new case though).</small>}
                                        {UserProfile.canEdit() && <Button className="btn-falcon-default mt-3" size="sm" onClick={() => push({ value: '', label: '' })}><FontAwesomeIcon icon={faPlus}/> Add A Child Case</Button>}
                                    </Fragment>
                                )}
                            </FieldArray>
                        </div>
                    </Col>
                    <Col>
                        <Form.Row className="my-3 form-row border rounded p-2 bg-light">
                            <Col>
                                <ReactAsyncCreatableSelect
                                    label="Group" name="group"
                                    value={values.group}
                                    isClearable={true}
                                    isSearchable={true}
                                    helper="To add a new group just type it in and choose 'Create' option."
                                    cacheOptions defaultOptions loadOptions={DoCase.getGroups}/>
                            </Col>
                            <Col>
                                <Form.Label>Group Members</Form.Label>
                                <Form.Control as="select" multiple >
                                    {values.GroupMembers && values.GroupMembers.map( (x, i) => (
                                        <option key={`g-${i}`} value={x.Name}>{x.Name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Row>
                        <Form.Label className="mt-3">
                            <Field type="checkbox" name="Case.disclosureRequired" /> Disclosure Required
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextArea rows={10} label="Internal Notes" name="Case.Notes" value={values.Case.Notes} />
                    </Col>
                </Row>
            </Card>
        </Fragment>
    )
}