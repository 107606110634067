import React, { Fragment, useEffect, useMemo, useState, useRef} from 'react';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import EditCase from '../Cases/EditCase';
import DoCase from '../Cases/DoCase';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import { AlertClass, AlertModalComponent, LoadingSpinner } from '../../components/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleChangeRowsPerPage, prepareRows } from '../../lib/helpers';
import CaseDetails from '../Cases/CaseDetails';
import { RowStyles } from '../Cases/Cases';
import UserProfile from '../../lib/UserProfile';

const LinkToCaseDetails = ({onEdit, row}) => {
    const variant = "outline-primary";

    const onRowEdit = useMemo(() => {
        return () => {
            onEdit(row);
        }
    });

    return (
        <Button title="Correspondence History" size="sm" variant={variant} className="btn-light-border" onClick={onRowEdit}><FontAwesomeIcon icon={faCalendarWeek} /></Button>
    );
}

export const CaseList = ({context, updateContext, ...props}) => {
    const ic = {name: "", sortable: true, selector: "", allowOverflow:true, center: false};
    const columns = [
        {...ic, name: "Activity", selector: "actions", sortable: false, center: true, button: true, ignoreRowClick: true,
                width: "60px", cell: row => <LinkToCaseDetails onEdit={onEdit} row={row} />},
        {...ic, name: "Case No", col: "CaseNo", selector: "caseno", omit: true},
        {...ic, name: "Matter", col: "Matter", selector: "matter", width: "150px",
                cell: row => <a href="#" onClick={()=> onRowClick(row)} title="Open Case Details">{row.matter}</a>},
        {...ic, name: "Country", col: "CountryName", selector: "country", width: "100px"},
        {...ic, name: "Status", col: "StatusName", selector: "status", width: "150px"},
        {...ic, name: "Type", col: "TypeNo", selector: "type", omit: true},
        {...ic, name: "Title", col: "Title", selector: "title", wrap: true,
                format: row => row.title && `${row.title.length < 80 ? row.title : row.title.slice(0, 80) + "..."}`,
                style: { whiteSpace: 'wrap', textOverflow: 'ellipses', width: "min-content"} },
        {...ic, name: "Application No", col: "ApplicationNo", selector: "applicationno", width: "150px"},
        {...ic, name: "Filing Date", col: "FilingDate", selector: "filingdate", width: "150px"},
        {...ic, name: "Actve", col: "Active", selector: "active", omit: true},
    ];
    const am = useRef();
    const ac = AlertClass();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editShow, setEditShow] = useState(false);
    const [actShow, setActShow] = useState(false);
    const [caseRow, setCaseRow] = useState();
    const [actRow, setActRow] = useState();

    function onEdit(row) {
        setActRow(row);
        setActShow(true);
    }

    function onRowClick(row) {
        setCaseRow(row);
        setEditShow(true);
    }

    function onRowEdit(ncase) {
        let alertMsg = `Case \"${ncase.Matter} -- ${ncase.Title}\" was successfully updated.`;

        if(ncase.newStatus) {
            updateContext({newsStatuses: [...context.statuses, ncase.newStatus]})
        }

        props.alert.current.showInfo(alertMsg);

        caseRow.matter = ncase.Matter;
        caseRow.country = ncase.CountryName;
        caseRow.status = ncase.StatusName;
        caseRow.title = ncase.Title;
        caseRow.applicationno = ncase.ApplicationNo;
        caseRow.filingdate = ncase.FilingDate;

        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 200);
    }

    function onDelete() {
        let row = caseRow;
        am.current.showConfirm("WARNING!", `Deleting case "${row.matter} - ${row.title}". Are you sure you want to proceed?`,
                () => {
                setEditShow(false);
                DoCase.deleteCase(row.caseno)
                    .then(resp => resp.data.deleteCase || null)
                    .then(deleted => {
                        props.alert.current.showInfo(`Case "${row.matter} - ${row.title}" was successfully deleted.`);
                        setData(data.filter(x => x.caseno != row.caseno));
                        setIsLoading(true);
                        setTimeout(() => setIsLoading(false), 200);
                    })
                    .catch(resp => {
                        console.error("Mutation Error:", resp);
                        props.alert.current.showErrorMsg(`Deleting case "${row.matter} - ${row.title}" failed.`);
                    });
                });
    }

    useEffect(()=> {
        DoCase.getCasesByParty(props.data.id)
                .then(cases => {
                    setData(prepareRows(columns, cases));
                    setIsLoading(false);
                })
                .catch(err => {
                    console.error("Query error: ", err);
                    setIsLoading(false);
                    ac.showErrorMsg("Oops! Something went wrong, could not load related case list.");
                })
    }, [props.data]);

    return (
        <Fragment>
            {ac.alertMsg && <div className="m-3">{ac.alertMsg}</div>}
            <div className="m-3">
                <p className="font-weight-bold text-primary mb-1">Related cases:</p>
                <DataTable
                    key="cases-table"
                    // dense
                    progressPending={isLoading}
                    progressComponent={<LoadingSpinner />}
                    columns={columns}
                    data={data}
                    allowOverflow={true}
                    conditionalRowStyles={RowStyles}
                    responsive
                    noHeader
                    fixedHeader
                    pagination
                    paginationPerPage={UserProfile.getRowsPerPage()}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    paginationRowsPerPageOptions={[25, 50, 100, 200]}
                    // pointerOnHover
                    highlightOnHover
                    paginationComponentOptions={{rowsPerPageText: 'Show clients: ', rangeSeparatorText: 'To', selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                />
            </div>
            <AlertModalComponent ref={am} />
            {actRow && <CaseDetails createNew={null} show={actShow} onHide={() => setActShow(false)} data={actRow} />}
            {caseRow && <EditCase onRowEdit={onRowEdit} onDelete={onDelete} show={editShow} onHide={() => setEditShow(false)} data={caseRow} />}
        </Fragment>
    )
}
