import { API, graphqlOperation } from 'aws-amplify';
import { getStatuses, getCaseTypes as getQCaseTypes, getCountries as getQCountries } from '../graphql/queries';
import UserProfile from './UserProfile';
import moment from 'moment';
import { DataType } from './Model';

export const handleTableSort = dtName => (col, dir) => localStorage.setItem("rdt-" + dtName,
                                    JSON.stringify({name: col.col, dir: dir !== "desc", selector: col.selector}))
export const setCol = col => ({...col, id: col.selector})
export const getDefaultSortField = clsId =>  {
    let col = localStorage.getItem("rdt-"+clsId);
    col = JSON.parse(col);
    return col ? col : {selector: null, dir: true};
}

export const prepareRows = (columns, data, hasAction) => {
    return data.map(k => {
        let x = {}; columns.map( (c, i) => {if(c.selector !== "actions") x[c.selector] = k[c.col]; return x;});
        if(hasAction)
            x.actions = hasAction;
        return x;
    });
}

export const prepareOneRow = (columns, row) => {
    let x = {}; columns.map( (c, i) => {if(c.selector !== "actions") x[c.selector] = row[c.col] ; return x;});
    return x;
}

export const matchP = p => {
    let ps = {0: "", 1: "Hight", 2: "Medium"};
    return p ? ps[p] || "" : "";
}

export const searchAll = (el, term) => {
    let reg = new RegExp(".*" + sanitizeRegex(term) + ".*", "i");

    return Object.keys(el).filter( k => typeof el[k] === "string" && el[k].match(reg) ).length;
}

export const sanitizeRegex = term => term.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

export const getCaseTypes = () => {
    let p = new Promise((res, rej) => API.graphql(graphqlOperation(getQCaseTypes))
                    .then(resp => resp.data.getCaseTypes || [])
                    .then(tps => res(tps))
                    .catch(err => {console.log("Types Query Error:", err); rej(err)})
    );

    return p;
}

export const getCaseStatuses = () => {
    let p = new Promise((res, rej) => API.graphql(graphqlOperation(getStatuses, {AccountId: UserProfile.getUser().account_id}))
        .then(resp => resp.data.getStatuses || [])
        .then(statuses => res(statuses))
        .catch(err => {console.log("Statues Query Error:", err); rej(err);})
    );

    return p;
}

export const getCountries = () => {
    let p = new Promise((res, rej) => API.graphql(graphqlOperation(getQCountries, {AccountId: UserProfile.getUser().account_id}))
        .then(resp => resp.data.getCountries || [])
        .then(countries => res(countries))
        .catch(err => {console.log("Statues Query Error:", err); rej(err);})
    );

    return p;
}

export const sortByDate = (list, byItem) => {
    return list.sort(function(a,b){
        return new Date(a[byItem]) - new Date(b[byItem]);
      });
}

export const calcDueDate = (x, new_date) => {
    let date = moment(new_date);
    x.Months > 0 && (date = date.add(x.Months, 'months'));
    x.Weeks > 0 && (date = date.add(x.Weeks, 'weeks'));
    x.Days > 0 && (date = date.add(x.Days, 'days'));
    return date.format("YYYY-MM-DD");
}

export const parseNulls = (values, model) => {
    Object.keys(values).map( x => (DataType[model.modelClass[x]] === DataType.String || DataType[model.modelClass[x]] === DataType.AWSDate) && values[x] === null && (values[x] = ""));
    return values;
}

export const handleChangeRowsPerPage = (rowsCount) => {
    UserProfile.persist("rdt-rows", rowsCount);
}

export const getSelectedValue = obj => obj && obj.constructor.name === "Object" ? obj.value : obj;

export const ActType = {Received: 1, Sent: 0};
export const ActTypeCategories = [{value: 0, label:"Sent"}, {value:1, label:"Received"}];
export const ActTypeBg = {1: "bg-received", 0: "bg-sent"};
export const PartyTypes = {Client: 0, Agent: 2, Attorney: 1, Inventor: 3};
export const CaseTypes = {Provisional: 3, Utility: 0, Design: 1, Plant: 2};
export const CaseTypesString = {3: "Provisional", 0: "Utility", 1: "Design", 2: "Plant"};