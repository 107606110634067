import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import UserProfile from './UserProfile';

export async function getStatuses(inputValue) {
    return await API.graphql(graphqlOperation(queries.getStatuses, {AccountId: UserProfile.getUser().account_id, inputValue: inputValue}))
                    .then(resp => resp.data.getStatuses || [])
                    .then(sts => sts.map(x => ({value: x.id, label: x.name})));
}

export async function getCountries(inputValue) {
    return await API.graphql(graphqlOperation(queries.getCountries, {inputValue: inputValue}))
                    .then(resp => resp.data.getCountries || [])
                    .then(sts => sts.map(x => ({value: x.ID, label: x.name})));
}

export async function getCaseDependencies() {
    return await API.graphql(graphqlOperation(queries.getCaseDeps, {AccountId: UserProfile.getUser().account_id, inputValue: ""}))
                .then(resp => {
                    let stats = resp.data.getStatuses || [];
                    let cts = resp.data.getCountries || [];
                    let getCaseTypes = resp.data.getCaseTypes || [];
                    return {stats: stats, cts: cts, getCaseTypes: getCaseTypes};
                });
}