import React, { Fragment, memo, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner, Modal } from 'react-bootstrap';
import { Formik, Form as KiForm } from 'formik';
import { RemindersField, SelectField, TextArea, TextField } from '../../components/fields';
import { AlertClass, LoadingSpinner, RedAlert } from '../../components/helpers';
import DoCorrespondence from './DoCorrespondence';
import { calcDueDate } from '../../lib/helpers';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserProfile from '../../lib/UserProfile';

export const CorrespFields = props => {
    const {
        values,
        errors,
        handleChange,
        reminders,
        size
    } = props;

    const [rems, setRems] = useState(reminders.filter(x => !x.excluded));
    const [removedRems, setRemovedRems] = useState(reminders.filter(x => x.excluded));

    useEffect(() => {
        values.reminderIds = rems.map(x => x.TicklerType);
        values.excludedIds = removedRems.map(x => x.TicklerType);
    }, [rems, removedRems]);

    const handleDueDateChange = evt => {
        handleChange(evt);
        let val = evt.target.value;

        setRems(rems.map(x => { x.ActSetDate && (x.DueDate = calcDueDate(x, val)); return x; }));
        setRemovedRems(removedRems.map(x => {x.DueDate = calcDueDate(x, val); return x;}));
    }

    return (
        <Fragment>
            {errors && errors.length > 0 && <RedAlert>
                <p className="font-weight-bold">Something is wrong:</p>
                <ul>
                    {errors.map( (x, i) => <li key={`a${i}`}>{x}</li>)}
                </ul>
            </RedAlert>}
            <Row>
                <Col xs={4}>
                    <TextField onChange={handleDueDateChange} label="Date" required type="date" name="ActDate" value={values.ActDate} size={size} />
                    <TextArea name="Notes" label="Notes" value={values.Notes} rows={4} />
                </Col>
                <Form.Group as={Col} className="pl-0 mb-0">
                    <RemindersField curDate={values.ActDate} values={values} rems={rems} setRems={setRems} removedRems={removedRems} setRemovedRems={setRemovedRems} reminders={reminders} >
                        <div className="d-flex justify-content-between">
                            <Form.Label>Included Reminders</Form.Label>
                        </div>
                    </RemindersField>
                </Form.Group>
            </Row>
        </Fragment>
    );
};

export const RespondFields = props => {
    const {values, errors, corresps, reminders, origRespondsTo, CompletingAct} = props;
    const [rems, setRems] = useState([]);
    const [removedRems, setRemovedRems] = useState([]);

    useEffect(() => {
        values.respondsTo.reminderIds = rems.map(x => x.TicklerType);
        values.respondsTo.excludedIds = removedRems.map(x => x.TicklerType);
    }, [rems, removedRems]);

    useEffect(() => {
        let cp = corresps.filter(x => x.ActNo == values.respondsTo.ActNo)[0];
        values.respondsTo.ActType = cp ? cp.Type : null;

        // show all completed reminders minus exluded
        let corresp_rems = reminders.filter(x => x.ActType == values.respondsTo.ActType);
        if(origRespondsTo == values.respondsTo.ActNo) {
            setRems(corresp_rems.filter(x => !x.excluded && x.DateCompleted && x.CompletingAct === CompletingAct));
            setRemovedRems(corresp_rems.filter(x => x.excluded || !x.DateCompleted));
        }
        else {
            setRems(corresp_rems.filter(x => !x.excluded && !x.DateCompleted));
            setRemovedRems(corresp_rems.filter(x => x.excluded));
        }

        values.respondsTo.reminderIds = rems.map(x => x.TicklerType);
    }, [values.respondsTo.ActNo]);

    return (
        <Fragment>
            {errors && errors.length > 0 && <RedAlert>
                <p className="font-weight-bold">Something is wrong:</p>
                <ul>
                    {errors.map( (x, i) => <li key={`a${i}`}>{x}</li>)}
                </ul>
            </RedAlert>}
            <SelectField name="respondsTo.ActNo" label="Responds To" value={values.respondsTo.ActNo} >
                <option value=""> -- </option>
                {corresps && corresps.map((x, i) => (
                   <Fragment key={`cp-${i}`}>{x.Description && <option value={x.ActNo}>{x.Description}</option>}</Fragment>
                ))}
            </SelectField>
            <Form.Group className="mb-0">
                <RemindersField curDate={values.ActDate} values={values} rems={rems} setRems={setRems} removedRems={removedRems} setRemovedRems={setRemovedRems} reminders={reminders} >
                    <Form.Label>Completes Reminders</Form.Label>
                </RemindersField>
            </Form.Group>
        </Fragment>
    )
};

const CorrespForm = (props) => {
    const ac = AlertClass();
    const [form, setForm] = useState(<Card body><LoadingSpinner/></Card>);
    const {corresps, reminders, data, onHide, onDelete} = props;
    const bgType = {0: "bg-sent", 1: "bg-received"};
    const bgTypeN = {0: "bg-received", 1: "bg-sent"};

    const onValidate = values => {
        let errors = {};
        return errors;
    }

    const onSubmit = (values, helpers) => {
        let act = {
            ActNo: values.ActNo,
            CaseNo: data.caseno,
            RespondsTo: values.respondsTo.ActNo,
            ActDate: values.ActDate,
            Notes: values.Notes,
            ReminderIds: values.reminderIds,
            RespReminderIds: values.respondsTo.reminderIds
        }
        let doco = new DoCorrespondence(act);
        doco.updateCorresp()
            .then(resp => resp.data.editCorrespondence || {})
            .then(coresp => {
                helpers.setSubmitting(false);
                if(coresp) {
                    ac.showInfo("Correspondence successfully updated");
                    props.onRowEdit(act);
                    setTimeout(props.onHide, 200);
                } else {
                    ac.showErrorMsg("Editing this correspondence failed.");
                }
            })
            .catch(resp => {
                console.error("Mutation Error:", resp);
                ac.showErrorMsg("Editing the correspondence failed.");
                helpers.setSubmitting(false);
            });
    }

    useEffect(() => {
        let RespCoresp = corresps.filter(x => x.ActNo == data.respondsto)[0];
        let RespActType = RespCoresp ? RespCoresp.Type : "";

        let cfData = {
            ActNo: data.actno,
            ActType: data.acttype,
            ActDate: data.actdate,
            Notes: data.notes,
            reminderIds: reminders.filter(x => x.ActNo === data.actno && !x.excluded).map(x => x.TicklerType),
            excludedIds: reminders.filter(x => x.ActNo === data.actno && x.excluded).map(x => x.TicklerType),
            respondsTo: {
                ActNo: data.respondsto ? data.respondsto : "",
                ActType: RespActType,
                reminderIds: reminders.filter(x => x.ActNo === RespActType && !x.excluded).map(x => x.TicklerType),
                excludedIds: reminders.filter(x => x.ActNo === RespActType && x.excluded).map(x => x.TicklerType),
            }
        };

        const activeRems = reminders.filter(x => x.ActNo === data.actno);
        const respondsToCorresps = corresps.filter(x => x.ActNo !== data.actno);
        const respondsToRems = reminders.filter(x => x.ActNo !== data.actno && x.TicklerNo);

        setForm(
            <Formik isSubmitting initialValues={{...cfData}} validate={onValidate} onSubmit={onSubmit}>
                {props => (
                    <KiForm noValidate className="modal-content" style={{maxHeight: "calc(100vh - 61px)"}}>
                        <Modal.Body>
                            <Card className="mb-2">
                                <Card.Header className={`h5 py-2 text-gray ${bgType[data.category]}`}>
                                    <div className="d-flex justify-content-between">
                                        {{1: "Received", 0:"Sent"}[data.category]}: {data.description}
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <CorrespFields size="sm" reminders={activeRems} {...props} />
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className={`h5 py-2 text-gray ${bgTypeN[data.category]}`}>Responds To</Card.Header>
                                <Card.Body className="">
                                    <RespondFields size="sm" CompletingAct={data.actno} reminders={respondsToRems} origRespondsTo={data.respondsto}
                                                corresps={respondsToCorresps} {...props} />
                                </Card.Body>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-start">
                            <Form.Group>
                                {UserProfile.canEdit() ?
                                    <Fragment>
                                        <Button variant="primary" type="submit" name="save" disabled={props.isSubmitting} size="sm" className="mr-2">{props.isSubmitting ? <>Saving <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></> : "Save"}</Button>
                                        <Button variant="outline-secondary" type="button" name="close" size="sm" className="btn-falcon-default" onClick={onHide}>Cancel</Button>
                                        {onDelete && <span className="mx-3 text-muted">|</span>}
                                        {onDelete && <Button type="reset" disabled={props.isSubmitting} onClick={onDelete} className="btn-falcon-danger" size="sm" ><FontAwesomeIcon icon={faTrashAlt} /> Delete</Button>}
                                    </Fragment> :
                                    <Button variant="outline-secondary" type="button" name="close" size="sm" className="btn-falcon-default" onClick={onHide}>Close</Button>}
                            </Form.Group>
                        </Modal.Footer>
                    </KiForm>
                )}
            </Formik>
        );
    }, [data]);

    return (
        <Fragment>
            {ac.alertMsg}
            {form}
        </Fragment>
    );
};

export default memo(CorrespForm);