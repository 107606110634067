import React, { Component, useContext, useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCopy, faUsers, faFileInvoice, faCalendarAlt, faTable, faCogs, faSignOutAlt, faUserCircle, faPlusCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from 'react-bootstrap';
import { Auth } from '@aws-amplify/auth';
import UserProfile from '../lib/UserProfile';
import { NavContext } from '../App';

const ListItemLink = ({ to, text, extra, icon="", ...rest }) => (
    <Route
      path={to}
      children={({ match }) => (
          <Link className={"nav-link " + extra + (match ? " active" : "")} to={to} {...rest} >
            <div className="d-flex align-items-center">
                <span className="nav-link-icon">{icon}</span>
                <span className="nav-link-text">{text}</span>
            </div>
          </Link>
      )}
    />
);

export const SideBar = props => {
    const {onToggleSideBar, onEnter, onLeave} = props;

    return (
        <Nav defaultActiveKey="/cases/list" className="navbar-vertical navbar-glass navbar-inverted navbar navbar-expand-xl navbar-light">
            <div className="d-flex align-items-center">
                <div className="toggle-icon-wrapper mr-2">
                    <button type="button" onClick={onToggleSideBar} id="toggleNavigationTooltip" className="navbar-toggler-humburger-icon navbar-vertical-toggle btn btn-link">
                    <span className="navbar-toggle-icon"><span className="toggle-line"></span></span>
                    </button>
                </div>
                <a  className="text-decoration-none navbar-brand text-left" href="/">
                    <div className="d-flex align-items-center py-3">
                    {/* <img className="mr-2" src="/static/media/falcon.920a9ff0.png" alt="Logo" width="40" /> */}
                    <span className="text-sans-serif">Docket Wizard</span>
                    </div>
                </a>
            </div>
            <NavContext.Consumer>
                {context => (
                <div id="sidebar" onMouseEnter={onEnter} onMouseLeave={onLeave}
                    className={`scrollbar collapse navbar-collapse ${context.isMobileHover ? "show" : ""}`} >
                    <ul className="navbar-nav flex-column">
                        <Nav.Item>
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><FontAwesomeIcon icon={faCopy} /></span>
                                <span className="nav-link-text">Cases</span>
                            </div>
                            <Nav className="mr-auto flex-column">
                                <ListItemLink to="/cases/list" text="Case List" />
                                {/* <ListItemLink to="/reminders/list" text="Reminders List" /> */}
                                {/* <ListItemLink to="/cases/new" text="Add New Case" /> */}
                                {/* <ListItemLink to="/cases/reminders/new" text="Add New Correspondence" />
                                <ListItemLink to="/cases/correspondences/new" text="Add New Reminder" /> */}
                            </Nav>
                        </Nav.Item>
                        <Nav.Item>
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><FontAwesomeIcon icon={faTable} /></span>
                                <span className="nav-link-text">Reports</span>
                            </div>
                            <Nav className="mr-auto flex-column">
                                <ListItemLink to="/reports/reminders" text="Reminders" />
                                <ListItemLink to="/reports/cases" text="Cases" />
                                <ListItemLink to="/reports/clients" text="Clients" />
                                <ListItemLink to="/reports/correspondences" text="Correspondence History" />
                                <ListItemLink to="/reports/attorneys" text="Attorneys" />
                            </Nav>
                        </Nav.Item>
                        <Nav.Item>
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><FontAwesomeIcon icon={faUsers} /></span>
                                <span className="nav-link-text">Parties</span>
                            </div>
                            <Nav className="mr-auto flex-column">
                                <ListItemLink to="/parties/clients" text="Client / Departments" />
                                <ListItemLink to="/parties/inventors" text="Inventors" />
                                <ListItemLink to="/parties/agents" text="Agents / Outside" />
                                <ListItemLink to="/parties/attorneys" text="Attorneys" />
                            </Nav>
                        </Nav.Item>
                        <Nav.Item>
                            <ListItemLink to="/calendar" text="Calendar" extra="single" icon={<FontAwesomeIcon icon={faCalendarAlt} />} />
                        </Nav.Item>
                        <Nav.Item>
                            <div className="navbar-vertical-divider">
                                < hr className="navbar-vertical-hr my-2"/>
                            </div>
                        </Nav.Item>
                        <Nav.Item>
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><FontAwesomeIcon icon={faFileInvoice} /></span>
                                <span className="nav-link-text">Templates</span>
                            </div>
                            <Nav className="mr-auto flex-column">
                                <ListItemLink to="/templates/correspondence" text="Correspondence" />
                                <ListItemLink to="/templates/custom-fields" text="Custom Fields" />
                            </Nav>
                        </Nav.Item>
                        <Nav.Item>
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><FontAwesomeIcon icon={faCogs} /></span>
                                <span className="nav-link-text">Administration</span>
                            </div>
                        <Nav className="mr-auto flex-column">
                            <ListItemLink to="/admin/specia-processing" text="Define Special Processing" />
                            <ListItemLink to="/admin/security" text="Manage Security" />
                        </Nav>
                        </Nav.Item>
                    </ul>
                </div>
            )}
            </NavContext.Consumer>
        </Nav>
    )
}

export class HMenu extends Component {

    constructor(props) {
        super(props);
        this.onToggleMobile = props.onToggleMobile;
        this.state = { user: null, showCreate: false, shown: false };
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(user => this.setState({user: user}))
            .catch(err => this.setState({user: null}));
    }

    render() {
        const userGroup = UserProfile.getUser().AccountName;
        const userName = UserProfile.getUser().name;

        return (
            <React.Fragment>
                <nav className="vnav navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit navbar navbar-expand-xl navbar-light">
                <NavContext.Consumer>
                    {(context) => (
                        <div className="toggle-icon-wrapper mr-md-3 mr-2 d-xl-none">
                            <button onClick={() => context.setIsMobileHover(true)} className="navbar-toggler-humburger-icon btn btn-link d-flex align-item-center justify-content-center " id="burgerMenu">
                                <span className="navbar-toggle-icon"><span className="toggle-line"></span></span>
                            </button>
                        </div>
                    )}
                </NavContext.Consumer>
                    <a className="text-decoration-none navbar-brand text-left" id="topLogo" href="/">
                        <div className="d-flex align-items-center">
                            {/* <img className="mr-2" src="/static/media/falcon.920a9ff0.png" alt="Logo" width="40" /> */}
                            <span className="text-sans-serif">Docket Wizard</span>
                        </div>
                    </a>
                    {/* <ul className="navbar-nav">
                        <li className="nav-item">
                            <Button onClick={() => this.setState({showCreate: true, shown: true})} size="sm" className="rounded-capsule btn-falcon-default"><FontAwesomeIcon icon={faPlusCircle} /> Create</Button>
                        </li>
                    </ul> */}
                    <ul className="pt-1 ml-auto flex-row-reverse align-items-center navbar-nav">
                        <li className="nav-item">
                            <span className="nav-link">
                                <Link to="/login">
                                    <Button size="sm" variant="outline-primary" onClick={() => {Auth.signOut(); }
                                    }><FontAwesomeIcon icon={faSignOutAlt} /> Sign out</Button>
                                </Link>
                            </span>
                        </li>
                        {[{title: "View User Profile", text: userName + " | " + userGroup, icon: <FontAwesomeIcon icon={faUserCircle} />, to: "/profile"},
                            {title: "View All Alerts", text:"", icon: <FontAwesomeIcon icon={faBell} />, to: "/alerts"}
                            ].map((link, i) => (
                                <li ref={React.createRef()} key={"vnav-"+i} className="nav-item">
                                <Link key={i + "-ln"} className="nav-link user" to={link.to} title={link.title}>
                                    {link.icon} {link.text}
                                </Link>
                                </li>
                            ))
                        }
                    </ul>
                </nav>
                {this.props.children}
                {this.state.shown &&
                    <Modal show={this.state.showCreate} onHide={() => this.setState({showCreate: false})} onExited={() => this.setState({shown: false})} className="reverse page-modal scrollbar"
                        dialogClassName="modal-25w"
                    >
                        <Modal.Header closeButton>
                            <div className="modal-title">
                                <h5>What do you want to create?</h5>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateList onHide={() => this.setState({showCreate: false})}/>
                        </Modal.Body>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}

const CreateList = props => {

    return (
        <div className="px-5 add-menu">
            <h5 className="mb-3 font-weight-light"><FontAwesomeIcon icon={faCopy} /> Cases</h5>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Case</Link>
            </div>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Correspondence</Link>
            </div>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Reminder</Link>
            </div>

            <h5 className="mt-4 mb-3 font-weight-light"><FontAwesomeIcon icon={faUsers} /> Parties</h5>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Client</Link>
            </div>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Inventor</Link>
            </div>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Attorney</Link>
            </div>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Agent</Link>
            </div>

            <h5 className="mt-4 mb-3 font-weight-light"><FontAwesomeIcon icon={faFileInvoice} /> Template</h5>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Received <abbr title="Correspondence">Corresp.</abbr></Link>
            </div>
            <div className="add-link">
                <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span><Link>New Sent <abbr title="Correspondence">Corresp.</abbr></Link>
            </div>
        </div>
    )
}
