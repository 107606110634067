import React, { memo, useContext, useEffect, useState } from 'react';
import { Modal, Badge, Card, Tabs, Tab } from "react-bootstrap";
import { AppContext } from '../../components/DWPage';
import { AlertClass } from '../../components/helpers';
import Correspondence, { Activity } from './correspondence';
import DoCase from './DoCase';
import Reminders from './Reminders';

const CaseDetails = (props) => {
  const [key, setKey] = useState('corresp');
  const [corresps, setCorresps] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [adhoc_reminders, setAdHocReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const appContext = useContext(AppContext);
  const ac = AlertClass();
  let row = props.data;
  if(!row)
    row = {matter: "", title: "", status: ""};

  const forceReload = x => reload === x ? setReload(!x) : setReload(x);

  useEffect(() => {
    // Get Correspondence & Reminders
    setIsLoading(true);
    setCorresps([]);
    setReminders([]);
    ac.hideAll();
    props.data && DoCase.getCaseDetails(props.data.caseno)
        .then(({corresps, reminders, adhoc_reminders}) => {
          setCorresps(corresps);
          setReminders(reminders);
          setAdHocReminders(adhoc_reminders);
          setIsLoading(false);
        })
        .catch(err => {
            console.error("Query Error:", err);
            setIsLoading(false);
            ac.showErrorMsg("Error retreiving case details. Please try again.")
        });
  }, [props.data, reload]);

  return (
    <Modal show={props.show} onHide={props.onHide} className="page-modal scrollbar"
      dialogClassName="modal-80w" onShow={() => setKey("corresp")}
      >
      <Modal.Header closeButton>
        <div className="modal-title">
          <span className="cdNum">Case: {row.matter}</span> - <span className="cdName">{row.title}</span> <Badge className="ml-2" variant="secondary" pill>{row.status}</Badge>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-1">
        <Tabs defaultActiveKey={key} id="case-dets">
          <Tab eventKey="corresp" title="Correspondence" >
            <Card body>
              {ac.alertMsg}
              <Correspondence createNew={props.createNew} setIsLoading={setIsLoading} setReload={forceReload} caseTypes={appContext.types} corresps={corresps} reminders={reminders} setReminders={setReminders} data={props.data} isLoading={isLoading}/>
            </Card>
            <Card body>
              <h5 className="mb-2">Uncompleted Reminders</h5>
              <Reminders showActiveOnly={true} corresps={corresps} reminders={[...reminders, ...adhoc_reminders]} setReload={forceReload} data={props.data} isLoading={isLoading} setIsLoading={setIsLoading} />
            </Card>
          </Tab>
          <Tab eventKey="reminders" title="Reminders">
            <Card body>
              {ac.alertMsg}
              <Reminders corresps={corresps} reminders={[...reminders, ...adhoc_reminders]} setReload={forceReload} data={props.data} isLoading={isLoading} setIsLoading={setIsLoading} />
            </Card>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default memo(CaseDetails);