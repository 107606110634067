import React from 'react';
import { Card } from 'react-bootstrap';
import { ThemeContext } from 'styled-components';

const NewCase = () => {

    return (
        <ThemeContext.Provider value="New Case">
            <Card body className="page-height">
                <h3>New Case</h3>
            </Card>
        </ThemeContext.Provider>
    )
}

export default NewCase;