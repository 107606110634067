import moment from 'moment';

function convertArrayOfObjectsToCSV(data, columns) {
	const columnDelimiter = ',';
	const lineDelimiter = '\n';
    const escape = (x) => x && x.toString().match(/[\"\,]/g) ? `"${x.replace(/\"/g, "\"$&")}"` : x;

    // only show displayed columns
    const displayed_cols = columns.map(x => (x.useInExport || (!x.omit && !x.button)) && x).filter(Boolean);
    const headers = displayed_cols.map(x => x.name);
    const keys = displayed_cols.map(x => x.selector);

    const dt = data.map(item => keys
                        .map(x => {
                            let col = columns.find(c => c.selector === x);
                            let val = col && col.format && col.useInExport ? col.format({[x]: item[x]}) : item[x];
                            return escape(val);
                        })
                        .join(',')
                    );

    return headers.join(columnDelimiter)
        + lineDelimiter
        + dt.join(lineDelimiter);
}

export const downloadCSV = (data, columns, filename) => {
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(data, columns);
	if (csv == null) return;

    const today = moment().format("YYYY-MM-DD_HHmm");
	const fqname = `${filename}-${today}.csv`;

    const file = new Blob([csv], {type: 'text/csv'});

	link.setAttribute('href', URL.createObjectURL(file));
	link.setAttribute('download', fqname);
	link.click();
}