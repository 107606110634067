import React, { Fragment, useContext, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import  {Routes} from "./Routes";
import { HMenu, SideBar } from "./components/Nav";
import Switch from 'react-bootstrap/esm/Switch';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import Amplify, { Logger } from 'aws-amplify';
import awsconfig from './aws-exports';
import UserProfile from './lib/UserProfile';
import { getCaseDependencies } from './lib/DoHelpers';
import { LoadingSpinner } from './components/helpers';
import { AppContext } from './components/DWPage';

// Logger.LOG_LEVEL = 'INFO';
// const logger = new Logger('DW-Logger');

Amplify.configure(awsconfig);

export const NavContext = React.createContext();

const Home = (props) => {

  const [globals, setGlobals] = useState(null);

  const update = (ctx, value) => {
    let newCtx = {};
    if(value.newStatuses) {
      newCtx.statuses = value.newStatuses;
    }

    if(Object.keys(globals).length > 0) {
      setGlobals({...ctx, ...newCtx});
    }
  }

  useEffect(() => {
    getCaseDependencies()
        .then(({stats, cts, getCaseTypes}) => {
          stats = stats.map(x => ({value: x.id, label: x.name}));
          cts = cts.map(x => ({value: x.ID, label: x.name}));
          setGlobals({
              types: getCaseTypes,
              statuses: stats,
              countries: cts,
              isMobile: false,
              updateContext: update
          });
        })
        .catch(err => {console.error("Case Deps Query Error:", err)});
  }, [])

  return (
      <AppContext.Provider value={globals}>
        <SideBar onEnter={props.onEnter} onLeave={props.onLeave} toggleSideBar={props.toggleSideBar}
                  onToggleSideBar={props.onToggleSideBar} />
        <div className="content">
          <HMenu />
          <div className="page">
            <Switch>
              {!globals && <LoadingSpinner/>}
              {globals && <Routes user={props.user}/>}
            </Switch>
          </div>
        </div>
      </AppContext.Provider>
  )
};

const Login = props => {

  useEffect(() => {
    document.body.classList.add("login");
  }, []);

  return (
    <Switch>
      <Route path="/">
        <div className="login-wrapper">
          <div className="login-header">
            <h2 className="text-sans-serif brand text-center pt-5">Docket Wizard</h2>
            <p className="lead text-center text-info mb-0">By MAG Systems</p>
          </div>
          <AmplifyAuthenticator usernameAlias="email">
            <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp={true} />
          </AmplifyAuthenticator>
        </div>
      </Route>
    </Switch>
  );
}

const App = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const [page, setPage] = useState();
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isMobileHover, setIsMobileHover] = useState(false);

  var _tsb = false;
  var _isMobile = false;

  function onEnter() {
    setIsHover(true);
  }

  function onLeave() {
    setIsHover(false);
  }

  function onToggleMobile(value) {
    _isMobile = !_isMobile;
    setToggleSideBar(true);
    setIsMobileHover(true);
  }

  function onTSB() {
    _tsb = !_tsb;
    setToggleSideBar(_tsb);
  }

  function setupHome() {
    document.body.classList.remove("login");
    UserProfile.trackLastLogin(user);
    UserProfile.queryUser(user)
      .then(dbuser => {
        setPage(<NavContext.Provider value={{isMobileHover: isMobileHover, setIsMobileHover: (value) => setIsMobileHover(value)}}>
                  <Home user={dbuser} onToggleSideBar={onTSB} onEnter={onEnter} onLeave={onLeave} toggleSideBar={toggleSideBar} />
                </NavContext.Provider>);
      });
  }

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  useEffect(() => {
    authState === AuthState.SignedIn && user ? setupHome() : setPage(<Login />);
  }, [user]);

  return (
    <Router>
      <main id="main">
        <Container fluid className={`${toggleSideBar && !_isMobile ? "navbar-vertical-collapsed" : (_isMobile ? "navbar-vertical-collapsed" : "")}
                ${toggleSideBar && isHover ? "navbar-vertical-collapsed-hover" : ""}`}>
          {page}
        </Container>
      </main>
    </Router>
  )

}

export default App;
