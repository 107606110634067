import { faCopy, faTable, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";


export default class NotFound extends Component {

    render() {
        return (
            <Card body>
                <h2 className="mb-3 text-center">404 - Page not found :(</h2>
                <p className="lead text-center">The page you're trying to visit does not exist. Please choose one of the pages from the navigation menu on the left side.</p>
                <p>Here are some suggestions:</p>
                <ul className="no-list">
                    <li><Link to="/cases/list"><FontAwesomeIcon icon={faCopy} /> Case List</Link></li>
                    <li><Link to="/parties/clients"><FontAwesomeIcon icon={faUsers} /> Client List</Link></li>
                    <li><Link to="/reports/reminders"><FontAwesomeIcon icon={faTable} /> Reminders Report</Link></li>
                </ul>
            </Card>
        )
    }
}