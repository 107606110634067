// Hold all related business logic related to Cases
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { DataType, Model } from '../../lib/Model';
import { getAllReminders, getReminder } from '../../graphql/queries';
import UserProfile from '../../lib/UserProfile';

export default class DoReminder extends Model {

    constructor(form) {
        super(form);
        this.modelClass = {
            AccountId: DataType.Int.name,
            TicklerNo: DataType.Int.name,
            DateCompleted: DataType.AWSDate.name,
            Description: DataType.String.name,
            DueDate: DataType.AWSDate.name,
            Notes: DataType.String.name,
            ManualDate: DataType.AWSDate.name,
            ActSetDate: DataType.Boolean.name,
            ActNo: DataType.Int.name,
            GeneratedBy: DataType.String.name,
            Priority: DataType.Int.name,
        };
    }

    extendModel() {
        this.modelClass = {...this.modelClass,
            TicklerType: DataType.Int.name,
            CaseNo: DataType.Int.name,
            Matter: DataType.String.name,
            ActType: DataType.Int.name,
            ActDate: DataType.AWSDate.name,
            Months: DataType.Int.name,
            Weeks: DataType.Int.name,
            Days: DataType.Int.name,
            excluded: DataType.Boolean.name,
        }
        return this;
    }

    getRemindersByCase(caseNo) {

    }

    getRemindersByCorresp(correspNo) {

    }

    static async getReminder(TicklerNo) {
        return await API.graphql(graphqlOperation(getReminder, {AccountId: UserProfile.getUser().account_id, TicklerNo: TicklerNo}))
        .then(resp => resp.data.getReminder || null);
    }

    static async getAllReminders(from, to, sort, dir, priority, filter) {
        let caseFilter = {};
        if(filter) {
            caseFilter = {...filter};
            if(Object.keys(caseFilter).length > 1) {
                filter.FilingDate && (caseFilter.FilingDateBefore = filter.FilingDate.Before);
                filter.FilingDate && (caseFilter.FilingDateAfter = filter.FilingDate.After);
                filter.IssueDate && (caseFilter.IssueDateBefore = filter.IssueDate.Before);
                filter.IssueDate && (caseFilter.IssueDateAfter = filter.IssueDate.After);
                filter.Status && (caseFilter.Status = filter.Status ? filter.Status.value : "");
                filter.Attorney && (caseFilter.Attorney = filter.Attorney ? filter.Attorney.value : "");
                filter.Client && (caseFilter.Client = filter.Client ? filter.Client.value : "");
                filter.Country && (caseFilter.Country = filter.Country ? filter.Country.value : "");
                caseFilter.From = from;
                caseFilter.To = to;

                delete caseFilter.FilingDate;
                delete caseFilter.IssueDate;
                delete caseFilter.prior;
                delete caseFilter.upcoming;
                delete caseFilter.FromDate;
                delete caseFilter.ToDate;
                delete caseFilter.isCustom;

                caseFilter.Status = DataType.Int.parser(caseFilter.Status);
                caseFilter.Country = DataType.Int.parser(caseFilter.Country);
                caseFilter.Attorney = DataType.Int.parser(caseFilter.Attorney);
                caseFilter.Client = DataType.Int.parser(caseFilter.Client);
                caseFilter.TypeNo = DataType.Int.parser(caseFilter.TypeNo);
                caseFilter.Reminder = DataType.String.parser(caseFilter.Reminder);
                caseFilter.Title = DataType.String.parser(caseFilter.Title);
                caseFilter.FilingDateBefore = DataType.AWSDate.parser(caseFilter.FilingDateBefore);
                caseFilter.FilingDateAfter = DataType.AWSDate.parser(caseFilter.FilingDateAfter);
                caseFilter.IssueDateBefore = DataType.AWSDate.parser(caseFilter.IssueDateBefore);
                caseFilter.IssueDateAfter = DataType.AWSDate.parser(caseFilter.IssueDateAfter);
                caseFilter.ProtectionType = DataType.Int.parser(caseFilter.ProtectionType);
            }

            caseFilter.Active && (caseFilter.Active = DataType.Boolean.parser(caseFilter.Active));
        } else {
            caseFilter = null;
        }
        return await API.graphql(graphqlOperation(getAllReminders, {AccountId: UserProfile.getUser().account_id, Sort: sort,
                                                                    Dir: dir, Priority: priority, filter: caseFilter}))
                        .then(resp => resp.data.getAllReminders || null);
    }

    async updateReminder() {
        this.updateModel();
        this.model.AccountId = UserProfile.getUser().account_id;

        return await API.graphql({ query: mutations.editReminder, variables: {input: this.model}});
    }

    async addReminder() {
        this.updateModel();
        this.model.AccountId = UserProfile.getUser().account_id;

        return await API.graphql({ query: mutations.addReminder, variables: {input: this.model}});
    }

    static async deleteReminder(TicklerNo) {
        if(!TicklerNo)
            throw "Tickler No is not set";

        return await API.graphql({ query: mutations.deleteReminder, variables: {TicklerNo: TicklerNo, AccountId: UserProfile.getUser().account_id}});
    }

}
