/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCase = /* GraphQL */ `
  query GetCase($CaseNo: Int!, $AccountId: Int!) {
    getCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      Case {
        CaseNo
        AccountId
        UserId
        Title
        ClientNo
        ClientName
        ClientCode
        Notes
        Matter
        Examiner
        ArtUnit
        FilingDate
        ParentCase
        Active
        ApplicationNo
        PatentNo
        IssueDate
        Status
        ExpirationDate
        ClientMatter
        PubDate
        PubNo
        GoodsServices
        PriorityDate
        UtilityDesign
        ProvisionalSN
        Country
        IntlClass
        Reel
        Frame
        RecordationDate
        GroupNo
        TypeNo
        ConfirmationNo
        Class
        CustomerNumber
        InternationalRegistration
        EntityStatus
        InternationalPublicationDate
        AIA
        PTOStatus
        PTOStatusDate
        LastUpdate
        parentFilingDate
        inventor
        inventorPhone
        inventorAddress
        assignee
        GroupId
        foreignAgent
        subtechnology
        disclosureRequired
        ReportedNotes
      }
      Parties {
        Id
        PartyType
        Organization
        FirstName
        LastName
        ShortName
        Position
      }
    }
    getRelatedCases(CaseNo: $CaseNo, AccountId: $AccountId) {
      RelAppNo Title
    }
    getGroupByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      Id Name
    }
    getPriotitiesByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      NatCode AppNo FilingDate
    }
    getGroupMembers(CaseNo: $CaseNo, AccountId: $AccountId) {
      Name
    }
    getIntClassByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      Id Name Description
    }
    # getCustomFields(CaseNo: $CaseNo, AccountId: $AccountId) {
    #   CaseNo Pos FieldId label ProtectionType fieldType Value
    # }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases($AccountId: Int!, $filter: CaseFilter) {
    listCases(AccountId: $AccountId, filter: $filter) {
      AIA
      AccountId
      Active
      ApplicationNo
      ArtUnit
      CaseNo
      Class
      ClientMatter
      ClientNo
      ClientName
	    ClientCode
      ConfirmationNo
      Country
      CountryName
      CustomerNumber
      EntityStatus
      Examiner
      ExpirationDate
      FilingDate
      Frame
      GoodsServices
      GroupNo
      InternationalPublicationDate
      InternationalRegistration
      IntlClass
      IssueDate
      LastUpdate
      Matter
      Notes
      PTOStatus
      PTOStatusDate
      ParentCase
      PatentNo
      PriorityDate
      ProvisionalSN
      PubDate
      PubNo
      RecordationDate
      Reel
      Status
      StatusName
      Title
      TypeNo
      UserId
      UtilityDesign
      assignee
      disclosureRequired
      foreignAgent
      inventor
      inventorAddress
      inventorPhone
      parentFilingDate
      subtechnology
      GroupId
      PriorityCases
      Inventors
      ReportedNotes
    }
  }
`;
export const getUser = /* GraphQL */ `
  query getUser($aws_id: String!) {
    getUser(aws_id: $aws_id) {
      id
      account_id
      name
      email
      aws_id
      AccountName
      RoleName
    }
  }
`;
export const getCaseTypes = /* GraphQL */ `
  query getCaseTypes {
    getCaseTypes {
      typeNo
      name
      tag
    }
  }
`;
export const getCorrespByCase = /* GraphQL */ `
  query getCorrespByCase($CaseNo: Int!, $AccountId: Int!) {
    getCorrespByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      Description
      ProtectionType
      Category
      ActDate
      ActNo
      CaseNo
      Notes
      Type
      ResponseCat
      Sent
      Received
      RespondsTo
    }
  }
`;
export const getRemindersByCase = /* GraphQL */ `
  query getRemindersByCase($CaseNo: Int!, $AccountId: Int!) {
    getRemindersByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      ActReminders{
        TicklerType
        TicklerNo
        DateCompleted
        Description
        DueDate
        Notes
        ManualDate
        ActSetDate
        ActNo
        ActType
        ActDate
        GeneratedBy
        Months
        Weeks
        Days
        excluded
        Category
        Priority
        CompletingAct
        Priority
      }
      AdHocReminders {
        TicklerType
        TicklerNo
        DateCompleted
        Description
        DueDate
        Notes
        ManualDate
        ActSetDate
        ActNo
        ActType
        ActDate
        GeneratedBy
        Months
        Weeks
        Days
        excluded
        Category
        Priority
      }
    }
  }
`;
export const getStatuses = /* GraphQL */ `
  query getStatuses($AccountId: Int!, $inputValue: String) {
    getStatuses(AccountId: $AccountId, inputValue: $inputValue) {
      id
      name
    }
  }
`;

export const getCountries = /* GraphQL */ `
  query getCountries($inputValue: String) {
    getCountries(inputValue: $inputValue) {
      ID
      name
    }
  }
`;
export const getCaseDeps = /* GraphQL */ `
  query getCaseDeps($AccountId: Int!, $inputValue: String) {
    getCountries(inputValue: $inputValue) {
      ID
      name
    }
    getCaseTypes {
      typeNo
      name
      tag
    }
    getStatuses(AccountId: $AccountId, inputValue: $inputValue) {
      id
      name
    }
  }
`;
export const getCaseDetails = /* GraphQL */ `
  query getCaseDetails($CaseNo: Int!, $AccountId: Int!) {
    getCorrespByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      Description
      ProtectionType
      Category
      ActDate
      ActNo
      CaseNo
      Notes
      Type
      ResponseCat
      Sent
      Received
      RespondsTo
    }
    getRemindersByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      ActReminders{
        TicklerType
        TicklerNo
        DateCompleted
        Description
        DueDate
        Notes
        ManualDate
        ActSetDate
        ActNo
        ActType
        ActDate
        GeneratedBy
        Months
        Weeks
        Days
        excluded
        Category
        CompletingAct
        Priority
      }
      AdHocReminders {
        TicklerType
        TicklerNo
        DateCompleted
        Description
        DueDate
        Notes
        ManualDate
        ActSetDate
        ActNo
        ActType
        ActDate
        GeneratedBy
        Months
        Weeks
        Days
        excluded
        Category
        CompletingAct
        Priority
      }
    }
  }
`;
export const getActivityTypes = /* GraphQL */ `
  query getActivityTypes($AccountId: Int!, $Type: Int!, $Category: Int!) {
    getActivityTypes(AccountId: $AccountId, Type: $Type, Category: $Category) {
      Type
      Description
    }
  }
`;
export const getRemindersByActivityType = /* GraphQL */ `
  query getRemindersByActivityType($ActType: Int!, $AccountId: Int!) {
    getRemindersByActivityType(ActType: $ActType, AccountId: $AccountId) {
      Weeks
      TicklerType
      Months
      Description
      Days
      Priority
      excluded
    }
  }
`;
export const getParties = /* GraphQL */ `
  query getParties($PartyType: Int!, $AccountId: Int!, $inputValue: String) {
    getParties(PartyType: $PartyType, AccountId: $AccountId, inputValue: $inputValue) {
      Id
      FirstName
      LastName
      Organization
      ShortName
      PartyType
    }
  }
`;
export const getParty = /* GraphQL */ `
  query getParty($Id: Int!, $AccountId: Int!) {
    getParty(Id: $Id, AccountId: $AccountId) {
      AccountId
      Id
      ShortName
      FirstName
      LastName
      Company
      Organization
      Phone
      Mobile
      Email
      Notes
      Address1
      Address2
      City
      State
      Zip
      AddressCountry
      ResidenceCountry
      Citizenship
      PartyType
      Fax
      BillingContact
      BillingAddress
      BillingCity
      BillingState
      BillingZip
      BillingCountry
      BillingPhone
    }
  }
`;
export const getCaseAppNos = /* GraphQL */ `
  query getCaseAppNos($AccountId: Int!, $inputValue: String) {
    getCaseAppNos(AccountId: $AccountId, inputValue: $inputValue) {
      CaseNo
      ApplicationNo
      Title
    }
  }
`;
export const getAllPartiesByType = /* GraphQL */ `
  query getAllPartiesByType($PartyType: Int!, $AccountId: Int!) {
    getAllPartiesByType(PartyType: $PartyType, AccountId: $AccountId) {
      AccountId
      Id
      ShortName
      FirstName
      LastName
      Organization
      Phone
      Mobile
      Notes
      City
      AddressCountry
      Citizenship
      Email
      Company
    }
  }
`;
export const getCasesByParty = /* GraphQL */ `
  query getCasesByParty($PartyId: Int!, $AccountId: Int!) {
    getCasesByParty(PartyId: $PartyId, AccountId: $AccountId) {
      CaseNo
      CountryName
      FilingDate
      Matter
      Title
      StatusName
      ApplicationNo
      TypeNo
      Active
    }
  }
`;
export const getAllActivityTemplate = /* GraphQL */ `
  query getAllActivityTemplate($AccountId: Int!) {
    getAllActivityTemplate(AccountId: $AccountId) {
      Type
      Description
      Category
      ProtectionType
      ProtectionTypeName
    }
  }
`;
export const getAllReminders = /* GraphQL */ `
  query getAllReminders($AccountId: Int!, $From: AWSDate, $To: AWSDate, $Sort: String, $Dir: String, $Priority: String, $filter: CaseFilter) {
    getAllReminders(AccountId: $AccountId, From: $From, To: $To, Sort: $Sort, Dir: $Dir, Priority: $Priority, filter: $filter) {
      ActReminders{
        Matter
        CaseNo
        Title
        Status
        Active
        TypeNo
        TicklerType
        TicklerNo
        DateCompleted
        Description
        DueDate
        Notes
        ManualDate
        ActSetDate
        ActNo
        ActType
        ActDate
        GeneratedBy
        Months
        Weeks
        Days
        excluded
        Category
        Priority
        Country
        ApplicationNo
        FilingDate
        IssueDate
        ClientMatter
        Client
        CaseType
      }
      AdHocReminders {
        Matter
        CaseNo
        Title
        Status
        TypeNo
        Active
        TicklerType
        TicklerNo
        DateCompleted
        Description
        DueDate
        Notes
        ManualDate
        ActSetDate
        ActNo
        ActType
        ActDate
        GeneratedBy
        Months
        Weeks
        Days
        excluded
        Category
        Priority
        Country
        ApplicationNo
        FilingDate
        IssueDate
        ClientMatter
        Client
        CaseType
      }
    }
  }
`;
export const getReminder = /* GraphQL */ `
  query getReminder($AccountId: Int!, $TicklerNo: Int!) {
    getReminder(AccountId: $AccountId, TicklerNo: $TicklerNo) {
      Matter
      TicklerType
      TicklerNo
      DateCompleted
      Description
      DueDate
      Notes
      ManualDate
      ActSetDate
      ActNo
      ActType
      ActDate
      GeneratedBy
      Months
      Weeks
      Days
      excluded
      Category
      Priority
    }
  }
`;
export const getCaseGroups = /* GraphQL */ `
  query getCaseGroups($AccountId: Int!, $inputValue: String) {
    getCaseGroups(AccountId: $AccountId, inputValue: $inputValue) {
      Id Name
    }
  }
`;
export const getPriotitiesByCase = /* GraphQL */ `
  query getPriotitiesByCase($CaseNo: String, $AccountId: Int!) {
    getPriotitiesByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      NatCode AppNo FilingDate
    }
  }
`;
export const getCustomFields = /* GraphQL */ `
  query getCustomFields($CaseNo: String, $AccountId: Int!) {
    getCustomFields(CaseNo: $CaseNo, AccountId: $AccountId) {
      CaseNo Pos FieldId label ProtectionType fieldType Value
    }
  }
`;
export const getIntClassByCase = /* GraphQL */ `
  query getIntClassByCase($CaseNo: Int, $AccountId: Int!) {
    getIntClassByCase(CaseNo: $CaseNo, AccountId: $AccountId) {
      Id Name Description CaseNo
    }
  }
`;
export const isMatterUnique = /* GraphQL */ `
  query isMatterUnique($Matter: String!, $AccountId: Int!) {
    isMatterUnique(Matter: $Matter, AccountId: $AccountId)
  }
`;