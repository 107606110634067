// Hold all related business logic related to Cases
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { DataType, Model } from '../../lib/Model';
import UserProfile from '../../lib/UserProfile';
import { getActivityTypes, getRemindersByActivityType } from '../../graphql/queries';


export default class DoCorrespondence extends Model {

    constructor(form) {
        super(form);
        this.modelClass = {
            ActNo: DataType.Int.name,
            CaseNo: DataType.Int.name,
            AccountId: DataType.Int.name,
            RespondsTo: DataType.Int.name,
            ActDate: DataType.AWSDate.name,
            Notes: DataType.String.name,
            ReminderIds: DataType.String.name,
            RespReminderIds: DataType.String.name
        };
    }

    getCorrespByCase(caseNo) {

    }

    async addCorresp() {
        this.model.ReminderIds = this.model.ReminderIds.join(",");
        this.model.RespReminderIds = this.model.RespReminderIds.join(",");
        this.model.AccountId = UserProfile.getUser().account_id;

        this.updateModel();

        if(!this.model.ActType)
            return new Error("Correspondence type is missing");

        return await API.graphql({ query: mutations.AddCorrespondence, variables: {input: this.model}});
    }

    async updateCorresp() {
        this.model.ReminderIds = this.model.ReminderIds.join(",");
        this.model.RespReminderIds = this.model.RespReminderIds.join(",");
        this.model.AccountId = UserProfile.getUser().account_id;

        this.updateModel();

        return await API.graphql({ query: mutations.EditCorrespondence, variables: {input: this.model}});
    }

    static async getActivityTypes(Type, Category) {
        return API.graphql(graphqlOperation(getActivityTypes, {AccountId: UserProfile.getUser().account_id, Type: Type, Category: Category}))
                    .then(resp => resp.data.getActivityTypes || [])
    }

    static async getRemindersByActivityType(ActType) {
        return API.graphql(graphqlOperation(getRemindersByActivityType, {ActType: ActType, AccountId: UserProfile.getUser().account_id}))
                    .then(resp => resp.data.getRemindersByActivityType || [])
    }

    static async deleteCorrespondence(ActNo) {
        if(!ActNo)
            throw "Correspondence No is not set";

        return await API.graphql({ query: mutations.deleteCorrespondence, variables: {ActNo: ActNo, AccountId: UserProfile.getUser().account_id}});
    }

}
