// Holds all related business logic related to Parties
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { DataType, Model } from '../../lib/Model';
import { getAllPartiesByType, getParties, getParty } from '../../graphql/queries';
import UserProfile from '../../lib/UserProfile';
import { PartyTypes } from '../../lib/helpers';

function getShortName(shortName) {
    return shortName ? "(" + (shortName || "") + ")" : "";
}

export default class DoParties extends Model {

    constructor(form) {
        super(form);
        this.modelClass = {
            AccountId: DataType.Int.name,
            Id: DataType.Int.name,
            ShortName: DataType.String.name,
            FirstName: DataType.String.name,
            LastName: DataType.String.name,
            Company: DataType.String.name,
            Organization: DataType.String.name,
            Phone: DataType.String.name,
            Mobile: DataType.String.name,
            Email: DataType.String.name,
            Notes: DataType.String.name,
            Address1: DataType.String.name,
            Address2: DataType.String.name,
            City: DataType.String.name,
            State: DataType.String.name,
            Zip: DataType.String.name,
            AddressCountry: DataType.String.name,
            ResidenceCountry: DataType.String.name,
            Citizenship: DataType.String.name,
            PartyType: DataType.Int.name,
            Fax: DataType.String.name,
            BillingContact: DataType.String.name,
            BillingAddress: DataType.String.name,
            BillingCity: DataType.String.name,
            BillingState: DataType.String.name,
            BillingZip: DataType.String.name,
            BillingCountry: DataType.String.name,
            BillingPhone: DataType.String.name,
        };
    }

    static isValidType(type) {
        return Object.keys(PartyTypes).find(x => PartyTypes[x] === type)
    }

    static async getClientList() {
        return await API.graphql(graphqlOperation(getAllPartiesByType, {PartyType: PartyTypes.Client, AccountId: UserProfile.getUser().account_id}))
            .then(resp => resp.data.getAllPartiesByType || []);
    }

    static async getInventorList() {
        return await API.graphql(graphqlOperation(getAllPartiesByType, {PartyType: PartyTypes.Inventor, AccountId: UserProfile.getUser().account_id}))
            .then(resp => resp.data.getAllPartiesByType || []);
    }

    static async getAgentsList() {
        return await API.graphql(graphqlOperation(getAllPartiesByType, {PartyType: PartyTypes.Agent, AccountId: UserProfile.getUser().account_id}))
            .then(resp => resp.data.getAllPartiesByType || []);
    }

    static async getAttorneyList() {
        return await API.graphql(graphqlOperation(getAllPartiesByType, {PartyType: PartyTypes.Attorney, AccountId: UserProfile.getUser().account_id}))
            .then(resp => resp.data.getAllPartiesByType || []);
    }

    static async getClients(inputValue) {
        return await API.graphql(graphqlOperation(getParties, {PartyType: PartyTypes.Client, AccountId: UserProfile.getUser().account_id, inputValue: inputValue || ""}))
                .then(resp => resp.data.getParties || [])
                .then(parties => parties.map(x => ({value: x.Id, label: x.Organization + " " + getShortName(x.ShortName)}) ));
    }

    static async getNamedClients(inputValue) {
        return await API.graphql(graphqlOperation(getParties, {PartyType: PartyTypes.Client, AccountId: UserProfile.getUser().account_id, inputValue: inputValue || ""}))
                .then(resp => resp.data.getParties || [])
                .then(parties => parties.map(x => ({value: x.Organization, label: x.Organization + " (" + getShortName(x.ShortName)}) ));
    }

    static async getAttorneys(inputValue) {
        return await API.graphql(graphqlOperation(getParties, {PartyType: PartyTypes.Attorney, AccountId: UserProfile.getUser().account_id, inputValue: inputValue || ""}))
                .then(resp => resp.data.getParties || [])
                .then(parties => parties.map(x => ({value: x.Id, label: getShortName(x.ShortName) + " " + (x.FirstName || "") + " " + (x.LastName || "")}) ));
    }

    static async getAgents(inputValue) {
        return await API.graphql(graphqlOperation(getParties, {PartyType: PartyTypes.Agent, AccountId: UserProfile.getUser().account_id, inputValue: inputValue || ""}))
                .then(resp => resp.data.getParties || [])
                .then(parties => parties.map(x => ({value: x.Id, label: getShortName(x.ShortName) + " " + (x.FirstName || "") + " " + (x.LastName || "")}) ));
    }

    static async getInventors(inputValue) {
        return await API.graphql(graphqlOperation(getParties, {PartyType: PartyTypes.Inventor, AccountId: UserProfile.getUser().account_id, inputValue: inputValue || ""}))
                .then(resp => resp.data.getParties || [])
                .then(parties => parties.map(x => ({value: x.Id, label: (x.FirstName || "") + " " + (x.LastName || ""), Organization: x.Organization}) ));
    }

    static async getParty(Id) {
        return await API.graphql(graphqlOperation(getParty, {Id: Id, AccountId: UserProfile.getUser().account_id}))
                        .then(resp => resp.data.getParty);
    }

    async persist() {
        this.model.AccountId = UserProfile.getUser().account_id;
        this.updateModel();

        return await API.graphql({ query: mutations.editParty, variables: {input: this.model}})
                        .then(resp => resp.data.editParty);
    }

    static async deleteParty(Id) {
        if(!Id)
            return new Error("Party No is not set");

        return await API.graphql({ query: mutations.deleteParty, variables: {Id: Id, AccountId: UserProfile.getUser().account_id}});
    }

}
