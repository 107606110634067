import React, {  Fragment, memo, useEffect, useRef, useState } from 'react';
import { Card, Col, FormControl, InputGroup, ListGroup, Row, Form, Modal, Button, Spinner } from 'react-bootstrap';
import { Formik, Form as KiForm } from 'formik';
import { SelectField } from '../../components/fields';
import { AlertClass, LoadingSpinner, } from '../../components/helpers';
import moment from 'moment';
import DoCorrespondence from './DoCorrespondence';
import { CorrespFields, RespondFields } from './CorrespForm';
import { sortByDate, searchAll, calcDueDate } from '../../lib/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faEnvelope, faSearch } from '@fortawesome/free-solid-svg-icons';

const NewCorrespForm = (props) => {
    const ac = AlertClass();
    const [form, setForm] = useState(<Card body><LoadingSpinner/></Card>);
    const [rules, setRules] = useState([]);
    const [isRemLoading, setRemLoading] = useState(true);
    const actTypeRef = useRef();
    const [selectedActivity, setSelectedActivity] = useState();
    const [search, setSearch] = useState("");
    const actDate = useRef(new Date());

    const {curcase, caseTypes, activityTypes, IniActivityTypes, isATLoading, setActivityTypes,
            categType, curCorresps, curReminders, setSelectedType, onHide, respTo} = props;
    const bgType = {0: "bg-sent", 1: "bg-received"};
    const bgTypeN = {0: "bg-received", 1: "bg-sent"};

    const handleCorespClick = (cp, evt) => {
        setSelectedActivity(cp);
    };

    const handlePTypeChanged = (props, evt) => {
        props.handleChange(evt);
        setSelectedType(evt.target.value);
    }

    const onSearch = evt => {
        let term = evt.target.value;
        setSearch(term);
        setActivityTypes(IniActivityTypes.filter(x => term.trim() === "" ? true : searchAll(x, term)));
    }

    const onKeyDown = evt => {
        if ((evt.charCode || evt.keyCode) === 13) {
            evt.preventDefault();
        }
    }

    const onSubmit = (values, helpers) => {
        if(!selectedActivity) {
            ac.showErrorMsg("No correspondence type selected. Please choose a correspondence type from the left side list.");
            actTypeRef.current && actTypeRef.current.classList.add("border-danger", "border");
            helpers.setSubmitting(false);
            return;
        }
        let act = {
            ActType: selectedActivity,
            ActNo: null,
            CaseNo: curcase.caseno,
            RespondsTo: values.respondsTo.ActNo,
            ActDate: values.ActDate,
            Notes: values.Notes,
            ReminderIds: values.reminderIds,
            RespReminderIds: values.respondsTo.reminderIds
        }
        let doco = new DoCorrespondence(act);
        doco.addCorresp()
            .then(resp => resp.data.addActivity || {})
            .then(coresp => {
                helpers.setSubmitting(false);
                if(coresp) {
                    ac.showInfo("Correspondence successfully Created");
                    props.onRowAdded(coresp);
                    setTimeout(props.onHide, 200);
                } else {
                    ac.showErrorMsg("Adding new correspondence failed.");
                }
            })
            .catch(resp => {
                console.error("Mutation Error:", resp);
                ac.showErrorMsg("Adding new correspondence failed.");
                helpers.setSubmitting(false);
            });
    }

    useEffect(() => {
        setRemLoading(true);
        setRules([]);
        ac.hideAll();
        if(!selectedActivity) {
            setRemLoading(false);
            return;
        }
        selectedActivity && DoCorrespondence.getRemindersByActivityType(selectedActivity)
            .then(reminders => {
                setRules(sortByDate(reminders.map(x => {
                    x.DueDate =  calcDueDate(x, actDate.current);
                    return x;
                }), "DueDate"));
                setRemLoading(false);
            })
            .catch(err => {
                console.error("Query Error:", err);
                setRemLoading(false);
                ac.showErrorMsg("Error retreiving reminders rules. Please try again.")
            });
    }, [selectedActivity]);

    useEffect(() => {
        let cfData = {
            TypeNo: curcase.type,
            ActNo: "",
            ActDate: moment().format("YYYY-MM-DD"),
            Notes: "",
            reminderIds: rules.map(x => x.TicklerType),
            excludedIds: [],
            respondsTo: {
                ActNo: respTo || "",
                ActType: "",
                reminderIds: [],
                excludedIds: [],
            }
        };

        setForm(
            <Formik
                isSubmitting
                initialValues={{...cfData}}
                validate={(values) => {
                    let errors = {};
                    if(!values.ActDate)
                        errors.ActDate = "Please enter a the correspondence date.";
                    else
                        actDate.current = values.ActDate;
                    return errors;
                }}
                onSubmit={onSubmit}
            >
                {props => (
                    <KiForm noValidate onKeyDown={onKeyDown} className="modal-content" style={{maxHeight: "calc(100vh - 61px)"}}>
                        <Modal.Body>
                            <Row>
                                <Col xs="4">
                                    <Card className="sticky-top">
                                        <Card.Header className={`h5 py-2 text-gray ${bgType[categType]}`}>
                                            New {categType === 1 ? "Received" : "Sent"} Correspondence
                                        </Card.Header>
                                        <Card.Body>
                                            <SelectField className="form-control-sm" label="Protection Type" name="TypeNo" value={props.values.TypeNo} onChange={evt => {handlePTypeChanged(props, evt)}}>
                                                <option key="0" value={0}>All</option>
                                                {caseTypes && caseTypes.map(x => (
                                                    <option key={x.typeNo} value={x.typeNo}>{x.name}</option>
                                                ))}
                                            </SelectField>
                                            <Form.Label>Correspondence Type</Form.Label>
                                            <InputGroup size="sm" className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroup-sizing-sm"><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl key="search-remd" aria-label="Seach" value={search} aria-describedby="inputGroup-sizing-sm" onChange={onSearch} />
                                            </InputGroup>
                                            {isATLoading ? <LoadingSpinner/> : <ListGroup ref={actTypeRef} defaultActiveKey="#0" className="w-100 narrow att">
                                                {activityTypes && activityTypes.map( (x, i) => (
                                                    <ListGroup.Item href={`#c${i}`} key={i} action onClick={evt => handleCorespClick(x.Type, evt)}>
                                                        <span className="mr-2">{categType === 1 ? <FontAwesomeIcon icon={faEnvelopeOpenText} /> : <FontAwesomeIcon icon={faEnvelope} /> }</span>
                                                        {x.Description}
                                                    </ListGroup.Item>
                                                ))}
                                                {activityTypes && activityTypes.length === 0 && <ListGroup.Item >&nbsp;&nbsp;</ListGroup.Item>}
                                            </ListGroup>}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="pl-1">
                                    <Card className="mb-3">
                                        <Card.Header className={`h5 py-2 text-gray ${bgType[categType]}`}>
                                                <div className="d-flex justify-content-between">
                                                    Reminders
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                {isRemLoading ? <LoadingSpinner/> : <CorrespFields reminders={rules} onHide={onHide} {...props} />}
                                            </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header className={`h5 py-2 text-gray ${bgTypeN[categType]}`}>Responds To</Card.Header>
                                        <Card.Body className="">
                                            <RespondFields reminders={curReminders}
                                                            corresps={curCorresps} {...props} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-start">
                            <Form.Group>
                                <Button variant="primary" type="submit" name="save" disabled={props.isSubmitting} size="sm" className="mr-2">{props.isSubmitting ? <>Saving <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></> : "Save"}</Button>
                                <Button variant="outline-secondary" type="button" name="close" size="sm" className="btn-falcon-default" onClick={onHide}>Cancel</Button>
                            </Form.Group>
                        </Modal.Footer>
                    </KiForm>
                )}
            </Formik>
        );
    }, [activityTypes, isATLoading, selectedActivity, isRemLoading]);

    return (
        <Fragment>
            {ac.alertMsg}
            {form}
        </Fragment>
    );
};

export default memo(NewCorrespForm);