import React, { Component, Fragment, memo, useEffect, useState, } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Card, InputGroup, FormControl, Form, Col, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faSync, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { ActType, ActTypeCategories, handleChangeRowsPerPage, prepareRows, searchAll } from '../../lib/helpers';
import { AlertClass, AlertComponent, AlertModalComponent, LoadingSpinner } from '../../components/helpers';
import memoize from 'memoize-one';
import DoTemplate from './DoTemplate';
import Select from 'react-select';
import { Formik, Form as KiForm, FieldArray } from 'formik';
import { TextField, ReactSelect, SelectField } from '../../components/fields';
import UserProfile from '../../lib/UserProfile';

const EditTemplate = props => {

    const {pTypes, onHide, onDelete} = props;
    const [reminders, setReminders] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const ac = AlertClass();
    var {data} = props;

    const onSubmit = (values, helpers) => {
        let pt = values.coresp.protectiontype.constructor.name === "Object" ? values.coresp.protectiontype.value : values.coresp.protectiontype;
        let ct = values.coresp.category.constructor.name === "Object" ? values.coresp.category.value : values.coresp.category;
        console.log("values:", values);
        if(ct !== ActType.Received && ct !== ActType.Sent) {
            helpers.setFieldError("coresp.category", "Category is required!");
            helpers.setSubmitting(false);
            return;
        }
        let temp = {AccountId:null,
            Description:values.coresp.description,
            ProtectionType:pt, Type:values.coresp.id,
            Category: ct
        };

        let dt = new DoTemplate(temp);
        dt.persist(values.reminders, reminders)
            .then(tp => {
                console.log("Edited result:", tp);
                helpers.setSubmitting(false);
                if(tp) {
                    props.onRowEdit(tp.coresp);
                    props.onHide();
                } else {
                    ac.showErrorMsg("Editing the templated failed. Please try again.");
                }
            })
            .catch(x => {
                console.log("Mutation Error:", x);
                ac.showErrorMsg("Editing the template failed.");
                helpers.setSubmitting(false);
            });
    }

    const remValidator = val => {
        if(parseInt(val) === NaN)
            return "Must be a valid number";

        if(parseInt(val) < 0)
            return "Must be a positive number";
    }

    useEffect(() => {
        setIsLoading(true);
        setReminders([]);
        ac.hideAll();

        if(data && data.isNew){
            console.log("New data:", data);
            setReminders([]);
            setIsLoading(false);
        } else {
            data && DoTemplate.getReminders(data.id)
            .then(rems => setReminders(rems))
            .then(x => setIsLoading(false))
            .catch(err => {
                console.error("Query Error:", err);
                setIsLoading(false);
                ac.showErrorMsg("Error retreiving reminders for this template. Please try again.")
            });
        }
    }, [data]);

    return (
      <Modal show={props.show} onHide={props.onHide} onExited={props.onExited} className="page-modal scrollbar page-2"
            dialogClassName="modal-70w"
        >
        <Modal.Header closeButton>
          <div className="modal-title">
            <h5>Edit Template</h5>
          </div>
        </Modal.Header>
        {isLoading && <Modal.Body>{ac.alertMsg} <Card body><LoadingSpinner/></Card></Modal.Body>}
        {!isLoading && reminders &&
            <Formik
                isSubmitting
                initialValues={{coresp: data, reminders: reminders}}
                validate={(values) => {
                    let errors = {};
                    if(values.coresp.description === "") {
                        errors.coresp = {};
                        errors.coresp.description = "Description is required!";
                    }

                    if(!values.coresp.protectiontype) {
                        if(!errors.coresp)
                            errors.coresp = {};
                        errors.coresp.protectiontype = "Protection Type is required!";
                    }

                    // if(values.coresp.category === null) {
                    //     if(!errors.coresp)
                    //         errors.coresp = {};
                    //     errors.coresp.category = "Category Type is required!";
                    // }

                    return errors;
                }}
                onSubmit={onSubmit}
            >
            {({values, isSubmitting, ...props}) => (
                <KiForm noValidate className="modal-content" style={{maxHeight: "calc(100vh - 61px)"}}>
                    <Modal.Body>
                            {ac.alertMsg}
                            <Card body>
                                <TextField required label="Description" name="coresp.description" value={values.coresp.description} />
                                <Form.Row>
                                    <ReactSelect
                                        as={Col}
                                        label="Protection Type"
                                        name="coresp.protectiontype"
                                        isClearable={false}
                                        value={pTypes.find(x => x.value == values.coresp.protectiontype)}
                                        options={pTypes}/>
                                    <ReactSelect isDisabled={true} as={Col} label="Correspondence Type" name="coresp.category" value={ActTypeCategories.find(x => x.value === values.coresp.category)}
                                        options={ActTypeCategories} />
                                </Form.Row>
                                <div className="my-3 p-3 rounded bg-light border shadow-sm">
                                    <Form.Label className="text-primary text-center w-100 font-weight-bolder">Automatically Generated Reminders</Form.Label>
                                    <Form.Row className="mb-4">
                                        <Col xs={5} title="Description" className="text-truncate text-center font-weight-bold text-nowrap">Description</Col>
                                        <Col xs={4}>
                                            <Form.Row>
                                                <Col xs={4} title="Month" className="text-truncate text-center font-weight-bold">Months</Col>
                                                <Col xs={4} title="Week" className="text-truncate text-center font-weight-bold">Weeks</Col>
                                                <Col xs={4} title="Day" className="text-truncate text-center font-weight-bold">Days</Col>
                                            </Form.Row>
                                        </Col>
                                        <Col xs={2} title="Priority" className="text-truncate text-center font-weight-bold">Priority</Col>
                                        <Col xs={1} className="text-center font-weight-bold"></Col>
                                    </Form.Row>
                                    <FieldArray name="reminders">
                                        {({ insert, remove, push }) => (
                                            <Fragment>
                                                {values.reminders && values.reminders.map((x, index) => (
                                                    <Form.Row key={index}>
                                                        <Col xs={5} className=" text-nowrap"><TextField validate={val => !val && "Description is required"} size="sm" name={`reminders.${index}.Description`} value={x.Description} /></Col>
                                                        <Col xs={4}>
                                                            <Form.Row>
                                                                <Col xs={4} className=""><TextField validate={remValidator} min="0" type="number" size="sm" name={`reminders.${index}.Months`} value={x.Months} /></Col>
                                                                <Col xs={4} className=""><TextField validate={remValidator} type="number" size="sm" name={`reminders.${index}.Weeks`} value={x.Weeks} /></Col>
                                                                <Col xs={4} className=""><TextField validate={remValidator} min="0" type="number" size="sm" name={`reminders.${index}.Days`} value={x.Days} /></Col>
                                                            </Form.Row>
                                                        </Col>
                                                        <Col xs={2} className="text-center">
                                                            <SelectField name={`reminders.${index}.Priority`} >
                                                                <option value=""> -- </option>
                                                                <option value="2"> Medium </option>
                                                                <option value="1"> High </option>
                                                            </SelectField>
                                                        </Col>
                                                        <Col xs={1}>{UserProfile.canEdit() && <Button title="Remove" className="xs" variant="outline-secondary" size="sm" onClick={() => remove(index)}><FontAwesomeIcon icon={faMinus}/></Button>}</Col>
                                                    </Form.Row>
                                                ))}
                                                {UserProfile.canEdit() && <Button className="btn-falcon-default mt-3" size="sm" onClick={() => push({ Description: '', Months: 0, Weeks: 0, Days: 0, Priority: 0 })}><FontAwesomeIcon icon={faPlus} /> Add a Reminder Rule</Button>}
                                            </Fragment>
                                        )}
                                    </FieldArray>
                                </div>
                            </Card>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-start">
                        <Form.Group>
                            {UserProfile.canEdit() ?
                                <Fragment>
                                    <Button type="submit" disabled={isSubmitting} className="mr-2" size="sm">{isSubmitting ? <>Saving <Spinner size="sm" as="span" animation="border" role="status" aria-hidden="true" /></> : "Save"}</Button>
                                    <Button type="reset" disabled={isSubmitting} onClick={onHide} className="btn-falcon-default" size="sm" >Cancel</Button>
                                    {!data.isNew && <span className="mx-3 text-muted">|</span>}
                                    {!data.isNew && <Button type="reset" disabled={isSubmitting} onClick={onDelete} className="btn-falcon-danger" size="sm"><FontAwesomeIcon icon={faTrashAlt} /> Delete</Button>}
                                </Fragment> :
                                <Button type="reset" disabled={isSubmitting} onClick={onHide} className="btn-falcon-default" size="sm" >Close</Button>}
                        </Form.Group>
                    </Modal.Footer>
                </KiForm>
            )}
        </Formik>}
      </Modal>
    );
}

const TableActions = (props) =>  {

    return (
        <div className="d-flex flex-row align-items-center">
            {UserProfile.canEdit() && <Button className="align-self-start mr-2 btn-falcon-primary" value={props.btnValue} size="sm" onClick={props.onAddNewRow}><FontAwesomeIcon icon={faPlus} /> Add New</Button>}
            <Button disabled={props.isLoading} title="Refresh" onClick={props.onRefresh} size="sm" className="align-self-start mr-2 btn-falcon-default"><FontAwesomeIcon icon={faSync} /></Button>
            <div>
                <InputGroup size="sm">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-sm">Search</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl key="search-remd" aria-label="Seach" name={props.name} value={props.value} aria-describedby="inputGroup-sizing-sm" onChange={props.onSearch} />
                </InputGroup>
                <Form.Text className="text-muted">Search with any criteria.</Form.Text>
            </div>
        </div>
    );
}

const ic = {name: "", sortable: true, selector: "", allowOverflow:true, center: false};
const columns = memoize((onDelete) => [
    {...ic, name: "ID", col: "Type", selector: "id", width: "50px", center: true, omit: true},
    {...ic, name: "Category", col: "Category", selector: "category", omit: true},
    {...ic, name: "Description", col: "Description", selector: "description", grow:2, wrap: true},
    {...ic, name: "TypeId", col: "ProtectionType", selector: "protectiontype", omit: true},
    {...ic, name: "Type", col: "ProtectionTypeName", selector: "typename", wrap: true},
]);

class Template extends Component {

    constructor(props) {
        super(props);
        this.state = {received_data: [], sent_data: [], show: false, editShow: false, row: null, received_search: "", sent_search: "",
                        re_init_data: [], se_init_data: [], isLoading: true, ProtectionTypes: [],
                        selectedType: ""};
        this.columns = columns(this.onDelete);
        this.am = React.createRef();
        this.alert = React.createRef();
    }

    componentDidMount() {
        DoTemplate.getProtectionTypes()
                    .then(data => this.setState({ProtectionTypes: data}))
                    .catch(err => {
                        console.log("ProtectionType Query error: ", err);
                        this.alert.current.showInfo("Could not load Protection Types. Please try again.");
                        this.setState({isLoading: false});
                    });
        this.getData();
    }

    getData() {
        DoTemplate.getTemplateList()
            .then(({sent_data, received_data}) => {
                let sent = prepareRows(this.columns, sent_data);
                let received = prepareRows(this.columns, received_data);
                this.setState({received_data: received, re_init_data: received,
                                sent_data: sent, se_init_data: sent,
                                isLoading: false});
            })
            .catch(err => {
                console.log("Query error: ", err);
                let m = <span>An Error has occured. Please <a href="#" onClick={() => window.location.reload()}>reload the page</a> to try again.</span>;
                this.alert.current.showErrorMsg(m);
                this.setState({isLoading: false});
            });
    }

    searchData(source) {
        let types = this.state.selectedType || [];
        let term, data_list;
        switch(source) {
            case "r":
                term = this.state.received_search;
                if(!term && !types.length) {
                    return this.state.re_init_data;
                }
                data_list = this.state.re_init_data;
                break;
            case "s":
                term = this.state.sent_search;
                if(!term && !types.length) {
                    return this.state.se_init_data;
                }
                data_list = this.state.se_init_data;
                break;
        }

        var a = data_list.filter(el => {
            if(!term && types.length) {
                return types.filter(x => el.protectiontype === x.value || !x.value).length > 0;
            }

            let l = searchAll(el, term);
            return !types.length ? l : l && types.filter(x => el.protectiontype === x.value || !x.value).length > 0;
        });

        return a;
    }

    onRowSearch = evt => {
        let term, target;
        if(!evt || Array.isArray(evt)) {
            target = "ptype";
        } else {
            term = evt.target.value;
            target = evt.target.name;
        }
        switch(target) {
            case "r_search":
                this.setState({received_search: term}, () => this.setState({received_data: this.searchData("r")}));
                break;
            case "s_search":
                this.setState({sent_search: term}, () => this.setState({sent_data: this.searchData("s")}));
                break;
            case "ptype":
                this.setState({selectedType: evt}, x => {
                    this.setState({received_data: this.searchData("r"),
                                sent_data: this.searchData("s")});
                });
                break;
            default:
                console.log("Went to default:", target);
        }
    }

    onAddNewRow = evt => {
        this.setState({row: {isNew: true, category: parseInt(evt.currentTarget.value), id: null, description: '', protectiontype: 1},
                        show: true});
    }

    onRowClick = x => {
        this.setState({row: x, show: true});
    }

    onRowEdit = tp => {
        let alert = `Correspondence template \"${tp.Description}\" was successfully `;
        alert += this.state.row.isNew ? "added." : "updated.";

        this.alert.current.showInfo(alert);

        this.state.row.description = tp.Description;
        this.state.row.protectiontype = tp.ProtectionType;
        this.state.row.typename = tp.ProtectionTypeName;
        this.state.row.id = tp.Type;
        this.state.row.category = tp.Category;

        if(tp.Category === ActType.Received) {
            if(this.state.row.isNew) {
                let a = [...this.state.re_init_data, this.state.row];
                this.setState({received_data: a, re_init_data: a});
            }
            else
                this.setState({received_data: this.state.re_init_data, re_init_data: this.state.re_init_data});

        } else if(tp.Category === ActType.Sent) {
            if(this.state.row.isNew) {
                let a = [...this.state.se_init_data, this.state.row];
                this.setState({sent_data: a, se_init_data: a});
            }
            else
                this.setState({sent_data: this.state.se_init_data});
        }

        delete this.state.row.isNew;

        this.setState({isLoading: true}, () => this.setState({isLoading: false}));
    }

    onDelete = () => {
        let row = this.state.row;
        this.am.current.showConfirm("WARNING!", `Deleting template "${row.description}". Are you sure you want to proceed?`,
                () => {
                this.setState({show: false});
                DoTemplate.deleteTemplate(row.id)
                    .then(resp => resp.data.deleteTemplate || null)
                    .then(deleted => {
                        this.alert.current.showInfo(`Correspondence template "${row.description}" was successfully deleted.`);
                        if(row.category === ActType.Received) {
                            let a = this.state.re_init_data.filter(x => x.id != row.id);
                            this.setState({received_data: a, re_init_data: a, isLoading: true}, () => this.setState({isLoading: false}));
                        }
                        else {
                            let a = this.state.se_init_data.filter(x => x.id != row.id)
                            this.setState({sent_data: a, se_init_data: a, isLoading: true}, () => this.setState({isLoading: false}));
                        }
                    })
                    .catch(resp => {
                        console.error("Mutation Error:", resp);
                        this.alert.current.showErrorMsg(`Deleting Correspondence template "${row.description}" failed.`);
                    });
                });
    }

    onRefresh = () => {
        this.setState({isLoading: true, sent_search: "", received_search: ""});
        this.getData();
    }

    render() {
        return (
            <Fragment>
                {<AlertComponent ref={this.alert} />}
                <div className="card mb-3 p-3 bg-light">
                    <Form.Label className="text-primary">Protection Type:</Form.Label>
                    <Select
                        name="ptype"
                        isClearable={false}
                        isSearchable={false}
                        defaultValue={this.state.selectedType}
                        isMulti
                        onChange={this.onRowSearch}
                        options={this.state.ProtectionTypes}/>
                </div>
                <Form.Row>
                    <Col>
                        <Card body className="page-height page-ctrl no-table-header">
                            <div className="d-flex justify-content-between">
                                <h3 className="page-title">Received</h3>
                                <div className="">
                                    <TableActions name="r_search" search={this.state.received_search} onSearch={this.onRowSearch} onAddNewRow={this.onAddNewRow} btnValue={ActType.Received} onRefresh={() => this.onRefresh(ActType.Received)} />
                                </div>
                            </div>
                            <DataTable
                                key="client-table"
                                progressPending={this.state.isLoading}
                                progressComponent={<LoadingSpinner />}
                                columns={this.columns}
                                data={this.state.received_data}
                                allowOverflow={true}
                                responsive
                                noHeader
                                fixedHeader
                                pagination
                                paginationPerPage={UserProfile.getRowsPerPage()}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                paginationRowsPerPageOptions={[25, 50, 100, 200]}
                                pointerOnHover
                                highlightOnHover
                                onRowClicked={this.onRowClick}
                                paginationComponentOptions={{rowsPerPageText: 'Show received: ', rangeSeparatorText: 'To', selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                            />
                            <div className="font-weight-bold rows-total">{this.state.received_data.length} templates found.</div>
                        </Card>
                    </Col>
                    <Col>
                        <Card body className="page-height page-ctrl no-table-header">
                            <div className="d-flex justify-content-between">
                                <h3 className="page-title">Sent</h3>
                                <div className="">
                                    <TableActions name="s_search" search={this.state.sent_search} onSearch={this.onRowSearch} onAddNewRow={this.onAddNewRow} btnValue={ActType.Sent} onRefresh={() => this.onRefresh(ActType.Sent)}/>
                                </div>
                            </div>
                            <DataTable
                                key="client-table"
                                progressPending={this.state.isLoading}
                                progressComponent={<LoadingSpinner />}
                                columns={this.columns}
                                data={this.state.sent_data}
                                allowOverflow={true}
                                responsive
                                noHeader
                                fixedHeader
                                pagination
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50, 100, 200]}
                                pointerOnHover
                                highlightOnHover
                                onRowClicked={this.onRowClick}
                                paginationComponentOptions={{rowsPerPageText: 'Show sent: ', rangeSeparatorText: 'To', selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                            />
                            <div className="font-weight-bold rows-total">{this.state.sent_data.length} templates found.</div>
                        </Card>
                    </Col>
                </Form.Row>

                {this.state.row
                        && <EditTemplate onRowEdit={this.onRowEdit} onDelete={this.onDelete} show={this.state.show} onHide={() => this.setState({show: false})} onExited={() => this.setState({row: null})} data={this.state.row}
                                     pTypes={this.state.ProtectionTypes}
                            />}
                <AlertModalComponent ref={this.am} />
            </Fragment>
        );
    }
}

export default memo(Template);