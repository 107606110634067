// Holds all related business logic related to Cases
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { DataType, Model } from '../../lib/Model';
import { getAllActivityTemplate, getCaseTypes } from '../../graphql/queries';
import UserProfile from '../../lib/UserProfile';
import { ActType } from '../../lib/helpers';
import {getRemindersByActivityType} from '../../graphql/queries';

export default class DoTemplate extends Model {

    constructor(form) {
        super(form);
        this.modelClass = {
            AccountId: DataType.Int.name,
            Type: DataType.Int.name,
            Category: DataType.Int.name,
            Description: DataType.String.name,
            ProtectionType: DataType.Int.name,
        };
    }

    static async getTemplateList() {
        return await API.graphql(graphqlOperation(getAllActivityTemplate, {AccountId: UserProfile.getUser().account_id}))
                .then(resp => resp.data.getAllActivityTemplate || [])
                .then(data => {
                    return {
                        sent_data: data.filter(x => x.Category === ActType.Sent),
                        received_data: data.filter(x => x.Category === ActType.Received)
                    };
                });
    }

    static async getReminders(Type) {
        return await  API.graphql(graphqlOperation(getRemindersByActivityType, {ActType: Type, AccountId: UserProfile.getUser().account_id}))
                .then(resp => resp.data.getRemindersByActivityType || []);
    }

    static async getProtectionTypes() {
        return await API.graphql(graphqlOperation(getCaseTypes))
                .then(resp => resp.data.getCaseTypes || [])
                .then(data => data.map(x => ({value: x.typeNo, label: x.name})));
    }

    static async deleteTemplate(Type) {
        if(!Type)
            throw "Template No is not set";

        return await API.graphql({ query: mutations.deleteTemplate, variables: {Type: Type, AccountId: UserProfile.getUser().account_id}});
    }

    async persist(reminders, origReminders) {
        this.model.AccountId = UserProfile.getUser().account_id;
        this.updateModel();

        if(this.model.Category !== ActType.Received && this.model.Category !== ActType.Sent)
            return new Error("Category is not set, can't persit template.", this.modal);
        let temp = await this._persistTemplate();
        if(!temp)
            return new Error("Persisting template failed.");

        // update reminders mutations
        let remsUpdated = [];
        console.log("runnning edit reminders");
        remsUpdated = await this._persistReminders(temp, reminders, origReminders);

        return await Promise.resolve({coresp: temp, reminders: remsUpdated});
    }

    async _persistTemplate() {
        return await API.graphql({ query: mutations.editTemplate, variables: {input: this.model}})
                            .then(resp => resp.data.editTemplate);
    }

    async _persistReminders(temp, reminders, origReminders) {
        let remsUpdated = [];
        await reminders.forEach(async rem => {
            delete rem.excluded;
            rem.AccountId = parseInt(UserProfile.getUser().account_id);

            if(!temp.Type)
                return new Error("Template is not set, can't persit reminders.", temp);

            rem.ActType = temp.Type;
            !rem.TicklerType && (rem.TicklerType = null);
            await API.graphql({ query: mutations.editTemplateReminders, variables: {input: rem}})
                    .then(resp => resp.data.editTemplateReminders || null)
                    .then(xrem => remsUpdated.push(xrem))
                    .catch(resp => {
                        console.error("Could not update template reminder. Details:", resp);
                    });
        });

        await origReminders.filter(x => !reminders.find(k=>k.TicklerType == x.TicklerType)).forEach(async rem => {
            await API.graphql({ query: mutations.deleteTemplateReminders, variables: {TicklerType: rem.TicklerType, AccountId: UserProfile.getUser().account_id}})
                    .then(resp => resp.data.deleteTemplateReminders)
                    .catch(resp => {
                        console.error("Could not delete template reminder. Details:", resp);
                    });
        });

        return remsUpdated;
    }

}
