import { faCopy, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form as KiForm } from 'formik';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import {Modal, Form, Badge, Card, Tabs, Tab, Button, Spinner, Alert, ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap";
import { AppContext } from '../../components/DWPage';
import { LoadingSpinner, RedAlert } from '../../components/helpers';
import { ActType, getSelectedValue } from '../../lib/helpers';
import { CaseFields as CFields, PartiesFields, RelatedCases } from './CaseForm';
import DoCase from './DoCase';
import UserProfile from '../../lib/UserProfile';

const EditCase = (props) => {
  const [key, setKey] = useState('case');
  const [CaseFields, setCaseFields] = useState(<LoadingSpinner/>);
  const appContext = useContext(AppContext);

  const {onHide, onDelete, onCopyCase} = {...props};
  const {types, statuses, countries} = appContext;
  const [ac, setAlert] = useState();
  const alertRef = useRef();

  let createNewCorresp = null;
  let data = props.data;

  if(!data)
    data = {matter: "", title: "", status: ""};

  const showMsg = msg => {
    setAlert(msg);
    alertRef.current.classList.add("in");
  }

  const onValidate = (values) => {
    let errors = {};
    if(!values.Case.Matter) {
      !errors.Case && (errors.Case = {});
      errors.Case.Matter = "Matter is required";
    }
    if(!values.Case.Status) {
      !errors.Case && (errors.Case = {});
      errors.Case.Status = "Status is required";
    }

    if(Object.keys(errors).length)
      console.warn(errors);

    return errors;
  }

  const onSubmit = (values, helpers, filingdata) => {
    try {
      Object.keys(values.Case).map( x => filingdata.Case[x] !== null && (filingdata.Case[x] = values.Case[x]));

      let pt = getSelectedValue(values.Case.ParentCase);
      let bc = getSelectedValue(values.Case.ClientNo);
      let gr = getSelectedValue(values.group);
      let st = getSelectedValue(values.Case.Status);
      let ct = getSelectedValue(values.Case.Country);

      filingdata.Case.ParentCase = pt && pt.toString();
      filingdata.Case.ClientNo = bc;
      filingdata.Case.GroupId = gr;
      filingdata.Case.Status = st;
      filingdata.Case.Country = ct;
    } catch(ex) {
      console.error("Form Submition Error:", ex);
      showMsg("Editing the case failed.");
      helpers.setSubmitting(false);
      return;
    }

    let doca = new DoCase(filingdata.Case);
    let createChilds = [];
    doca.persist(values, filingdata, createChilds)
        .then(resp => {
          helpers.setSubmitting(false);
          if(resp.hasErrors) {
            showMsg("Oops! Something went wrong. Editing the case didn't complete successfully. Please contact support for more info.");
            return false;
          }
          onHide();
          resp.Case.newStatus = resp.newStatus;
          props.onRowEdit(resp.Case, createNewCorresp);
        })
        .catch(x => {
            console.error("Submit Mutation Error:", x);
            showMsg("Editing the case failed.");
            helpers.setSubmitting(false);
        });
  }

  const saveNCreate = actType => {
    createNewCorresp = actType;
  }

  useEffect(() => {
    setCaseFields(<Modal.Body><LoadingSpinner/></Modal.Body>);
    if(!props.show) return;

    DoCase.getCase(data.caseno)
          .then((filingdata) => {
            let type = types.find(x => x.typeNo === parseInt(filingdata.Case.TypeNo));
            let tag = type ? type.tag : "patent";

            filingdata.Case.Status = statuses.find(x => x.value === filingdata.Case.Status) || null;
            filingdata.Case.Country = countries.find(x => x.value === filingdata.Case.Country) || null;
            if(data.isNew) {
              filingdata.Case.Active = true;
              filingdata.Case.TypeNo = "1";
              filingdata.Case.UtilityDesign = "0";
            }

            setCaseFields(
                <Formik
                    enableReinitialize
                    isSubmitting
                    initialValues={{...filingdata}}
                    validate={onValidate}
                    onSubmit={(values, helpers) => onSubmit(values, helpers, filingdata)}
                >
                    {({isSubmitting, ...props}) => (
                        <Fragment>
                          <KiForm noValidate className="modal-content" style={{maxHeight: "calc(100vh - 61px)"}}>
                          <Modal.Body className="has-footer pt-1 pb-0">
                            <Tabs defaultActiveKey={key} id="case-dets">
                              <Tab eventKey="case" title="Filing Data">
                                <Card body className={`bg-transit mb-3`}>
                                  <CFields types={types} statuses={statuses} countries={countries}
                                            typeTag={tag} {...props} />
                                </Card>
                              </Tab>
                              <Tab eventKey="parties" title="Parties">
                                {<PartiesFields types={types} statuses={statuses} countries={countries}
                                          typeTag={tag} {...props} />}
                              </Tab>
                              <Tab eventKey="rel_cases" title="Related Cases/Notes">
                                <RelatedCases {...props} />
                              </Tab>
                              {/* <Tab eventKey="custom" title="Custom"></Tab> */}
                            </Tabs>
                          </Modal.Body>
                          <Modal.Footer className="justify-content-start">
                            <Form.Group>
                              {UserProfile.canEdit() && data.isNew &&
                                <DropdownButton disabled={isSubmitting} className="mr-2" size="sm" variant="primary" drop="up" as={ButtonGroup}
                                      title={isSubmitting ? <>Saving <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" /></> : "Save & ..."}>
                                  <Dropdown.Item as={Button} type="submit" size="sm" onClick={() => saveNCreate(ActType.Received)}>Save & Create a Received Correspondence</Dropdown.Item>
                                  <Dropdown.Item as={Button} type="submit" size="sm" onClick={() => saveNCreate(ActType.Sent)} >Save & Create a Sent Correspondence</Dropdown.Item>
                                  <Dropdown.Item as={Button} type="submit" size="sm" >Save & Close</Dropdown.Item>
                                </DropdownButton>}
                              {UserProfile.canEdit() && !data.isNew && <Button type="submit" disabled={isSubmitting} className="mr-2" size="sm">{isSubmitting ? <>Saving <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" /></> : "Save"}</Button> }
                              <Button type="reset" disabled={isSubmitting} onClick={onHide} className="btn-falcon-default" size="sm" >{UserProfile.canEdit() ? "Cancel" : "Close"}</Button>
                              {UserProfile.canEdit() && !data.isNew && <span className="mx-3 text-muted">|</span>}
                              {UserProfile.canEdit() && !data.isNew && onCopyCase && <Button type="button" disabled={isSubmitting} onClick={onCopyCase} className="btn-falcon-default mr-2" size="sm" ><FontAwesomeIcon icon={faCopy} /> Copy Case</Button>}
                              {UserProfile.canEdit() && !data.isNew && <Button type="button" disabled={isSubmitting} onClick={onDelete} className="btn-falcon-danger" size="sm" ><FontAwesomeIcon icon={faTrashAlt} /> Delete</Button>}
                            </Form.Group>
                          </Modal.Footer>
                        </KiForm>
                        </Fragment>
                    )}
                </Formik>
              );
          })
          .catch(err => {
            console.error("Case Query Error:", err);
            setCaseFields(<Modal.Body><RedAlert>Could not fetch case data. Please <a href="#" onClick={() => window.location.reload()}>reload the page</a> to try again.</RedAlert></Modal.Body>);
          });
  }, [data, props.show]);

  return (
      <Modal show={props.show} onHide={props.onHide} className="page-modal scrollbar"
        dialogClassName="modal-80w" onShow={() => setKey("case")}
        >
        <Modal.Header closeButton>
          <div className="modal-title">
            {data.isNew && <h5>Adding new Case</h5>}
            {!data.isNew && <Fragment><span className="cdNum">Case: {data.matter}</span> - <span className="cdName">{data.title}</span>
                            <Badge title="Status" className="ml-2" variant="secondary" pill>{data.status}</Badge></Fragment>}
          </div>
        </Modal.Header>
          <Alert className="floating-alert shadow-lg alert-warning" ref={alertRef}>{ac} <button onClick={() => {setAlert(""); alertRef.current.classList.remove("in")}} type="button" className="close ml-3"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button></Alert>
          {CaseFields}
      </Modal>
  );
}

export default memo(EditCase);