
export const DataType = {
            Int: {name: "Int", parser: x => parseInt(x) !== NaN ? parseInt(x) : null},
            String: {name: "String", parser: x => x && x.replace(/'/g, "''")},
            AWSDate: {name: "AWSDate", parser: x => x || null},
            Boolean: {name: "Boolean", parser: x => ["1", "true", true].indexOf(x) !== -1 ? true : false},
            Float: {name: "Float", parser: x => x ? parseFloat(x) : null},
            Array: {name: "Array", parser: x => x ? x.filter(k => k = k.coerceFields()) : null},
            Object: {name: "Object", parser: x => x ? x.coerceFields() : null}
        };

export class Model {

    _lcMapping = null;

    constructor(model) {
        this.model = model;
        this.modelClass = null;
    }

    coerceFields() {
        if(!this.model)
            throw "Model is not yet initialized";

        Object.keys(this.modelClass).map( x => {
            let parser = DataType[this.modelClass[x]].parser;
            parser && (this.model[x] = parser.call(this, this.model[x]));
        });

        return this.model
    }

    updateModel() {
        if(!this.model)
            return Promise.reject("Model is not yet initialized");

        this.coerceFields();
    }

    setModel(values) {
        this.model = values;
    }

    getModel() {
        return this.model;
    }

    getLowerCaseMapping() {
        if(!this._lcMapping) {
            this._lcMapping = {};
            Object.keys(this.modelClass).map( x => {
                this._lcMapping[x.toLowerCase()] = this.modelClass[x];
            });
        }

        return this._lcMapping;
    }

}