// Holds all related business logic related to Cases
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { DataType, Model } from '../../lib/Model';
import { getCase, getCaseAppNos, getCasesByParty, getCaseGroups, getCaseDetails, listCases, getCaseDeps, filterCases, getIntClassByCase, isMatterUnique } from '../../graphql/queries';
import UserProfile from '../../lib/UserProfile';
import { parseNulls, PartyTypes } from '../../lib/helpers';

export default class DoCase extends Model {

    constructor(form) {
        super(form);
        this.modelClass = {
            CaseNo: DataType.Int.name,
            AccountId: DataType.Int.name,
            UserId: DataType.Int.name,
            Title: DataType.String.name,
            ClientNo: DataType.Int.name,
            Notes: DataType.String.name,
            Matter: DataType.String.name,
            Examiner: DataType.String.name,
            ArtUnit: DataType.String.name,
            FilingDate: DataType.AWSDate.name,
            ParentCase: DataType.String.name,
            Active: DataType.Boolean.name,
            ApplicationNo: DataType.String.name,
            PatentNo: DataType.String.name,
            IssueDate: DataType.AWSDate.name,
            Status: DataType.Int.name,
            StatusName: DataType.String.name,
            ExpirationDate: DataType.AWSDate.name,
            ClientMatter: DataType.String.name,
            PubDate: DataType.AWSDate.name,
            PubNo: DataType.String.name,
            GoodsServices: DataType.String.name,
            PriorityDate: DataType.AWSDate.name,
            UtilityDesign: DataType.Int.name,
            ProvisionalSN: DataType.String.name,
            Country: DataType.Int.name,
            CountryName: DataType.String.name,
            IntlClass: DataType.String.name,
            Reel: DataType.Int.name,
            Frame: DataType.Int.name,
            RecordationDate: DataType.AWSDate.name,
            GroupNo: DataType.Int.name,
            TypeNo: DataType.Int.name,
            ConfirmationNo: DataType.String.name,
            Class: DataType.String.name,
            CustomerNumber: DataType.String.name,
            InternationalRegistration: DataType.String.name,
            EntityStatus: DataType.String.name,
            InternationalPublicationDate: DataType.AWSDate.name,
            AIA: DataType.String.name,
            PTOStatus: DataType.String.name,
            PTOStatusDate: DataType.AWSDate.name,
            LastUpdate: DataType.AWSDate.name,
            parentFilingDate: DataType.AWSDate.name,
            inventor: DataType.String.name,
            inventorPhone: DataType.String.name,
            inventorAddress: DataType.String.name,
            assignee: DataType.String.name,
            GroupId: DataType.Int.name,
            foreignAgent: DataType.String.name,
            subtechnology: DataType.String.name,
            disclosureRequired: DataType.Boolean.name,
            ReportedNotes: DataType.String.name
        };
    }

    static async getCaseList(filter = null) {
        let caseFilter = {};
        if(filter !== null && filter != undefined) {
            caseFilter = {...filter};
            caseFilter.FilingDateBefore = filter.FilingDate.Before;
            caseFilter.FilingDateAfter = filter.FilingDate.After;
            caseFilter.IssueDateBefore = filter.IssueDate.Before;
            caseFilter.IssueDateAfter = filter.IssueDate.After;
            caseFilter.Status = filter.Status ? filter.Status.value : "";
            caseFilter.Attorney = filter.Attorney ? filter.Attorney.value : "";
            caseFilter.Client = filter.Client ? filter.Client.value : "";
            caseFilter.Country = filter.Country ? filter.Country.value : "";

            delete caseFilter.FilingDate;
            delete caseFilter.IssueDate;
            delete caseFilter.prior;
            delete caseFilter.upcoming;
            delete caseFilter.FromDate;
            delete caseFilter.ToDate;
            delete caseFilter.isCustom;

            caseFilter.Status = DataType.Int.parser(caseFilter.Status);
            caseFilter.Country = DataType.Int.parser(caseFilter.Country);
            caseFilter.Attorney = DataType.Int.parser(caseFilter.Attorney);
            caseFilter.Client = DataType.Int.parser(caseFilter.Client);
            caseFilter.FilingDateBefore = DataType.AWSDate.parser(caseFilter.FilingDateBefore);
            caseFilter.FilingDateAfter = DataType.AWSDate.parser(caseFilter.FilingDateAfter);
            caseFilter.IssueDateBefore = DataType.AWSDate.parser(caseFilter.IssueDateBefore);
            caseFilter.IssueDateAfter = DataType.AWSDate.parser(caseFilter.IssueDateAfter);
            caseFilter.TypeNo = DataType.Int.parser(caseFilter.TypeNo);
        } else {
            caseFilter = null;
        }
        return await API.graphql(graphqlOperation(listCases, {AccountId: UserProfile.getUser().account_id, filter: caseFilter }))
                        .then(resp => resp.data.listCases || [])
    }

    static async getCaseDetails(CaseNo) {
        return API.graphql(graphqlOperation(getCaseDetails, {CaseNo: CaseNo, AccountId: UserProfile.getUser().account_id}))
                        .then(resp => {
                            let data = {corresps: [], reminders: [], adhoc_reminders: []};
                            data.corresps = resp.data.getCorrespByCase || [];
                            data.reminders = resp.data.getRemindersByCase.ActReminders || [];
                            data.adhoc_reminders = resp.data.getRemindersByCase.AdHocReminders || [];

                            return data;
                          });
    }

    static async getCase(CaseNo) {
        const prepareCase = data => {
            let getCase = data.getCase || {Case: {}, Parties: []},
                        relCases = data.getRelatedCases || [];
            let group = data.getGroupByCase ? {value: data.getGroupByCase.Id, label: data.getGroupByCase.Name} : null;
            let priorities = data.getPriotitiesByCase || [];
            let $case = getCase.Case;
            let parties = getCase.Parties;
            let members = data.getGroupMembers || [];
            let intclasses = data.getIntClassByCase || [];

            Object.keys($case).map(x => $case[x] = $case[x] === null ? "" : $case[x]);

            $case.TypeNo = $case.TypeNo && $case.TypeNo.toString();

            if(Object.keys($case).length === 0) {
                $case = {AIA: "", AccountId: "", Active: "", ApplicationNo: "", ArtUnit: "", CaseNo: "", Class: "",
                ClientCode: "", ClientMatter: "", ClientName: "", ClientNo: "", ConfirmationNo: "", Country: "",
                CustomerNumber: "", EntityStatus: "", Examiner: "", ExpirationDate: "", FilingDate: "", Frame: "",
                GoodsServices: "", GroupId: "", GroupNo: "", InternationalPublicationDate: "", InternationalRegistration: "",
                IntlClass: "", IssueDate: "", LastUpdate: "", Matter: "", Notes: "", PTOStatus: "", PTOStatusDate: "",
                ParentCase: "", PatentNo: "", PriorityDate: "", ProvisionalSN: "", PubDate: "", PubNo: "", RecordationDate: "",
                Reel: "", Status: "", Title: "", TypeNo: "", UserId: "", UtilityDesign: "", assignee: "", disclosureRequired: "",
                foreignAgent: "", inventor: "", inventorAddress: "", inventorPhone: "", parentFilingDate: "", subtechnology: ""};
            }

            let clients = [], inventors = [], attorneys = [], agents = [];

            parties.map(x => {
                switch(x.PartyType) {
                    case 0: clients.push({value: x.Id, label: x.Organization + " (" + x.ShortName + ")"}); break;
                    case 1: attorneys.push({value: x.Id, label: x.FirstName + " " + x.LastName, position: x.Position}); break;
                    case 2: agents.push({value: x.Id, label: x.FirstName + " " + x.LastName}); break;
                    case 3: inventors.push({value: x.Id, label: x.FirstName + " " + x.LastName}); break;
                    default:;
                };
                return;
            });

            attorneys.sort((a, b) => a.position - b.position);

            $case.ClientNo && !clients.find(x => x.value === $case.ClientNo) && clients.push({value: $case.ClientNo, label: $case.ClientName});

            let newParty = {FirstName: "", LastName: "", Address1: "", Phone: ""};

            let ChildCases = relCases.map(x => ({value: x.RelAppNo, label: x.Title || x.RelAppNo}));
            $case.ParentCase = $case.ParentCase && {value: $case.ParentCase, label: $case.ParentCase};

            if($case.IntlClass) {
                intclasses.unshift({Id: 0, Name: $case.IntlClass, Description: ""});
            }

            return {Case: $case, ClientList: clients, InventorList: inventors, ChildCases: ChildCases, NewInventor: newParty, NewAgent: newParty,
                    AttorneyList: attorneys, group: group, Priorities: priorities, AgentList: agents,
                    GroupMembers: members, NewAttorney: newParty, IntClasses: intclasses};
        }
        if(!CaseNo) {
            return prepareCase({});
        }

        return await API.graphql(graphqlOperation(getCase, {CaseNo: CaseNo, AccountId: UserProfile.getUser().account_id}))
                .then(resp => resp.data)
                .then(data => {
                    return prepareCase(data);
                });
    }

    static async getCaseAppNos(inputValue) {
        return await API.graphql(graphqlOperation(getCaseAppNos, {AccountId: UserProfile.getUser().account_id, inputValue: inputValue}))
                .then(resp => resp.data.getCaseAppNos || [])
                .then(parties => parties.map(x => ({value: x.CaseNo, label: x.ApplicationNo + " - " + (x.Title || "")}) ));
    }

    static async getCaseIntClass(CaseNo) {
        return await API.graphql(graphqlOperation(getIntClassByCase, {AccountId: UserProfile.getUser().account_id, CaseNo: CaseNo}))
                .then(resp => resp.data.getIntClassByCase || []);
    }

    static async getCaseAppNosByName(inputValue) {
        return await API.graphql(graphqlOperation(getCaseAppNos, {AccountId: UserProfile.getUser().account_id, inputValue: inputValue}))
                .then(resp => resp.data.getCaseAppNos || [])
                .then(parties => parties.map(x => ({value: x.ApplicationNo, label: x.ApplicationNo + " - " + (x.Title || "")}) ));
    }

    static async getGroups(inputValue) {
        return await API.graphql(graphqlOperation(getCaseGroups, {AccountId: UserProfile.getUser().account_id, inputValue: inputValue}))
                .then(resp => resp.data.getCaseGroups || [])
                .then(parties => parties.map(x => ({value: x.Id, label: x.Name}) ));
    }

    static async getCasesByParty(PartyId) {
        return await API.graphql(graphqlOperation(getCasesByParty, {PartyId: PartyId, AccountId: UserProfile.getUser().account_id}))
                .then(resp => resp.data.getCasesByParty || []);
    }

    static async deleteCase(CaseNo) {
        if(!CaseNo)
            return new Error("Case No is not set");

        return await API.graphql({ query: mutations.deleteCase, variables: {CaseNo: CaseNo, AccountId: UserProfile.getUser().account_id}});
    }

    static async isMatterUnique(Matter) {
        if(!Matter)
            return new Error("Matter is missing.");

        return await API.graphql(graphqlOperation(isMatterUnique, {Matter: Matter, AccountId: UserProfile.getUser().account_id}))
            .then(resp => resp.data.isMatterUnique);
    }

    static async copyCase(newMatter, CaseNo) {
        if(!newMatter)
            return new Error("Copy failed! Missing new Matter No.");

        return await API.graphql({ query: mutations.copyCase, variables:{NewMatter: newMatter, CaseNo: CaseNo, AccountId: UserProfile.getUser().account_id}})
                        .then(resp => resp.data.copyCase);
    }

    async persist(values, filingData) {
        this.model.AccountId = UserProfile.getUser().account_id;
        this.updateModel();

        delete this.model.ClientName;
        delete this.model.ClientCode;

        let hasError = false;
        let newStatus = null;
        let origParties = [...this._explode(filingData.ClientList), ...this._explode(filingData.InventorList),
                             ...this._explode(filingData.AgentList), ...this._explode(filingData.AttorneyList)];

        // create new data
        if(values.group && values.group.__isNew__) {
            this.model.GroupId = await this._persistGroup(values.group).catch(resp => {
                hasError = true;
                console.error("Mutation error. Details:", resp);
            });
        }
        if(values.Case.Status && values.Case.Status.__isNew__) {
            let _ = this;
            this.model.Status = await this._persistStatus(values.Case.Status)
                                    .then(status => {
                                        newStatus = {value: status, label: values.Case.Status.label};
                                        return status;
                                    })
                                    .catch(resp => {
                                        hasError = true;
                                        console.error("Mutation error. Details:", resp);
                                    });
        }

        // update the case
        let tempCase = null;
        tempCase = await this._persistCase().catch(resp => {
            hasError = true;
            console.error("Mutation error. Details:", resp);
        });

        if(!tempCase) {
            console.error("Persist case return:", tempCase);
            return await Promise.resolve({Case: tempCase, hasErrors: true });
        }

        // update Parties
        const {PartiesErrors, Inventors} = await this._persistParties(tempCase.CaseNo, origParties, values).catch(resp => {
            hasError = true;
            console.error("Mutation error. Details:", resp);
        });
        let err = PartiesErrors;
        tempCase.Inventors = Inventors;

        if(err) hasError = true;

        // update Child Cases
        err = await this._persistChildCases(tempCase.CaseNo, filingData.ChildCases, values.ChildCases).catch(resp => {
            hasError = true;
            console.error("Mutation error. Details:", resp);
        });

        if(err) hasError = true;

        // update priorities
        const {ErrorResponse, PriorityCases} = await this._persistPriorityCases(tempCase.CaseNo, filingData.Priorities, values.Priorities).catch(resp => {
            hasError = true;
            console.error("Mutation error. Details:", resp);
        });
        err = ErrorResponse;
        tempCase.PriorityCases = PriorityCases;

        // update Int'l Classes
        err = await this._persistIntClasses(tempCase.CaseNo, filingData.IntClasses, values.IntClasses).catch(resp => {
            hasError = true;
            console.error("Mutation error. Details:", resp);
        });

        if(err) hasError = true;

        // update Custom
        // TBD

        return await Promise.resolve({Case: parseNulls(tempCase, new DoCase()), hasErrors: hasError, newStatus: newStatus });
    }

    _explode = list => list ? list.map(x => x.value) : [];

    async _persistCase() {
        return await API.graphql({ query: mutations.EditCase, variables: {input: this.model}})
                        .then(resp => resp.data.editCase);
    }

    async _persistParties(CaseNo, orgisParties, {ClientList, InventorList, AttorneyList, AgentList, NewInventor, NewAgent, NewAttorney}) {
        let hasErrors = false;
        let mergedList = [...this._explode(ClientList), ...this._explode(InventorList), ...this._explode(AgentList)];
        let attList = this._explode(AttorneyList);

        let act = UserProfile.getUser().account_id;
        let invs = [];

        // Check for new parties
        if(NewInventor.FirstName !== "" || NewInventor.LastName !== "") {
            let newInv = await this._persistNewParty(NewInventor, PartyTypes.Inventor).catch(resp => {
                hasErrors = true;
                console.error("Mutation error. Details:", resp);
            });
            mergedList.push(newInv.Id);
            invs.push(newInv.FirstName + " " + newInv.LastName);
        }

        if(NewAgent.FirstName !== "" || NewAgent.LastName !== "") {
            let newagt = await this._persistNewParty(NewAgent, PartyTypes.Agent).catch(resp => {
                hasErrors = true;
                console.error("Mutation error. Details:", resp);
            });
            mergedList.push(newagt.Id);
        }

        if(NewAttorney.FirstName !== "" || NewAttorney.LastName !== "") {
            let newagt = await this._persistNewParty(NewAttorney, PartyTypes.Attorney).catch(resp => {
                hasErrors = true;
                console.error("Mutation error. Details:", resp);
            });
            attList.push(newagt.Id);
        }

        InventorList && InventorList.forEach(x => invs.push(x.label));

        // update case Parties
        mergedList.length > 0 && await API.graphql({ query: mutations.editCaseParties, variables: {CaseNo: CaseNo, AccountId: act, Parties: mergedList.join(",")}})
                    .then(resp => resp.data.editCaseParties || null)
                    .then(x => !x && (hasErrors = true))
                    .catch(resp => {
                        hasErrors = true;
                        console.error("Mutation error. Details:", resp);
                    });

        attList.length > 0 && await API.graphql({ query: mutations.addCaseAttorneys, variables: {CaseNo: CaseNo, AccountId: act, Attorneys: attList}})
                    .then(resp => resp.data.addCaseAttorneys || null)
                    .then(x => !x && (hasErrors = true))
                    .catch(resp => {
                        hasErrors = true;
                        console.error("Mutation error. Details:", resp);
                    });

        // remove excluded parties
        mergedList = [...mergedList, ...attList];
        let deleteList = orgisParties.filter(x => !mergedList.find(k=> k === x));
        if(deleteList.length > 0) {
            await API.graphql({ query: mutations.deleteCaseParties, variables: {CaseNo: CaseNo, AccountId: act, Parties: deleteList.join(",")}})
                    .then(resp => resp.data.deleteCaseParties)
                    .then(x => !x && (hasErrors = true))
                    .catch(resp => {
                        hasErrors = true;
                        console.error("Mutation error. Details:", resp);
                    });
        }

        return {PartiesErrors: hasErrors, Inventors: invs.join(";")};
    }

    async _persistChildCases(CaseNo, origChilds, ChildCases) {
        let hasErrors = false;
        let act = UserProfile.getUser().account_id;

        // update case Parties
        // let children = [];
        await ChildCases.forEach( async cs => {
            cs && cs.value && await API.graphql({ query: mutations.editChildCases, variables: {input: {CaseNo: CaseNo, AccountId: act, RelAppNo: cs.value}}})
                        .then(resp => resp.data.editCaseParties || null)
                        // .then(x => x && children.push(x))
                        .catch(resp => {
                            hasErrors = true;
                            console.error("Mutation error. Details:", resp);
                        });
        });

        // remove excluded parties
        let deleteList = this._explode(origChilds.filter(x => !ChildCases.find(k=> k.value === x.value)));
        if(deleteList.length > 0) {
            await API.graphql({ query: mutations.deleteChildCases, variables: {CaseNo: CaseNo, AccountId: act,
                                 RelAppNo: deleteList.map(x=>x.replace(/\,/g, "<>")).join(",")}})
                    .then(resp => resp.data.deleteChildCases)
                    .then(x => !x && (hasErrors = true))
                    .catch(resp => {
                        hasErrors = true;
                        console.error("Mutation error. Details:", resp);
                    });
        }

        return hasErrors;
    }

    async _persistPriorityCases(CaseNo, origPs, PriorityCases) {
        let hasErrors = false;
        let act = UserProfile.getUser().account_id;

        // update case Parties
        let prs = [];
        await PriorityCases.forEach( async pc => {
            let pr = {CaseNo: CaseNo, AccountId: act, NatCode: pc.NatCode, AppNo: pc.AppNo, FilingDate: pc.FilingDate};
            prs.push(pr);
            await API.graphql({ query: mutations.editPriorityCases, variables: {input: pr}})
                        .then(resp => resp.data.editPriorityCases || null)
                        .catch(resp => {
                            hasErrors = true;
                            console.error("Mutation error. Details:", resp);
                        });
        });

        // remove excluded parties
        let deleteList = origPs.filter(x => !PriorityCases.find(k => k.AppNo === x.AppNo)).map(x => x.AppNo);
        await deleteList.forEach(async xAppno => {
            await API.graphql({ query: mutations.deletePriorityCases, variables: {CaseNo: CaseNo, AccountId: act, AppNo: xAppno}})
                    .then(resp => resp.data.deletePriorityCases)
                    .then(x => !x && (hasErrors = true))
                    .catch(resp => {
                        hasErrors = true;
                        console.error("Mutation error. Details:", resp);
                    });
        });

        prs = prs.sort((a,b) => new Date(a.FilingDate) - new Date(b.FilingDate));

        return {ErrorResponse: hasErrors, PriorityCases: prs.map(x => x.NatCode + " " + x.AppNo + " " + x.FilingDate).join(";")};
    }

    async _persistGroup(group) {
        return await API.graphql({ query: mutations.addGroup, variables: {Group: group.label, AccountId: UserProfile.getUser().account_id}})
                    .then(resp => resp.data.addGroup)
    }

    async _persistStatus(status) {
        return await API.graphql({ query: mutations.addStatus, variables: {Name: status.label, AccountId: UserProfile.getUser().account_id}})
                    .then(resp => resp.data.addStatus);
    }

    async _persistNewParty(Party, PartyType) {
        Party.AccountId = UserProfile.getUser().account_id;
        Party.PartyType = PartyType;
        return await API.graphql({ query: mutations.editParty, variables: {input: Party}})
                    .then(resp => resp.data.editParty);
    }

    async _persistIntClasses(CaseNo, OrigIntClasses, IntClasses) {
        let hasErrors = false;
        let act = UserProfile.getUser().account_id;

        IntClasses = IntClasses.filter(x => x.Id !== 0);
        OrigIntClasses = OrigIntClasses.filter(x => x.Id !== 0);

        // update case intclass
        // let prs = [];
        await IntClasses.forEach( async ic => {
            let cic = {CaseNo: CaseNo, AccountId: act, Id: ic.Id || null, Name: ic.Name, Description: ic.Description};
            await API.graphql({ query: mutations.editIntClasses, variables: {input: cic}})
                        .then(resp => resp.data.editIntClasses || null)
                        .catch(resp => {
                            hasErrors = true;
                            console.error("Mutation error. Details:", resp);
                        });
        });

        // remove excluded IntClasses
        let deleteList = OrigIntClasses.filter(x => !IntClasses.find(k => k.Id === x.Id)).map(x => x.Id);
        if(deleteList.length > 0) {
            await API.graphql({ query: mutations.deleteIntClasses, variables: {CaseNo: CaseNo, AccountId: act, Id: deleteList.join(",")}})
                    .then(resp => resp.data.deleteIntClasses)
                    .then(x => !x && (hasErrors = true))
                    .catch(resp => {
                        hasErrors = true;
                        console.error("Mutation error. Details:", resp);
                    });
        }

        return hasErrors;
    }

}
