import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import Cases from './pages/Cases/Cases';
import NewCase from './pages/Cases/NewCase';
import Parties from './pages/Parties/Clients';
import Inventors from './pages/Parties/Inventors';
import Agents from './pages/Parties/Agents';
import Attorneys from './pages/Parties/Attorneys';
import Template from './pages/Template/Template';
import Reminders from './pages/Reports/Reminders';
import NotFound from './pages/NotFound';
import NotImplemented from './pages/NotImplemented';
import { Fragment } from 'react';

const routes = [
    {path: "/", component: <Redirect to="/reports/reminders" />},
    {path: "/login", component: (isLogged) => isLogged && <Redirect to="/reports/reminders" />},
    {path: "/cases/list", component: <Cases />},
    {path: "/cases/new", component: <NewCase />},
    {path: "/parties/clients", component: <Parties />},
    {path: "/parties/inventors", component: <Inventors />},
    {path: "/parties/agents", component: <Agents />},
    {path: "/parties/attorneys", component: <Attorneys />},
    {path: "/templates/correspondence", component: <Template />},
    {path: "/reports/reminders", component: <Reminders />},
    {path: "/reports/cases", component: <NotImplemented />},
    {path: "/reports/clients", component: <NotImplemented />},
    {path: "/reports/correspondences", component: <NotImplemented />},
    {path: "/reports/attorneys", component: <NotImplemented />},
    {path: "/templates/custom-fields", component: <NotImplemented />},
    {path: "/admin/special-processing", component: <NotImplemented />},
    {path: "/admin/security", component: <NotImplemented />},
    {path: "/profile", component: <NotImplemented />},
    {path: "/alerts", component: <NotImplemented />},
    {path: "/calendar", component: <NotImplemented />},
    {path: "/not-found", component: <NotFound />},
];

export const Routes = props => {

    const location = useLocation();
    const  rt = location.pathname === "/" ? true : routes.find(x => x.path === location.pathname.replace(/\/+$/, ""));
    const user = props.user;

    return (
        <Fragment>
            {!rt && <Redirect to="/not-found" />}
            {rt && props.user && routes.map((element, i) => (
                <Route exact key={i} path={element.path} render={ props => (
                    element.path === "/login" ? element.component(user) : element.component
                )}/>))}
        </Fragment>
    )
}
