import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Button, InputGroup, FormControl, Card, Modal, Spinner, Row, Col, Form } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { faTrashAlt, faBell, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDefaultSortField, handleTableSort, prepareRows, searchAll, setCol } from '../../lib/helpers';
import { AlertClass, AlertModal, LoadingSpinner } from '../../components/helpers';
import { SelectField, TextArea, TextField } from '../../components/fields';
import { Formik, Form as KiForm } from 'formik';
import DoReminder from './DoReminder';
import moment from 'moment';
import UserProfile from '../../lib/UserProfile';
import { DataType } from '../../lib/Model';


export const ReminderForm = ({data, isNew, curcase, ...props}) => {

  const ac = AlertClass();
  const {onHide, onDelete} = props;
  const [form, setForm] = useState(<Card body><LoadingSpinner/></Card>);

  const onKeyDown = evt => {
    if ((evt.charCode || evt.keyCode) === 13 && evt.target.tagName !== "TEXTAREA") {
        evt.preventDefault();
    }
  }

  const onSubmit = (values, helpers) => {
    helpers.setSubmitting(true);
    let dorem = new DoReminder({...values, CaseNo: curcase.caseno});

    if(isNew) {
      dorem.addReminder()
        .then(resp => resp.data.addReminder || {})
        .then(rem => {
            helpers.setSubmitting(false);
            rem && ac.showInfo("Reminder successfully added");
            !rem && ac.showErrorMsg("Could not add a new reminder.");
            props.onAddNewRow(rem);
            setTimeout(onHide, 200);
        })
        .catch(x => {
            console.error("Mutation Error:", x);
            ac.showErrorMsg("Adding new reminder failed.");
            helpers.setSubmitting(false);
        });
    } else {
      dorem.updateReminder()
        .then(resp => resp.data.editReminder || {})
        .then(rem => {
            helpers.setSubmitting(false);
            if(rem)
            {
              ac.showInfo("Reminder successfully updated");
              props.onRowEdit(rem);
              setTimeout(onHide, 200);
            } else {
              ac.showErrorMsg("Editing this reminder failed.");
            }
        })
        .catch(x => {
            console.error("Mutation Error:", x);
            ac.showErrorMsg("Editing the reminder failed.");
            helpers.setSubmitting(false);
        });
    }
  }

  useEffect(() => {
    let cfData = null;
    if(data) {
      cfData = {
        ActNo: data.actno,
        TicklerNo: data.ticklerno,
        DateCompleted: data.datecompleted || "",
        Description: data.description,
        DueDate: data.duedate || "",
        Notes: data.notes || "",
        ManualDate: data.manualdate || "",
        ActSetDate: false,
        Priority: data.priority
      };
    } else {
      cfData = {
          ActNo: "",
          TicklerNo: "",
          DateCompleted: "",
          Description: "",
          DueDate: "",
          Notes: "",
          ManualDate: "",
          ActSetDate: false,
          Priority: ""
      }
    }

    setForm(
        <Formik
            isSubmitting
            initialValues={{...cfData}}
            validate={(values) => {
                let errors = {};
                if(values.Description == "")
                  errors.Description = "Please enter a description";

                if(isNew && values.ManualDate == "")
                  errors.ManualDate = "Please enter the reminder date";

                return errors;
            }}
            onSubmit={onSubmit}
        >
            {({values, ...props}) => (
                <KiForm noValidate onKeyDown={onKeyDown}>
                    <Card className={isNew ? "w-75 my-0 mx-auto" : ""}>
                      <Card.Header className={`h5 py-2 text-gray ${isNew && "bg-secondary text-white"} ${data && !data.actno && "bg-secondary text-white"} card-header ${data && {1: "bg-received", 0: "bg-sent"}[data.category]}`}>
                        {data ? "Edit Reminder" : "Ad-hoc Reminder"}
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <TextArea required name="Description" label="Description" value={values.Description} rows={2}></TextArea>
                            <Row>
                              {!isNew && <TextField as={Col} name="DueDate" label="Generated Reminder Date" disabled type="date" value={values.DueDate} />}
                              <TextField as={Col} required={isNew} name="ManualDate" label={`${!isNew ? "Override " : ""}Reminder Date`} type="date" value={values.ManualDate}/>
                            </Row>
                            <Row>
                              <Col xs={8}><TextField name="DateCompleted" label="Date Completed" type="date" value={values.DateCompleted}/></Col>
                              <Col>
                                <SelectField name="Priority" label="Priority" value={values.Priority}>
                                    <option value=""> -- </option>
                                    <option value="2"> Medium </option>
                                    <option value="1"> High </option>
                                </SelectField>
                              </Col>
                            </Row>
                            <TextArea name="Notes" label="Notes" value={values.Notes} rows={10}></TextArea>
                          </Col>
                          {!isNew && <Col xs={4} >
                            <div className="border p-3 rounded form-group shadow-sm bg-light position-sticky">
                              {data.actno && <div className="">
                                <span className="font-weight-bold mt-3">Generated By:</span>
                                <p>{data.generatedby} — <span className="text-nowrap">{ moment(data.actdate).format("MMMM Do YYYY")}</span></p>
                              </div>
                              }
                              {!data.actno && <p className="font-weight-bold">Ad hoc reminder.</p>}
                            </div>
                          </Col>}
                        </Row>
                        {UserProfile.canEdit() ? <Fragment>
                            <Button variant="primary" type="submit" name="save" disabled={props.isSubmitting} size="sm" className="mr-2">
                            {props.isSubmitting ? <>Saving <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></> : "Save"}
                            </Button>
                            <Button variant="outline-secondary" type="button" name="cancel" size="sm btn-falcon-default" onClick={onHide}>Cancel</Button>
                            {!isNew && <span className="mx-3 text-muted">|</span>}
                            {!isNew && <Button type="reset" disabled={props.isSubmitting} onClick={onDelete} className="btn-falcon-danger" size="sm" ><FontAwesomeIcon icon={faTrashAlt} /> Delete</Button>}
                        </Fragment> : <Button variant="outline-secondary" type="button" name="cancel" size="sm btn-falcon-default" onClick={onHide}>Close</Button>}
                      </Card.Body>
                    </Card>
                </KiForm>
            )}
        </Formik>
    );
}, [data]);

  return (
      <Fragment>
          {ac.alertMsg}
          {form}
      </Fragment>
  );
};

const Reminders = (props) => {

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [row, setRow] = useState();
  const [isNew, setIsNew] = useState(false);
  const ini_data = useRef([]);
  const [showCompleted, setShowCompeleted] = useState(true);
  const [completedAtBottom, setCompletedAtBottom] = useState(true);
  const {isLoading, reminders, setReload, corresps, showActiveOnly} = props;
  const am = AlertModal();
  const ac = AlertClass();
  const dtName = "CReminders";

  const getCompletedBy = CompletingAct => {
    let cp = corresps && corresps.length && corresps.find(x => x.ActNo === CompletingAct);
    return cp ? cp.Description : "";
  };

  const curcase = props.data;
  const ic = {name: "", sortable: true, selector: "", allowOverflow:true, wrap: true};
  const columns = useMemo(() => {
    let cols = [
      setCol({...ic, name: "Reminder", col:"Description", selector: "description", grow: 3,}),
      setCol({...ic, name: "Date", col:"DueDate", selector: "duedate", width: "110px", format: row => row.manualdate || row.duedate}),
      setCol({...ic, name: "Generating Corresp.", col:"GeneratedBy", selector: "generatedby", grow: 2}),
      setCol({...ic, name: "Corresp. Date", col:"ActDate", selector: "actdate", width: "110px"}),
      setCol({...ic, name: "Notes", col:"Notes", selector: "notes", omit: !showActiveOnly, grow: 2,
              format: row => row.notes && `${row.notes.length < 55 ? row.notes : row.notes.slice(0, 55) + "..."}`}),
      setCol({...ic, name: "Tickler No", col:"TicklerNo", selector: "ticklerno", omit: true}),
      setCol({...ic, name: "Category", col:"Category", selector: "category", omit: true}),
      setCol({...ic, name: "actno", col:"ActNo", selector: "actno", omit: true}),
      setCol({...ic, name: "manualdate", col:"ManualDate", selector: "manualdate", omit: true}),
      setCol({...ic, name: "actsetdate", col:"ActSetDate", selector: "actsetdate", omit: true}),
      setCol({...ic, name: "priority", col:"Priority", selector: "priority", omit: true}),
    ];
    if(!showActiveOnly) {
      cols.push(setCol({...ic, name: "Completed By", col:"CompletingAct", selector: "completingact", grow: 2, format: row => getCompletedBy(row.completingact) }));
      cols.push(setCol({...ic, name: "Completed", col:"DateCompleted", selector: "datecompleted", width: "110px"}));
    }
    return cols;
  });
  const RowStyles = [
    {
      when: row => row.datecompleted,
      style: {
        color: "gray",
        fontStyle: "italic"
      }
    },
    {
      // 1 month before due date
      when: row => {
        return row.priority === 2 && !row.datecompleted;
      },
      style: {
        backgroundColor: "#ffeccf",
        color: "black"
      }
    },
    {
      when: row => {
        return row.priority === 1 && !row.datecompleted;
      } ,
      style: {
        backgroundColor: "#f6afaf",
        color: "black"
      }
    }
  ];

  const onRowClick = row => {
    setRow(row);
    setShow(true);
  }

  const onDelete = () => {
    am.showConfirm("WARNING!", `You are about to delete reminder for \"${row.duedate} - ${row.description}\". Are you sure you want to proceed?`,
            () => {
              setShow(false);
              DoReminder.deleteReminder(row.ticklerno)
                .then(resp => resp.data.deleteReminder || null)
                .then(deleted => {
                  ac.showInfo(`Reminder for \"${row.duedate} - ${row.description}\" was successfully deleted.`);
                  setReload(true);
                })
                .catch(resp => {
                  console.error("Mutation Error:", resp);
                  ac.showErrorMsg(`Deleting reminder for \"${row.duedate} - ${row.description}\" failed.`);
                });
            });
  }

  const onRowEdit = rem => {
    setReload(true);
  }

  const onAddNewRow = row => {
    setReload(true);
  }

  const onNew = evt => {
    setIsNew(true);
    setShow(true);
  }

  const onSearch = evt => {
    let term = evt.target.value;
    setSearch(evt.target.value);
    setSearch(term);
    if(term === "") {
      setData(ini_data.current);
      return;
    }
    var a = ini_data.current.filter(function(el) {
      return searchAll(el, term);
    });
    setData(a);
  };

  const sortReminders = (rows, field, dir) => {
    let rem = new DoReminder(rows[0]);
    let model = rem.getLowerCaseMapping();
    model["actdate"] = DataType.AWSDate.name;
    model["completingact"] = DataType.String.name;

    let sortFn = (a, b) => {
      let valA = a[field];
      let valB = b[field];

      switch (model[field]) {
        case DataType.String.name:
          if(field === "completingact") {
            valA = getCompletedBy(a[field]);
            valB = getCompletedBy(b[field]);
          }

          valA = valA ? valA.toLowerCase() : "";
          valB = valB ? valB.toLowerCase() : "";

          if(dir === "asc") {
            if(valA < valB) return -1;
            if(valA > valB) return 1;
          } else {
            if(valA < valB) return 1;
            if(valA > valB) return -1;
          }
          break;
        case DataType.AWSDate.name:
          let dateA = valA ? (new Date(valA)).getTime() : Number.MIN_SAFE_INTEGER;
          let dateB = valB ? (new Date(valB)).getTime() : Number.MIN_SAFE_INTEGER;
          return dir === "asc" ? dateA - dateB : dateB - dateA;
        default:
          return dir === "asc" ? valA - valB : valB - valA;
      }

      return 0;
    }

    if(completedAtBottom) {
      return [...rows.filter(x => !x.datecompleted).sort(sortFn), ...rows.filter(x => x.datecompleted).sort(sortFn)].slice(0).filter(x => {
        if(showActiveOnly)
          return !x.excluded && !x.DateCompleted;

        return showCompleted ? !x.excluded : !x.excluded && !x.DateCompleted;
      });
    }

    return rows.filter(x => {
        if(showActiveOnly)
          return !x.excluded && !x.DateCompleted;

        return showCompleted ? !x.excluded : !x.excluded && !x.DateCompleted;
      }).sort(sortFn).slice(0);
  }

  useEffect(() => {
    let field = UserProfile.getProp("rdt"+dtName);
    let rems = sortReminders(reminders, field && field.selector, field && field.dir)
    ini_data.current = prepareRows(columns, rems || []);
    setData(ini_data.current);
  }, [reminders, showCompleted, completedAtBottom]);

  return (
    <div className="">
      {ac.alertMsg}
      {!showActiveOnly && <div className="d-flex justify-content-between mb-3">
          <div>
            {UserProfile.canEdit() && <Button size="sm" className="mr-2 btn-falcon-default" onClick={onNew}><FontAwesomeIcon icon={faBell} /> Add a Reminder</Button>}
            <Form.Check type="checkbox" label={`Show Completed`} id="showCompleted" inline checked className="ml-2 small-label" onChange={() => setShowCompeleted(!showCompleted)} checked={showCompleted} />
            <Form.Check type="checkbox" label={`Show Completed At the bottom`} id="showCompAtBtm" inline checked className="ml-2 small-label" onChange={() => setCompletedAtBottom(!completedAtBottom)} checked={completedAtBottom} />
          </div>
          <div className="d-flex">
            <Button title="Refresh" onClick={() => setReload(true)} size="sm" className="mr-2 btn-falcon-default"><FontAwesomeIcon icon={faSync} /></Button>
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-sm">Search</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl key="search-remd" aria-label="Seach" value={search} aria-describedby="inputGroup-sizing-sm" onChange={onSearch} />
            </InputGroup>
          </div>
      </div>}
      <DataTable
          progressPending={isLoading}
          progressComponent={<LoadingSpinner />}
          columns={columns}
          data={data}
          defaultSortFieldId={getDefaultSortField(dtName).selector}
          defaultSortAsc={getDefaultSortField(dtName).dir}
          onSort={handleTableSort(dtName)}
          sortFunction={!showActiveOnly && sortReminders}
          noHeader
          highlightOnHover
          pointerOnHover
          conditionalRowStyles={RowStyles}
          onRowClicked={onRowClick}
          responsive
          dense
          fixedHeader
          keyField="ticklerno"
      />
      {data.length > 0 ? <p className="font-weight-bold mt-3 mb-0">Found {data.length} Reminder{data.length ? "s" : ""}.</p> : ""}
      {row &&
          <Modal show={show} onHide={() => setShow(false)} onExited={() => setRow(null)} className="page-modal scrollbar page-2"
              dialogClassName="modal-65w"
          >
          <Modal.Header closeButton>
            <div className="modal-title">
              <span className="cdNum">{curcase.matter} / {curcase.applicationno}</span> - <span className="cdName">Reminder</span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <ReminderForm onRowEdit={onRowEdit} onDelete={onDelete} curcase={curcase} data={row} onHide={() => setShow(false)} />
          </Modal.Body>
        </Modal>
      }
      {isNew && <Modal show={show} onHide={() => {setShow(false)} } onExited={() => setIsNew(false)} className="page-modal scrollbar page-2"
              dialogClassName="modal-65w"
          >
          <Modal.Header closeButton>
            <div className="modal-title">
              <span className="cdNum">{curcase.matter} / {curcase.applicationno}</span> - <span className="cdName">New Reminder</span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <ReminderForm onAddNewRow={onAddNewRow} curcase={curcase} data={null} isNew={isNew} onHide={() => setShow(false)} />
          </Modal.Body>
        </Modal>}
      {am.modal}
    </div>
  );
}

export default Reminders;