import React, { memo, useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { Button, InputGroup, FormControl, Modal} from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { faEnvelopeOpenText, faEnvelope, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleTableSort, prepareRows, searchAll, setCol, getDefaultSortField, ActType } from '../../lib/helpers';
import { AlertClass, AlertModal, LoadingSpinner } from '../../components/helpers';
import CorrespForm from './CorrespForm';
import NewCorrespForm from './NewCorrespForm';
import DoCorrespondence from './DoCorrespondence';
import DoCase from './DoCase';
import UserProfile from '../../lib/UserProfile';
import { createPortal } from 'react-dom';


const RowActions = ({row, ...props}) => {

  return (
      <div className="d-flex flex-row">
        <Button size="sm" variant="outline-primary" className="btn-light-border mr-1 color-received" title="Add Received Response" onClick={() => props.respondTo(ActType.Received, row.actno)}><FontAwesomeIcon icon={faEnvelopeOpenText} /></Button>
        <Button size="sm" variant="outline-primary" className="btn-light-border color-sent" title="Add Sent Response" onClick={() => props.respondTo(ActType.Sent, row.actno)}><FontAwesomeIcon icon={faEnvelope} /></Button>
      </div>
  );
}

export const OneActivity = props => {

  const {show, onRowEdit, actRow, onHide} = props;
  const [activity, setActivity] = useState({corresps: [], reminders: []});
  const [curAct, setCurAct] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const ac = AlertClass();

  useEffect(() => {
    setIsLoading(true);
    ac.hideAll();
    DoCase.getCaseDetails(actRow.caseno)
        .then(({corresps, reminders}) => {
          setActivity({corresps: corresps, reminders: reminders});
          let act = corresps.find(x => x.ActNo === actRow.actno)
          if(act) {
            Object.keys(act).map(k => {
              act[k.toLowerCase()] = act[k];
              delete act[k];
            });
          } else {
            setIsLoading(false);
            console.error("Could not fetch correspondence:", actRow.actno);
            ac.showErrorMsg("Oops! Sorry, we had an issue loading your correspondence. Please contact support for more info. Thank you!");
            return;
          }
          act.acttype = act.type;
          setCurAct(act);
          setIsLoading(false);
        })
        .catch(err => {
            setIsLoading(false);
            console.error("Query Error:", err);
            ac.showErrorMsg("Oops! Sorry, we had an issue loading your correspondence. Please contact support for more info. Thank you!");
        });
  }, [actRow])

  return (
    <Modal show={show} onHide={onHide} className="page-modal scrollbar page-2" dialogClassName="modal-70w" >
      <Modal.Header closeButton>
        <div className="modal-title">
          <span className="cdNum">Case: {actRow.matter}</span> - <span className="cdName">Correspondence</span>
        </div>
      </Modal.Header>
      <Fragment>
        {ac.alertMsg}
        {isLoading && <LoadingSpinner />}
        {!isLoading && <CorrespForm onDelete={props.onDelete} data={curAct} corresps={activity.corresps} reminders={activity.reminders} onRowEdit={onRowEdit} onHide={props.onHide} />}
      </Fragment>
    </Modal>
  )
}

const Activity = props => {

  const {data, curcase, reminders, corresps, onRowEdit} = props;

  return (
    <Modal show={props.show} onHide={props.onHide} className="page-modal scrollbar page-2"
          dialogClassName="modal-70w"
      >
      <Modal.Header closeButton>
        <div className="modal-title">
          <span className="cdNum">Case: {curcase.matter} / {curcase.applicationno || curcase.title}</span> - <span className="cdName">Correspondence</span>
        </div>
      </Modal.Header>
      <CorrespForm onDelete={props.onDelete} data={data} corresps={corresps} reminders={reminders} onRowEdit={onRowEdit} onHide={props.onHide} />
    </Modal>
  );
}

const NewActivity = props => {

  const {curcase, caseTypes, curCorresps, curReminders, categType, respTo} = props;
  const [activityTypes, setActivityTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(curcase.type);
  const [reminders, setReminders] = useState([]);
  const [isATLoading, setIsATLoading] = useState(true);
  const ac = AlertClass();
  const [iniActivityTypes, setIniActivityTypes] = useState([]);

  useEffect(() => {
    setIsATLoading(true);
    setActivityTypes([]);
    setReminders([]);
    ac.hideAll();

    curcase && DoCorrespondence.getActivityTypes(selectedType, categType)
        .then(corresps => {
          setActivityTypes(corresps);
          setIniActivityTypes(corresps);
          setIsATLoading(false);
        })
        .catch(err => {
            console.error("Query Error:", err);
            setIsATLoading(false);
            ac.showErrorMsg("Error retreiving correspondence types. Please try again.")
        });
  }, [selectedType, categType]);

  return (
    <Modal show={props.show} onHide={props.onHide} className="page-modal scrollbar page-2"
          dialogClassName="modal-80w"
      >
      <Modal.Header closeButton>
        <div className="modal-title">
          <span className="cdNum">{curcase.matter} / {curcase.applicationno}</span> - <span className="cdName">Correspondence</span>
        </div>
      </Modal.Header>
      <Fragment>
        {ac.alertMsg}
        <NewCorrespForm respTo={respTo} onRowAdded={props.onRowAdded} isATLoading={isATLoading} setSelectedType={setSelectedType} categType={categType} caseTypes={caseTypes} curcase={curcase} onHide={props.onHide}
                        activityTypes={activityTypes} IniActivityTypes={iniActivityTypes} setActivityTypes={setActivityTypes} curCorresps={curCorresps} curReminders={curReminders} />
      </Fragment>
    </Modal>
  );
}

const SentCellStyles = [
  {
    when: row => row.category === 0,
    style: {
      backgroundColor: "#A7D2FF",
      color: "black",
      padding: "3px 8px"
    }
  }
];

const ReceivedCellStyles = [
  {
    when: row => row.category === 1,
    style: {
      backgroundColor: "rgb(253 191 113)",
      color: "black",
      padding: "3px 8px"
    }
  }
];

const Correspondence = (props) => {

  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [new_show, setNewShow] = useState(false);
  const [categType, setCategType] = useState();
  const [row, setRow] = useState(null);
  const [respTo, setRespTo] = useState(null);
  const [newRow, setNewRow] = useState(null);
  const [data, setData] = useState([]);
  const ini_data = useRef([]);
  const {isLoading, reminders, caseTypes, setReload, setIsLoading} = props;
  var {corresps} = props;
  const am = AlertModal();
  const ac = AlertClass();
  const dtName = "Correspondence";

  const getRespondsTo = row => (ini_data.current && ini_data.current.filter(x => x.actno == row.respondsto)[0]) || {};
  const ic = {name: "", sortable: true, selector: "", allowOverflow:true, wrap: true};
  const columns = useMemo(() => {
    let actions = UserProfile.canEdit() ? [setCol({...ic, name: "Respond", selector: "actions", sortable: false, center: true, button: true, ignoreRowClick: true, width: "80px",
                                                  cell: row => <RowActions respondTo={respondTo} row={row} />})] : [];

    return [...actions,
          setCol({...ic, name: "Date", col:"ActDate", selector: "actdate", width: "130px"}),
          setCol({...ic, name: "Received", col:"Received", selector: "received", width: "200px", conditionalCellStyles: ReceivedCellStyles}),
          setCol({...ic, name: "Sent", col:"Sent", selector: "sent", width: "200px", conditionalCellStyles: SentCellStyles}),
          setCol({...ic, name: "Notes", col:"Notes", selector: "notes", grow: 2,
                        format: row => row.notes && `${row.notes.length < 33 ? row.notes : row.notes.slice(0, 33) + "..."}`}),
          setCol({...ic, name: "Responds To", col:"RespondsToDesc", selector: "respondstodesc", grow: 2,
                        format: row => row.respondsto !== null ? getRespondsTo(row).description : "" }),
          setCol({...ic, name: "RespondsTo Date", col:"RespondsToDate", selector: "respondstodate",
                        format: row => row.respondsto !== null ? getRespondsTo(row).actdate : "" }),
          setCol({...ic, name: "Category", col:"Category", selector: "category", omit: true}),
          setCol({...ic, name: "TypeId", col:"Type", selector: "acttype", omit: true}),
          setCol({...ic, name: "ActNo", col:"ActNo", selector: "actno", omit: true}),
          setCol({...ic, name: "RespondsTo", col:"RespondsTo", selector: "respondsto", omit: true}),
          setCol({...ic, name: "CaseNo", col:"CaseNo", selector: "caseno", omit: true}),
          setCol({...ic, name: "description", col:"Description", selector: "description", omit: true}),
        ];
  });

  const onSearch = evt => {
    let term = evt.target.value;
    setSearch(evt.target.value);
    setSearch(term);
    if(term === "") {
      setData(ini_data.current);
      return;
    }
    var a = ini_data.current.filter(function(el) {
      return searchAll(el, term);
    });
    setData(a);
  };

  const onRowClick = row => {
    setRow(row);
    setTimeout(() => setShow(true), 100);
  };

  const respondTo = (type, respAct) => {
    setRespTo(respAct);
    setCategType(type);
    setNewRow(true);
    setTimeout(() => setNewShow(true), 100);
  }

  const onDelete = () => {
    am.showConfirm("WARNING!", `You are about to delete correspondence for \"${row.actdate} - ${row.description}\". Are you sure you want to proceed?`,
            () => {
              setShow(false);
              setIsLoading(true);
              DoCorrespondence.deleteCorrespondence(row.actno)
                .then(resp => resp.data.deleteReminder || null)
                .then(deleted => {
                  ac.showInfo(`Correspondence for \"${row.actdate} - ${row.description}\" was successfully deleted.`);
                  setReload(true);
                })
                .catch(resp => {
                  setIsLoading(false);
                  console.error("Mutation Error:", resp);
                  ac.showErrorMsg(`Deleting Correspondence for \"${row.actdate} - ${row.description}\" failed.`);
                });
            });
  }

  const onRowEdit = act => {
    setReload(true);
  }

  const onRowAdded = act => {
    setReload(true);
  }

  const onNewClick = x => {
    setRespTo(null);
    setCategType(x);
    setNewRow(true);
    setTimeout(() => setNewShow(true), 100);
  };

  useEffect(() => {
    ini_data.current = prepareRows(columns, corresps || []);
    setData(ini_data.current);
  }, [corresps]);

  useEffect(() => {
    props.createNew !== null && onNewClick(props.createNew);
  }, [props.createNew]);

  return (
      <div className="">
        {ac.alertMsg}
        <div className="d-flex justify-content-between mb-3">
          <div>
            {UserProfile.canEdit() && <Button style={{backgroundColor: ReceivedCellStyles[0].style.backgroundColor}} disabled={isLoading} size="sm" className="mr-2 btn-falcon-default" onClick={evt => onNewClick(ActType.Received)}><FontAwesomeIcon icon={faEnvelopeOpenText} /> Add a Received Corresp.</Button>}
            {UserProfile.canEdit() && <Button style={{backgroundColor: SentCellStyles[0].style.backgroundColor}} disabled={isLoading} size="sm" className="mr-2 btn-falcon-default" onClick={evt => onNewClick(ActType.Sent)}><FontAwesomeIcon icon={faEnvelope} /> Add a Sent Corresp.</Button>}
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <Button title="Refresh" onClick={() => setReload(true)} size="sm" className="mr-2 btn-falcon-default"><FontAwesomeIcon icon={faSync} /></Button>
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Search</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl key="search-remd" aria-label="Seach" value={search} aria-describedby="inputGroup-sizing-sm" onChange={onSearch} />
              </InputGroup>
            </div>
          </div>
        </div>
        <DataTable
          progressPending={isLoading}
          progressComponent={<LoadingSpinner />}
          columns={columns}
          data={data}
          noHeader
          highlightOnHover
          pointerOnHover
          defaultSortFieldId={getDefaultSortField(dtName).selector}
          defaultSortAsc={getDefaultSortField(dtName).dir}
          onSort={handleTableSort(dtName)}
          onRowClicked={onRowClick}
          dense
          responsive
          fixedHeader
        />
        <div className="d-flex justify-content-between">
          {data.length > 0 ? <p className="font-weight-bold mt-3 mb-0">Found {data.length} Correspondence{data.length ? "s" : ""}.</p> : ""}
        </div>
        {row && <Activity onDelete={onDelete} corresps={corresps} reminders={reminders} show={show} onRowEdit={onRowEdit}
                        onHide={() => setShow(false)} data={row} curcase={props.data} />}
        {newRow && <NewActivity respTo={respTo} onRowAdded={onRowAdded} categType={categType} curCorresps={corresps} curReminders={reminders.filter(x => x.TicklerNo)}
                        caseTypes={caseTypes} show={new_show} onHide={() => setNewShow(false)} curcase={props.data} />}
        {am.modal}
      </div>
  );
}

export default memo(Correspondence);