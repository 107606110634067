import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Card, InputGroup, FormControl, Form, Col, Row, Modal, Spinner, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCalendarAlt, faCalendarWeek, faFilter, faInfoCircle, faSync, faTimes, faPen } from '@fortawesome/free-solid-svg-icons';
import { searchAll, ActTypeBg, parseNulls, getDefaultSortField, setCol, handleChangeRowsPerPage, CaseTypes, CaseTypesString } from '../../lib/helpers';
import { AlertClass, AlertComponent, AlertModalComponent, LoadingSpinner } from '../../components/helpers';
import memoize from 'memoize-one';
import moment from 'moment';
import DoReminder from '../Cases/DoReminder';
import { SelectField, TextArea, TextField } from '../../components/fields';
import { Formik, Form as KiForm } from 'formik';
import DWPage from '../../components/DWPage';
import CaseFilter from '../../components/CaseFilter';
import EditCase from '../Cases/EditCase';
import { OneActivity } from '../Cases/correspondence';
import DoCase from '../Cases/DoCase';
import UserProfile from '../../lib/UserProfile';
import CaseDetails from '../Cases/CaseDetails';

const TableActions = (props) =>  {

    const include = props.includeInactive;

    return (
        <div className="d-flex flex-row align-items-end">
            <ButtonGroup aria-label="Case Filter" className="align-self-start mr-2">
                <Button size="sm" title={`Case Filter ${props.filterApplied ? "Applied" : ""}`} onClick={props.applyCaseFilter}
                    disabled={props.isLoading} className={`${props.filterApplied ? "btn-danger text-white" : "btn-falcon-default"}`}>
                        <FontAwesomeIcon icon={faFilter}/></Button>
                {props.filterApplied && <Button onClick={props.resetCaseFilter} size="sm" title="Reset Filter" className="btn-falcon-danger"><FontAwesomeIcon icon={faTimes}/></Button>}
            </ButtonGroup>
            <Dropdown className="align-self-start">
                <Dropdown.Toggle disabled={props.isLoading} size="sm" className="mr-2 btn-falcon-default" ><FontAwesomeIcon icon={faCog} /></Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={props.showInactiveCases}>{include ? "Hide" : "Show"} Inactive Cases</Dropdown.Item>
                    <Dropdown.Item onClick={props.handleExport}>Export to CSV</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Button disabled={props.isLoading} title="Refresh" onClick={props.onRefresh} size="sm" className="mr-2 align-self-start btn-falcon-default">
                <FontAwesomeIcon icon={faSync} /></Button>
            <InputGroup size="sm" className="align-self-start mr-2 w-50">
                <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-sm">Priority</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control as="select" onChange={props.onPriorityChange} name="priority" value={props.priority || ""} >
                    <option value="">All</option>
                    <option value="1,2">Medium & High</option>
                    <option value="1">High Only</option>
                </Form.Control>
            </InputGroup>
            <div>
                <InputGroup size="sm">
                    <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-sm">Search</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl disabled={props.isLoading} key="search-remd" aria-label="Seach" value={props.search} aria-describedby="inputGroup-sizing-sm" onChange={props.onSearch} />
                </InputGroup>
                <Form.Text className="text-muted">Search with any criteria.</Form.Text>
            </div>
        </div>
    );
}

const RowActions = ({onShowActivity, row}) => {
    const variant = "outline-primary";

    const showAct = useMemo(() => {
        return () => {
            onShowActivity(row);
        }
    });

    return (
        <div className="d-flex flex-row">
            <Button title="Show Correspondence History" size="sm" variant={variant} className="btn-light-border" onClick={showAct}><FontAwesomeIcon icon={faCalendarWeek} /></Button>
        </div>
    );
}

const ic = {name: "", sortable: true, selector: "", allowOverflow:true, wrap: true};
const columns = memoize((onCaseClick, onActClick, onShowActivity) => [
    setCol({...ic, name: "History", selector: "activity", width: "50px", sortable: false, center: true, button: true, ignoreRowClick: true,
        cell: row => <RowActions onShowActivity={onShowActivity} row={row}/>}),
    setCol({...ic, name: "Due Date", col:"DueDate", selector: "duedate", width: "125px"}),
    setCol({...ic, name: "Priority", col:"Priority", selector: "priority", omit: true}),
    setCol({...ic, name: "Matter", col:"Matter", selector: "matter", width: "120px", ignoreRowClick: true,
                    cell: row => <a href="#" onClick={()=> onCaseClick(row)} title="Open Case Details">{row.matter}</a>}),
    setCol({...ic, name: "Title", col:"Title", selector: "title", grow: 2, format: row => row.title && `${ row.title.length < 60 ? row.title : row.title.slice(0, 60) + "..."}`}),
    setCol({...ic, name: "Reminder", col:"Description", selector: "description", grow: 3}),
    setCol({...ic, name: "Generating Corresp", col:"GeneratedBy", selector: "generatedby", ignoreRowClick: true,
                    cell: row => row.ticklertype ? <a href="#" onClick={()=> onActClick(row)} title="Open Activity History">{row.generatedby}</a> : <em>(Ad-hoc)</em>}),
    setCol({...ic, name: "Corresp. Date", col:"ActDate", selector: "actdate", width: "110px"}),
    setCol({...ic, name: "Notes", col:"Notes", selector: "notes", omit: true}),
    setCol({...ic, name: "Tickler No", col:"TicklerNo", selector: "ticklerno", omit: true}),
    setCol({...ic, name: "TicklerType", col:"TicklerType", selector: "ticklertype", omit: true}),
    setCol({...ic, name: "Category", col:"Category", selector: "category", omit: true}),
    setCol({...ic, name: "actno", col:"ActNo", selector: "actno", omit: true}),
    setCol({...ic, name: "caseno", col:"CaseNo", selector: "caseno", omit: true}),
    setCol({...ic, name: "type", col:"TypeNo", selector: "type", omit: true}),
    // setCol({...ic, name: "title", col:"Title", selector: "title", omit: true}),
    setCol({...ic, name: "status", col:"Status", selector: "status", omit: true}),
    setCol({...ic, name: "manualdate", col:"ManualDate", selector: "manualdate", omit: true}),
    setCol({...ic, name: "active", col:"Active", selector: "active", omit: true}),
  ]);
const CaseColumns = [
    setCol({...ic, name: "Country", col: "Country", selector: "country"}),
    setCol({...ic, name: "Status", col: "Status", selector: "status"}),
    setCol({...ic, name: "Application No", col: "ApplicationNo", selector: "applicationno"}),
    setCol({...ic, name: "Filing Date", col: "FilingDate", selector: "filingdate"}),
    setCol({...ic, name: "Issue Date", col: "IssueDate", selector: "issuedate"}),
    setCol({...ic, name: "Client Matter", col: "ClientMatter", selector: "clientmatter"}),
    setCol({...ic, name: "Client", col: "Client", selector: "client"}),
    setCol({...ic, name:"Case Type", col: "CaseType", selector: "casetype",
                format: row=>CaseTypesString[row.casetype], useInExport: true}),
]
const RowStyles = [
    {
        // less than 1 week before due date
        when: row => {
        return row.priority == 2 && moment(row.duedate).diff(moment(), "days") <= 7;
        },
        style: {
        backgroundColor: "#edff84",
        color: "black"
        }
    },
    {
        // less than 1 before due date
        when: row => {
        return row.priority == 2 && moment(row.duedate).diff(moment(), "days") > 7;
        },
        style: {
        backgroundColor: "#f8ffd0",
        color: "black"
        }
    },
    {
        // less than 1 week
        when: row => {
        return row.priority == 1 && moment(row.duedate).diff(moment(), "days") <= 7;
        } ,
        style: {
        backgroundColor: "#ff9898",
        color: "black"
        }
    },
    {
        // more than 1 week
        when: row => {
          return row.priority == 1 && moment(row.duedate).diff(moment(), "days") > 7;
        } ,
        style: {
          backgroundColor: "#ffd0d0",
          color: "black"
        }
      }
  ];

const ReminderDetails = props => {
    const {data, onHide} = props;
    const ac = AlertClass();
    const [rem, setRem] = useState(null);
    const [res, setRes] = useState(<Card body><LoadingSpinner/></Card>);

    useEffect(() => {
        DoReminder.getReminder(data.ticklerno).then(rem => {
            rem = parseNulls(rem, new DoReminder());
            setRem(rem);
        }).catch(err => {
            console.error("Query Error:", err);
            ac.showErrorMsg("Error fetching reminder details.");
            setRes("");
        })
    }, [data]);

    const handleSubmit = (values, helpers) => {
        helpers.setSubmitting(true);
        let cfData = {
            ActNo: values.ActNo,
            TicklerNo: values.TicklerNo,
            DateCompleted: values.DateCompleted || "",
            Description: values.Description,
            DueDate: values.DueDate || "",
            Notes: values.Notes || "",
            ManualDate: values.ManualDate || "",
            Priority: values.Priority
          };
        delete values.Matter;
        delete values.ActType;
        let dorem = new DoReminder(cfData);

        dorem.updateReminder()
            .then(resp => resp.data.editReminder || {})
            .then(rem => {
                helpers.setSubmitting(false);
                if(rem) {
                    ac.showInfo("Reminder successfully updated");
                    props.onRowEdit(rem);
                    setTimeout(onHide, 500);
                } else {
                    ac.showErrorMsg("Editing this reminder failed.");
                }
            })
            .catch(x => {
                console.error("Mutation Error:", x);
                ac.showErrorMsg("Editing the reminder failed.");
                helpers.setSubmitting(false);
            });
    }

    const handleValidate = (values) => {
        let errors = {};
        if(values.Description == "")
          errors.Description = "Please enter a description";

        return errors;
    }

    return (
        <Fragment>
            {ac.alertMsg}
            {!rem && res}
            {rem &&
                <Formik isSubmitting initialValues={rem} onSubmit={handleSubmit} validate={handleValidate}>
                    {({values, isSubmitting, ...props}) => (
                        <KiForm>
                            <Card>
                                <Card.Header className={`h5 py-2 text-gray ${data && !data.actno && "bg-secondary text-white"} card-header ${data && ActTypeBg[data.category]}`}>
                                    {data ? "Edit Reminder" : "Ad-hoc Reminder"}
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                    <Col>
                                        <TextArea required name="Description" label="Description" value={values.Description} rows={2}></TextArea>
                                        <Row>
                                            <TextField as={Col} name="DueDate" label="Generated Reminder Date" disabled type="date" value={values.DueDate} />
                                            <TextField as={Col} name="ManualDate" label="Override Reminder Date" type="date" value={values.ManualDate}/>
                                        </Row>
                                        <Row>
                                            <Col xs={8}><TextField name="DateCompleted" label="Date Completed" type="date" value={values.DateCompleted}/></Col>
                                            <Col>
                                                <SelectField name="Priority" label="Priority" value={values.Priority}>
                                                    <option value=""> -- </option>
                                                    <option value="2"> Medium </option>
                                                    <option value="1"> High </option>
                                                </SelectField>
                                            </Col>
                                        </Row>
                                        <TextArea name="Notes" label="Notes" value={values.Notes} rows={5}></TextArea>
                                    </Col>
                                    <Col xs={4} >
                                        <div className="border p-3 rounded form-group shadow-sm bg-light position-sticky">
                                        {data.actno && <div className="">
                                            <span className="font-weight-bold mt-3">Generated By:</span>
                                            <p>{data.generatedby} — <span className="text-nowrap">{ moment(data.actdate).format("MMMM Do YYYY")}</span></p>
                                        </div>
                                        }
                                        {!data.actno && <p className="font-weight-bold">Ad hoc reminder.</p>}
                                        </div>
                                    </Col>
                                    </Row>
                                    {UserProfile.canEdit() && <Button variant="primary" type="submit" name="save" disabled={isSubmitting} size="sm" className="mr-2 btn-falcon-primary">
                                            {isSubmitting ? <>Saving <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></> : "Save"}
                                    </Button>}
                                    <Button variant="outline-secondary" type="button" name="cancel" size="sm btn-falcon-default" onClick={onHide}>{UserProfile.canEdit() ? "Cancel" : "Close"}</Button>
                                </Card.Body>
                                </Card>
                        </KiForm>
                    )}
                </Formik>}
        </Fragment>
    )
}

const ReminderTypeField = ({values, ...props}) => {
    const isValid = val => val ? "valid" : "";
    return (
        <div class="d-flex mt-3">
            <Form.Label className="font-weight-bold text-right mr-2 align-self-center mb-0">Reminder Type:</Form.Label>
            <TextField freeEdit type="text" value={values.Reminder} name="Reminder" groupClass="mb-0 flex-fill" className={isValid(values.Reminder)} />
        </div>
    )
};

const ProtectionTypeField = ({values, protectionTypes}) => {
    const isValid = val => val ? "valid" : "";
    return (
        <div class="d-flex mt-3 mb-2">
            <Form.Label className="text-right mr-2 align-self-center">Protection Type:</Form.Label>
            <SelectField freeEdit as={Col} name="ProtectionType" value={values.ProtectionType} groupClass="mb-0" className={isValid(values.ProtectionType)}>
                <option key="0" value="">All</option>
                {protectionTypes && protectionTypes.map(x => (
                    <option key={x.typeNo} value={x.typeNo}>{x.name}</option>
                ))}
            </SelectField>
        </div>
    );
};

const DateRangeForm = ({values, ...props}) => {
    const [showDates, setShowDates] = useState(values.isCustom);

    function handleAdvancedPeriod() {
        if(showDates) {
            values.ToDate = ""
            values.FromDate = "";
            values.upcoming = "1-months";
        } else {
            values.prior = "";
            values.upcoming = "";
            values.FromDate = moment().format("YYYY-MM-DD");
            values.ToDate = moment().add(1, "months").format("YYYY-MM-DD");
        }
        values.isCustom = !showDates;
        setShowDates(!showDates);
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-start mb-2">
                <Form.Label className={`${!showDates ? "font-weight-bold" : ""} text-right mr-2 align-self-center mb-0`}>For the Prior:</Form.Label>
                <SelectField disabled={showDates} freeEdit name="prior" value={values.prior} groupClass="mb-0 mr-4">
                    <option value=""> -- </option>
                    <option value="1-weeks">1 Week</option>
                    <option value="2-weeks">2 Weeks</option>
                    <option value="1-months">1 Month</option>
                    <option value="3-months">3 Months</option>
                    <option value="6-months">6 Months</option>
                </SelectField>
                <Form.Label className={`${!showDates ? "font-weight-bold" : ""} text-right mr-2 align-self-center mb-0`}>To the upcoming:</Form.Label>
                <SelectField disabled={showDates} freeEdit name="upcoming" value={values.upcoming} groupClass="mb-0 mr-2">
                    <option value=""> -- </option>
                    <option value="1-weeks">1 Week</option>
                    <option value="2-weeks">2 Weeks</option>
                    <option value="1-months">1 Month</option>
                    <option value="3-months">3 Months</option>
                    <option value="6-months">6 Months</option>
                </SelectField>
                <Button variant="sm" className="btn-link flex-fill" onClick={handleAdvancedPeriod}><FontAwesomeIcon icon={faCalendarAlt} />&nbsp;{showDates ? "Hide":"Show"} Custom Dates</Button>
            </div>
            <div className={`justify-content-start p-2 ${showDates ? "d-flex bg-light":"d-none"}`}>
                <Form.Label className="font-weight-bold text-right mr-2 align-self-center mb-0">From: </Form.Label>
                <TextField freeEdit type="date" value={values.FromDate} name="FromDate" groupClass="mb-0"/>
                <Form.Label className="font-weight-bold text-right mx-2 align-self-center mb-0">To: </Form.Label>
                <TextField freeEdit type="date" value={values.ToDate} name="ToDate" groupClass="mb-0"/>
            </div>
        </Fragment>
    )
}

class Reminders extends DWPage {

    PAGE_NAME = "Reminders";

    constructor(props) {
        super(props);
        if(!this.defaultSort.selector) {
            this.defaultSort = {selector: "duedate", dir: true, name:"Due Date"};
        }

        this.rr_filter = UserProfile.getProp("rr_filter") || null;
        this.model = new DoReminder().extendModel();
        this.state = {data: [], show: false, corsShow: false, CorsRow: null, editShow: false, row: null, search: "", from: "", to: "", includeInactive: false, priority: null,
                        caseModal: null, init_data: [], isLoading: true, countries: [], showFilter: false, filterShow: false, filterApplied: false, showDateFilter: false,
                        actRow: null, caseRow: null, actShow: false, caseShow: false, rowStyles: RowStyles, curSort: this.defaultSort.name, CaseTypes: [], protectionTypes: []};
        this.columns = columns(this.onCaseClick, this.onActClick, this.onShowActivity);
        this.am = React.createRef();
        this.alert = React.createRef();
    }

    async exportCallback() {
        this.setState({isLoading: true});
        let {from, to} = this.recalculateDateRange();
        this.setState({from: from, to: to});
        let sort = this.getOrderBy(), filter = this.getCaseFilter();

        if(UserProfile.getProp("rr_filter")) {
            this.setState({filterApplied: true});
        }
        return DoReminder.getAllReminders(from, to, sort.selector, sort.dir, this.state.priority, filter)
            .then(rems => {
                this.setState({isLoading: false});
                let columns = [...this.columns, ...CaseColumns];
                let rows = this.prepareRows(columns, [...rems.ActReminders, ...rems.AdHocReminders]);
                return {data: rows, columns: columns};
            })
            .catch(err => {
                console.log("Query error: ", err);
                let m = <span>An Error has occured. Please <a href="#" onClick={() => window.location.reload()}>reload the page</a> to try again.</span>;
                this.alert.current.showErrorMsg(m);
                this.setState({isLoading: false});
                return [[], this.columns];
            });
    }

    recalculateDateRange(newRange) {
        let from, to,
            frange = newRange || this.rr_filter || {};

        if(frange.isCustom) {
            from = frange.FromDate || moment().format("YYYY-MM-DD");
            to = frange.ToDate || moment().format("YYYY-MM-DD")
        } else {
            from = frange.prior ? moment().subtract(...frange.prior.split("-")).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
            to = frange.upcoming ? moment().add(...frange.upcoming.split("-")).format("YYYY-MM-DD"): moment().add(1, "months").format("YYYY-MM-DD");
        }

        return {from: from, to: to};
    }

    getCaseFilter() {
        let caseFilter = this.rr_filter || null;
        if(!caseFilter) {
            caseFilter = {Active: !this.state.includeInactive, FromDate: "", ToDate: "", upcoming: "1-months", prior: "", isCustom: false};
        }
        else
            caseFilter.Active = !this.state.includeInactive;

        return caseFilter;
    }

    componentDidMount() {
        this.getData();
        // let {types} = this.context;
        this.setState({CaseTypes: CaseTypes, protectionTypes: this.context.types});
    }

    getOrderBy() {
        let sort = getDefaultSortField(this.constructor.name);
        if(!sort.selector) {
            sort = {selector: "duedate", dir: true};
        }
        sort.dir = !sort.dir ? "desc" : "asc";
        return sort;
    }

    getData() {
        this.setState({isLoading: true});
        let {from, to} = this.recalculateDateRange();
        this.setState({from: from, to: to});
        let sort = this.getOrderBy(), filter = this.getCaseFilter();

        if(UserProfile.getProp("rr_filter")) {
            this.setState({filterApplied: true});
        }

        DoReminder.getAllReminders(from, to, sort.selector, sort.dir, this.state.priority, filter)
            .then(rems => {
                let data = [...rems.ActReminders, ...rems.AdHocReminders];
                let rows = this.prepareRows(this.columns, data);
                this.setState({data: rows, init_data: rows, isLoading: false});
            })
            .catch(err => {
                console.log("Query error: ", err);
                let m = <span>An Error has occured. Please <a href="#" onClick={() => window.location.reload()}>reload the page</a> to try again.</span>;
                this.alert.current.showErrorMsg(m);
                this.setState({isLoading: false});
            });
    }

    searchData() {
        let term = this.state.search.trim();

        if(!term) {
            return this.state.init_data;
        }
        return this.state.init_data.filter(el => searchAll(el, term));
    }

    onRefresh = () => {
        this.setState({isLoading: true, caseType: 0, filterApplied: false, search: ""});
        this.getData();
    }

    onPriorityChange = evt => {
        let value = evt.target.value;
        this.setState({priority: value || null}, this.getData);
    }

    onShowActivity = row => {
        this.setState({CorsRow: row}, () => this.setState({corsShow: true}));
    }

    onCaseClick = row => {
        this.setState({caseRow: row}, () => this.setState({caseShow: true}));
    }

    onActClick = row => {
        this.setState({actRow: row}, () => this.setState({actShow: true}));
    }

    onCaseRowEdit = ncase => {
        let alertMsg = `Case \"${ncase.Matter} -- ${ncase.Title}\" was successfully updated.`;

        if(ncase.newStatus) {
            this.context.statuses.push(ncase.newStatus);
            this.updateContext({newsStatuses: this.context.statuses})
        }


        this.state.caseRow.matter = ncase.Matter;
        this.state.caseRow.title = ncase.Title;

        this.alert.current.showInfo(alertMsg);
        this.state.init_data.forEach(x => {
            if(x.caseno === ncase.CaseNo) {
                x.matter = ncase.Matter;
                x.title = ncase.Title;
            }
        })

        this.setState({isLoading: true}, () => {this.setState({isLoading: false})});
    }

    onCaseDelete = () => {
        let row = this.state.caseRow;
        this.am.current.showConfirm("WARNING!", `Deleting case "${row.matter} - ${row.title}". Are you sure you want to proceed?`,
                () => {
                this.setState({caseShow: false});
                DoCase.deleteCase(row.caseno)
                    .then(resp => resp.data.deleteCase || null)
                    .then(deleted => {
                        this.alert.current.showInfo(`Case "${row.matter} - ${row.title}" was successfully deleted.`);
                        this.setState({isLoading: true});
                        this.getData({FromDate: this.state.from, ToDate: this.state.to});
                    })
                    .catch(resp => {
                        console.error("Mutation Error:", resp);
                        this.alert.current.showErrorMsg(`Deleting case "${row.matter} - ${row.title}" failed.`);
                    });
                });
    }

    onSearchByChange = val => {
        this.setState({searchBy: val}, () => this.setState({data: this.searchData()}));
    }

    onRowSearch = evt => {
        let term = evt.target.value;
        this.setState({search: term});
        this.setState({search: term}, () => this.setState({data: this.searchData()}));
    }

    onRowClick = row => {
        this.setState({row: row, show: true});
    }

    onRowEdit = rem => {
        Object.keys(rem).map(x => this.state.row[x.toLowerCase()] = rem[x]);
        this.setState({data: this.state.data, init_data: this.state.init_data, isLoading: true}, () => setTimeout(() => this.setState({isLoading: false}), 1000));
    }

    onActivityRowEdit = act => {
        setTimeout(() => this.setState({actRow: null}), 500);
    }

    onShowFilter = evt => {
        this.setState({showFilter: !this.state.showFilter});
    }

    applyCaseFilter = () => {
        this.setState({filterShow: true, showDateFilter: false});
    }

    showInactiveCases = () => {
        this.setState({includeInactive: !this.state.includeInactive}, this.getData);
    }

    onApplyDateFilter = (values, helpers) => {
        values.isCustom = false;
        this.rr_filter = this.rr_filter ? {...this.rr_filter, ...values} : values;
        UserProfile.persist("rr_filter", this.rr_filter);
        this.onApplyFilter(values, true, helpers);
    }

    onApplyFilter = (values, hasValues, helpers) => {
        this.setState({isLoading: true, priority: null, filterApplied: hasValues, search: ""});
        if(values.isCustom) {
            values.prior = "";
            values.upcoming = "";
        } else {
            values.FromDate = "";
            values.ToDate = "";
        }
        let {from, to} = this.recalculateDateRange(values);
        this.setState({from: from, to: to});
        helpers.setSubmitting(true);
        this.rr_filter = hasValues ? values : null;
        let sort = this.getOrderBy();
        let filter = this.getCaseFilter();
        DoReminder.getAllReminders(from, to, sort.selector, sort.dir, this.state.priority, filter)
            .then(rems => {
                helpers.setSubmitting(false);
                let data = [...rems.ActReminders, ...rems.AdHocReminders];
                let rows = this.prepareRows(this.columns, data);
                this.setState({data: rows, init_data: rows, isLoading: false});
            })
            .catch(err => {
                helpers.setSubmitting(false);
                console.error("Query error: ", err);
                let m = <span>An Error has occured. Please <a href="#" onClick={() => window.location.reload()}>reload the page</a> to try again.</span>;
                this.alert.current.showErrorMsg(m);
                this.setState({isLoading: false});
            });
    }

    resetCaseFilter = () => {
        UserProfile.unsetProp("rr_filter");
        this.rr_filter = null;
        this.setState({priority: null, filterApplied: false, search: "", showDateFilter: false}, this.getData);
    }

    sortedBy = () => {
        let col = this.columns.find(x => x.col === this.state.curSort);
        return (col && col.name);
    }

    render() {
        return (
            <Fragment>
                {<AlertComponent ref={this.alert} />}
                <Card body className={`mb-3 page-height page-ctrl-2 no-table-header ${this.state.showDateFilter ? "extanded-filter" : ""}`}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <h3 className="page-title mb-1"><FontAwesomeIcon icon={faCalendarAlt} /> Reminders Report</h3>
                            <h6 className="mb-0">Showing reminders from <span className="text-primary">{moment(this.state.from).format("MMM D, YYYY")}</span> to <span className="text-primary">{moment(this.state.to).format("MMM D, YYYY")}</span>
                                <Button className="px-1" variant="link" size="sm" onClick={()=>this.setState({showDateFilter: !this.state.showDateFilter})}><FontAwesomeIcon icon={faPen} /></Button>
                            </h6>
                            {this.state.showDateFilter &&
                            <div className="border p-2 rounded shadow-sm bg-light mb-3">
                                <Formik isSubmitting initialValues={{prior: this.rr_filter ? this.rr_filter.prior : "", upcoming: this.rr_filter ? this.rr_filter.upcoming : ""}}
                                    onSubmit={this.onApplyDateFilter}
                                    validate={(values) => {
                                        if(values.prior == "" && values.upcoming == "")
                                            return {upcoming: "Must choose an option."};
                                    }}>
                                    {({values, isSubmitting, ...props}) => (
                                        <KiForm noValidate >
                                            <div className="d-flex justify-content-start">
                                                <div>
                                                    <Form.Label className="">For the Prior:</Form.Label>
                                                    <SelectField freeEdit name="prior" value={values.prior} groupClass="mb-0">
                                                        <option value=""> -- </option>
                                                        <option value="1-weeks">1 Week</option>
                                                        <option value="2-weeks">2 Weeks</option>
                                                        <option value="1-months">1 Month</option>
                                                        <option value="3-months">3 Months</option>
                                                        <option value="6-months">6 Months</option>
                                                    </SelectField>
                                                </div>
                                                <div className="ml-2">
                                                    <Form.Label className="">To the upcoming:</Form.Label>
                                                    <SelectField freeEdit name="upcoming" value={values.upcoming} groupClass="mb-0">
                                                        <option value=""> -- </option>
                                                        <option value="1-weeks">1 Week</option>
                                                        <option value="2-weeks">2 Weeks</option>
                                                        <option value="1-months">1 Month</option>
                                                        <option value="3-months">3 Months</option>
                                                        <option value="6-months">6 Months</option>
                                                    </SelectField>
                                                </div>
                                                <div className="ml-2 align-self-end">
                                                    <Button type="submit" size="sm" variant="link" className="px-1">Apply</Button>
                                                    <Button size="sm" variant="link" className="px-1" onClick={() => this.setState({showDateFilter: false})}>Close</Button>
                                                </div>
                                            </div>
                                        </KiForm>
                                    ) }
                                </Formik>
                                <span className="text-muted form-text"><small>For custom dates click the Case Filter button.</small></span>
                            </div>}
                            <p className="mb-0"><small><FontAwesomeIcon icon={faInfoCircle} /> List of reminders ordered by {this.sortedBy()}.</small></p>
                        </div>
                        <div className="">
                            <TableActions search={this.state.search} isLoading={this.state.isLoading} onSearch={this.onRowSearch} onAddNewRow={this.onAddNewRow} onRefresh={this.onRefresh}
                                            includeInactive={this.state.includeInactive} filterApplied={this.state.filterApplied} applyCaseFilter={this.applyCaseFilter}
                                            showInactiveCases={this.showInactiveCases} onPriorityChange={this.onPriorityChange} priority={this.state.priority}
                                            resetCaseFilter={this.resetCaseFilter} handleExport={this.downloadCSV} />
                        </div>
                    </div>
                    <DataTable
                        key="rpt-rem-table"
                        keyField="ticklerno"
                        progressPending={this.state.isLoading}
                        progressComponent={<LoadingSpinner />}
                        columns={this.columns}
                        data={this.state.data}
                        conditionalRowStyles={this.state.rowStyles}
                        allowOverflow={true}
                        responsive
                        noHeader
                        fixedHeader
                        defaultSortFieldId={this.defaultSort.selector}
                        defaultSortAsc={this.defaultSort.dir}
                        onSort={this.handleTableSort}
                        pagination
                        paginationPerPage={UserProfile.getRowsPerPage()}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        paginationRowsPerPageOptions={[25, 50, 100, 200]}
                        pointerOnHover
                        highlightOnHover
                        onRowClicked={this.onRowClick}
                        paginationComponentOptions={{rowsPerPageText: 'Show reminders: ', rangeSeparatorText: 'To', selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                    />
                    <div className="font-weight-bold rows-total">{this.state.data.length} reminders found{this.state.includeInactive ? " / Inactive cases included." : "."}</div>
                </Card>
                <AlertModalComponent ref={this.am} />
                {this.state.row &&
                    <Modal show={this.state.show} onHide={() => this.setState({show: false})} className="page-modal scrollbar page-2" dialogClassName="modal-65w">
                        <Modal.Header closeButton>
                            <div className="modal-title">
                                <span className="cdNum">Matter: </span>{this.state.row.matter}
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <ReminderDetails onRowEdit={this.onRowEdit} data={this.state.row} onHide={() => this.setState({show: false})}/>
                        </Modal.Body>
                    </Modal>}
                {this.state.actRow && <OneActivity show={this.state.actShow} onDelet={this.onActivityDelete} onRowEdit={this.onActivityRowEdit} onHide={() => this.setState({actShow: false})} actRow={this.state.actRow} />}
                {this.state.caseRow && <EditCase onRowEdit={this.onCaseRowEdit} onDelete={this.onCaseDelete} show={this.state.caseShow} onHide={() => this.setState({caseShow: false})} data={this.state.caseRow} />}
                {this.state.CorsRow && <CaseDetails createNew={null} show={this.state.corsShow} onHide={() => this.setState({corsShow: false})} data={this.state.CorsRow} />}
                <CaseFilter title="Reminders" types={this.state.CaseTypes} protectionTypes={this.state.protectionTypes} filterVar="rr_filter" show={this.state.filterShow} onHide={() => this.setState({filterShow: false})} onApplyFilter={this.onApplyFilter} >
                    <h5 className="mb-2">Show Reminders For:</h5>
                    <DateRangeForm />
                    <ReminderTypeField />
                    <hr/>
                    <h5 className="mb-2">Filter Cases By:</h5>
                    <ProtectionTypeField />
                </CaseFilter>
            </Fragment>
        );
    }
}

export default memo(Reminders);