import {Modal, Button, Form, Col } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from 'react';
import { ReactAsyncSelect, SelectField, TextField } from "./fields";
import { Formik, Form as KiForm } from 'formik';
import { getCountries, getStatuses } from "../lib/DoHelpers";
import DoParties from "../pages/Parties/DoParties";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserProfile from "../lib/UserProfile";

export default function CaseFilter (props) {

  const filterVar = props.filterVar || "cl_filter";
  const modal_title = props.title || "Case";
  const types = props.types || [];
  const protectionTypes = props.protectionTypes || [];
  let elements = React.Children.toArray(props.children);
  const initFileterVals = {Title: "", Matter: "", Status: "", AppNo: "", Patent: "", Country: "",
                          Attorney: "", Client: "", FilingDate: {Before: "", After: ""}, IssueDate: {Before: "", After: ""},
                          FromDate: "", ToDate: "", upcoming: "1-months", prior: "", isCustom: false, Reminder: "", ProtectionType: ""}
  const [filterVals, setFilterVals] = useState(initFileterVals);

  const onValidate = values => {

  }

  const onSubmit = (values, helpers) => {
    let hasValues = o => Object.keys(o).find(x => (o[x] && o[x].constructor.name == "Object") ? hasValues(o[x]) : o[x]);
    UserProfile.persist(filterVar, values);

    props.onApplyFilter(values, hasValues(values), helpers);
    props.onHide();
  }

  const onReset = (values, helpers) => {
    UserProfile.unsetProp(filterVar);
    props.onApplyFilter(initFileterVals, false, helpers);
    props.onHide();
  }

  const onHide = () => props.onHide();

  useEffect(() => {
    setFilterVals({...filterVals, ...(UserProfile.getProp(filterVar) || initFileterVals)});
  }, [UserProfile.getUser().storage[filterVar]])

  const isValid = val => val ? "valid" : "";

  return (
    <Fragment>
      <Modal show={props.show} onHide={props.onHide} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FontAwesomeIcon icon={faFilter} /> {modal_title} Filter
            </Modal.Title>
          </Modal.Header>

            <Formik isSubmitting initialValues={filterVals} validate={onValidate} onSubmit={onSubmit} onReset={onReset}>
              {({values, isSubmitting, ...props}) => (
                <KiForm noValidate>
                  <Fragment>
                    <Modal.Body>
                      {elements.map(x => React.cloneElement(x, {values: values, protectionTypes: protectionTypes}))}
                      <Form.Row>
                        <Col xs={8}><TextField freeEdit name="Title" label="Title" value={values.Title} className={isValid(values.Title)} /></Col>
                        <TextField freeEdit as={Col} name="Matter" value={values.Matter} label="Matter #" className={isValid(values.Matter)} />
                      </Form.Row>
                      <Form.Row>
                        <TextField freeEdit as={Col} name="AppNo" value={values.AppNo} label="Application No" className={isValid(values.AppNo)}/>
                        <TextField freeEdit as={Col} name="Patent" value={values.Patent} label="Patent #" className={isValid(values.Patent)}/>
                        <SelectField freeEdit as={Col} name="TypeNo" value={values.TypeNo} label="Case Type" className={isValid(values.TypeNo)}>
                          <option key="0" value="">All</option>
                          {Object.keys(types).map( (x, i) => (
                              <option key={`cs-${i}`} value={types[x]}>{x}</option>
                          ))}
                        </SelectField>
                      </Form.Row>
                      <Form.Row>
                        <ReactAsyncSelect freeEdit as={Col} label="Status" name="Status" value={values.Status} className={isValid(values.Status)}
                          cacheOptions defaultOptions loadOptions={getStatuses} isClearable isSearchable />
                        <ReactAsyncSelect freeEdit as={Col} label="Country" name="Country" value={values.Country} className={isValid(values.Country)}
                          cacheOptions defaultOptions loadOptions={getCountries} isClearable isSearchable  />
                      </Form.Row>
                      <Form.Row>
                        <Col xs={7} className="border rounded">
                          <Form.Row>
                            <Col xs={9} className="ml-0">
                              <ReactAsyncSelect freeEdit label="Attorney" name="Attorney" value={values.Attorney} className={isValid(values.Attorney)}
                              cacheOptions defaultOptions loadOptions={DoParties.getAttorneys} isClearable isSearchable/>
                            </Col>
                            <Col xs={3}>
                              <SelectField freeEdit label="Position" name="AttorneyPosition" value={values.AttorneyPosition}>
                                {["--", "First", "Second", "Third or Later"].map( (x, i) => (
                                  <option key={`at-${i}`} value={i}>{x}</option>
                                ))}
                              </SelectField>
                            </Col>
                          </Form.Row>
                        </Col>
                        <ReactAsyncSelect freeEdit as={Col} label="Client" name="Client" value={values.Client} className={isValid(values.Client)}
                          cacheOptions defaultOptions loadOptions={DoParties.getClients} isClearable isSearchable/>
                      </Form.Row>
                      <Form.Row>
                        <Col xs={2} className="align-self-center"><Form.Label>Filing Date:</Form.Label></Col>
                        <Col>
                          <Form.Row>
                            <TextField freeEdit type="date" as={Col} label="Before" name="FilingDate.Before" value={values.FilingDate.Before} className={isValid(values.FilingDate.Before)}/>
                            <TextField freeEdit type="date" as={Col} label="After" name="FilingDate.After" value={values.FilingDate.After} className={isValid(values.FilingDate.After)}/>
                          </Form.Row>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                      <Col xs={2} className="align-self-center"><Form.Label>Issue Date:</Form.Label></Col>
                      <Col>
                        <Form.Row>
                          <TextField freeEdit type="date" as={Col} label="Before" name="IssueDate.Before" value={values.IssueDate.Before} className={isValid(values.IssueDate.Before)}/>
                          <TextField freeEdit type="date" as={Col} label="After" name="IssueDate.After" value={values.IssueDate.After} className={isValid(values.IssueDate.After)}/>
                        </Form.Row>
                      </Col>
                    </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button type="submit" className="btn-falcon-primary mr-2" size="sm">Apply</Button>
                      <Button type="reset" className="btn-falcon-default mr-2" size="sm">Reset</Button>
                      <Button type="button" className="btn-falcon-default" size="sm" onClick={onHide}>Close</Button>
                    </Modal.Footer>
                  </Fragment>
                </KiForm>
              )}
            </Formik>
      </Modal>
    </Fragment>
  );
}
