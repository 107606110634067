import React, { Fragment, memo, useEffect, useMemo, useState, } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Card, InputGroup, FormControl, Form, Col, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { getCountries, handleChangeRowsPerPage, parseNulls, PartyTypes, searchAll, setCol } from '../../lib/helpers';
import { AlertClass, AlertModalComponent, LoadingSpinner } from '../../components/helpers';
import memoize from 'memoize-one';
import DoParties from './DoParties';
import { Formik, Form as KiForm } from 'formik';
import { ReactSelect, TextArea, TextField } from '../../components/fields';
import { Parties } from '../../components/DWPage';
import UserProfile from '../../lib/UserProfile';


const TableActions = (props) =>  {

    return (
        <div className="d-flex flex-row align-items-center">
            {UserProfile.canEdit() && <Button disabled={props.isLoading} className="align-self-start mr-2 btn-falcon-primary" size="sm" onClick={props.onAddNewRow}><FontAwesomeIcon icon={faPlus} /> Add New Attorney</Button>}
            <Button disabled={props.isLoading} title="Refresh" onClick={props.onRefresh} size="sm" className="align-self-start mr-2 btn-falcon-default"><FontAwesomeIcon icon={faSync} /></Button>
            <div>
                <InputGroup size="sm">
                    <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-sm">Search</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl disabled={props.isLoading} key="search-remd" aria-label="Seach" value={props.search} aria-describedby="inputGroup-sizing-sm" onChange={props.onSearch} />
                </InputGroup>
                <Form.Text className="text-muted">Search with any criteria.</Form.Text>
            </div>
        </div>
    );
}

const ic = {name: "", sortable: true, selector: "", allowOverflow:true, center: false, wrap: true};
const columns = memoize(onDelete => [
    setCol({...ic, name: "ID", col: "Id", selector: "id", omit: true, width:"80px", wrap: false, center: true}),
    // setCol({...ic, name: "Initials", col: "ShortName", selector: "initials", width: "100px", center: true, wrap: false}),
    setCol({...ic, name: "Last Name", col: "LastName", selector: "lastname"}),
    setCol({...ic, name: "Firs tName", col: "FirstName", selector: "firstname"}),
    setCol({...ic, name: "Company", col: "Company", selector: "company"}),
    setCol({...ic, name: "Phone", col: "Phone", selector: "phone"}),
    setCol({...ic, name: "Mobile", col: "Mobile", selector: "mobile"}),
    setCol({...ic, name: "Country", col: "AddressCountry", selector: "country"}),
]);

const EditAttorney = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [party, setParty] = useState(null);
    const ac = AlertClass();
    const {data, countries, onHide, onDelete} = props;

    const onSubmit = (values, helpers) => {
        let ctr = values.AddressCountry.constructor.name == "Object" ? values.AddressCountry.value : values.AddressCountry;
        let ct = values.Citizenship.constructor.name == "Object" ? values.Citizenship.value : values.Citizenship;
        values.AddressCountry = ctr;
        values.Citizenship = ct;
        delete values.isNew;

        if(!DoParties.isValidType(values.PartyType)) {
            ac.showErrorMsg("Unrecognized party. Please contact support for more info.");
            helpers.setSubmitting(false);
            return;
        }

        let dp = new DoParties(values);
        dp.persist()
            .then(attr => {
                helpers.setSubmitting(false);
                if(attr) {
                    props.onRowEdit(attr);
                    props.onHide();
                } else {
                    ac.showErrorMsg("Editing attorney failed. Please try again.");
                }
            })
            .catch(x => {
                console.error("Mutation Error:", x);
                ac.showErrorMsg("Editing the attorney failed.");
                helpers.setSubmitting(false);
            });
    }

    useEffect(() => {
        setIsLoading(true);
        ac.hideAll();

        if(data && data.isNew){
            setParty(data);
            setIsLoading(false);
        } else {
            data && DoParties.getParty(data.id)
                        .then(pt => {
                            pt = parseNulls(pt, new DoParties());
                            setParty(pt);
                            setIsLoading(false);
                        })
                        .catch(err => {
                            console.error("Query Error:", err);
                            setIsLoading(false);
                            ac.showErrorMsg("Error retreiving attorney data. Please try again.")
                        });
        }
    }, [data]);

    return (
        <Modal show={props.show} onHide={onHide} className="page-modal scrollbar page-2"
            dialogClassName="modal-65w"
        >
        <Modal.Header closeButton>
          <div className="modal-title">
            <h5>{!data.isNew ? "Edit" : "Add New"} Attorney - <span>{!data.isNew && `${data.firstname} ${data.lastname}`}</span></h5>
          </div>
        </Modal.Header>
        {isLoading && <Modal.Body>{ac.alertMsg} <Card body><LoadingSpinner/></Card></Modal.Body>}
        {!isLoading &&
            <Formik
                isSubmitting
                initialValues={{...party}}
                validate={(values) => {
                    let errors = {};
                    if(values.FirstName === "") {
                        errors.FirstName = "First Name is required!";
                    }
                    if(values.LastName === "") {
                        errors.LastName = "Last Name is required!";
                    }

                    return errors;
                }}
                onSubmit={onSubmit}
            >
            {({values, isSubmitting, ...props}) =>
                {return (values && <KiForm noValidate className="modal-content" style={{maxHeight: "calc(100vh - 61px)"}}>
                    <Modal.Body>
                        {ac.alertMsg}
                        <Card body className="mb-3 bg-light">
                            <TextField label="Firm Name" name="Company" value={values.Company} />
                            <Form.Row>
                                <TextField as={Col} label="First Name" name="FirstName" value={values.FirstName} />
                                <TextField as={Col} label="Last Name" name="LastName" value={values.LastName} />
                            </Form.Row>
                            <TextField label="Email" name="Email" type="email" value={values.Email} />
                            <Form.Row>
                                <TextField as={Col} label="Office Phone" name="Phone" value={values.Phone} />
                                <TextField as={Col} label="Mobile" name="Mobile" value={values.Mobile} />
                            </Form.Row>

                            <TextField label="Fax" name="Fax" value={values.Fax} />
                        </Card>
                        <Card body className="mb-3 bg-light">
                            <TextArea label="Address 1" name="Address1" value={values.Address1} rows={2}/>
                            <TextArea label="Address 2" name="Address2" value={values.Address2} rows={2}/>
                            <Form.Row>
                                <TextField as={Col} label="City" name="City" value={values.City} />
                                <TextField as={Col} label="State" name="State" value={values.State} />
                                <TextField as={Col} label="Zip" name="Zip" value={values.Zip} />
                            </Form.Row>
                            <Form.Row>
                                <ReactSelect as={Col} label="Country" name="AddressCountry" value={countries.find(x => x.value === values.AddressCountry)}
                                            options={countries}/>
                                <ReactSelect as={Col} label="Citizenship" name="Citizenship" value={countries.find(x => x.value === values.Citizenship)}
                                            options={countries}/>
                            </Form.Row>
                            <TextArea label="Notes" name="Notes" value={values.Notes} rows={5}/>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-start">
                        <Form.Group>
                        {UserProfile.canEdit() ?
                            <Fragment>
                                <Button type="submit" disabled={isSubmitting} className="mr-2" size="sm">{isSubmitting ? <>Saving <Spinner size="sm" as="span" animation="border" role="status" aria-hidden="true" /></> : "Save"}</Button>
                                <Button type="reset" disabled={isSubmitting} onClick={onHide} className="btn-falcon-default" size="sm" >Cancel</Button>
                                {!data.isNew && <span className="mx-3 text-muted">|</span>}
                                {!data.isNew && <Button type="reset" disabled={isSubmitting} onClick={onDelete} className="btn-falcon-danger" size="sm"><FontAwesomeIcon icon={faTrashAlt} /> Delete</Button>}
                            </Fragment> :
                            <Button type="reset" disabled={isSubmitting} onClick={onHide} className="btn-falcon-default" size="sm" >Close</Button>}
                        </Form.Group>
                    </Modal.Footer>
                </KiForm>)}
            }
        </Formik>}
      </Modal>
    )
}

class Attorneys extends Parties {

    PAGE_NAME = "Attorneys";

    constructor(props) {
        super(props);
        this.state = {data: [], show: false, editShow: false, row: null, search: "",
                        caseModal: null, init_data: [], isLoading: true, countries: []};
        this.columns = columns(this.onDelete);
        this.am = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount.apply(this);
        this.getData();
        if(this.context.countries) {
            this.setState({countries: this.context.countries.map(k => ({value: k.label, label: k.label}))});
        } else {
            getCountries().then(x => this.setState({countries: x.map(k => ({value: k.name, label: k.name}))}) );
        }
    }

    getData() {
        DoParties.getAttorneyList()
            .then(attr => {
                let rows = this.prepareRows(this.columns, attr);
                this.setState({data: rows, init_data: rows, isLoading: false});
            })
            .catch(err => {
                console.error("Query error: ", err);
                let m = <span>An Error has occured. Please <a href="#" onClick={() => window.location.reload()}>reload the page</a> to try again.</span>;
                this.alert.current.showErrorMsg(m);
                this.setState({isLoading: false});
            });
    }

    searchData() {
        let term = this.state.search.trim();

        if(!term) {
            return this.state.init_data;
        }
        return this.state.init_data.filter(el => searchAll(el, term));
    }

    onSearchByChange = val => {
        this.setState({searchBy: val}, () => this.setState({data: this.searchData()}));
    }

    onRowSearch = evt => {
        let term = evt.target.value;
        this.setState({search: term});
        this.setState({search: term}, () => this.setState({data: this.searchData()}));
    }

    onRowClick = (x) => {
        x.id !== undefined && this.setState({row: x});
        this.setState({show: true});
    }

    onAddNewRow = evt => {
        let agt = {
            isNew: true, PartyType: PartyTypes.Attorney, Id: null, FirstName: "", LastName: "", Company: "", Organization: "",
            Phone: "", Mobile: "", Email: "", Notes: "", Address1: "", Address2: "", City: "", State: "", Zip: "",
            AddressCountry: "", ResidenceCountry: "", Citizenship: "", Fax: ""
        }
        this.setState({row: agt}, ()=> this.setState({show: true}));
    }

    onRowEdit = attr => {
        let alert = `Attorney \"${attr.FirstName} ${attr.LastName}\" was successfully `;
        alert += this.state.row.isNew ? "added." : "updated.";

        this.alert.current.showInfo(alert);

        this.state.row.id = attr.Id;
        this.state.row.shortname = attr.ShortName;
        this.state.row.firstname = attr.FirstName;
        this.state.row.lastname = attr.LastName;
        this.state.row.company = attr.Company;
        this.state.row.phone = attr.Phone;
        this.state.row.mobile = attr.Mobile;
        this.state.row.email = attr.Email;
        this.state.row.country = attr.Country;

        if(this.state.row.isNew) {
            delete this.state.row.isNew;
            let a = [this.state.row, ...this.state.data];
            this.setState({data: a, init_data: a, isLoading: true}, () => this.setState({isLoading: false}));
        } else {
            this.setState({data: this.state.data, isLoading: true}, () => setTimeout(() => this.setState({isLoading: false}), 200));
        }
    }

    onDelete = () => {
        let row = this.state.row;
        this.am.current.showConfirm("WARNING!", `Deleting attorney "${row.firstname} ${row.lastname}". Are you sure you want to proceed?`,
                () => {
                this.setState({show: false});
                DoParties.deleteParty(row.id)
                    .then(resp => resp.data.deleteParty || null)
                    .then(deleted => {
                        this.alert.current.showInfo(`Attorney "${row.firstname} ${row.lastname}" was successfully deleted.`);
                        let a = this.state.data.filter(x => x.id != row.id);
                        this.setState({data: a, init_data: a, isLoading: true},
                                        () => this.setState({isLoading: false}));
                    })
                    .catch(resp => {
                        console.error("Mutation Error:", resp);
                        this.alert.current.showErrorMsg(`Deleting attorney "${row.firstname} ${row.lastname}" failed.`);
                    });
                });
    }

    onEditRow = row => {
        this.setState({editShow: false, row: this.state.row});
    }

    onRefresh = () => {
        this.setState({isLoading: true, search: ""});
        this.getData();
    }

    render() {
        return (
            <Fragment>
                {this.isContextReady() && <Fragment>
                    {this.alertCmp}
                    <Card body className="page-height no-table-header">
                        <div className="d-flex justify-content-between">
                            <h3 className="page-title">Attorney List</h3>
                            <div className="">
                                <TableActions search={this.state.search} isLoading={this.state.isLoading} onSearch={this.onRowSearch} onAddNewRow={this.onAddNewRow} onRefresh={this.onRefresh}/>
                            </div>
                        </div>
                        <DataTable
                            key="attr-table"
                            keyField="id"
                            progressPending={this.state.isLoading}
                            progressComponent={<LoadingSpinner />}
                            columns={this.columns}
                            data={this.state.data}
                            allowOverflow={true}
                            defaultSortFieldId={this.defaultSort.selector}
                            defaultSortAsc={this.defaultSort.dir}
                            onSort={this.handleTableSort}
                            responsive
                            noHeader
                            fixedHeader
                            pagination
                            paginationPerPage={UserProfile.getRowsPerPage()}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            paginationRowsPerPageOptions={[25, 50, 100, 200]}
                            pointerOnHover
                            highlightOnHover
                            onRowClicked={this.onRowClick}
                            paginationComponentOptions={{rowsPerPageText: 'Show attorneys: ', rangeSeparatorText: 'To', selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                            expandableRows
                            expandableRowDisabled={row => row.disabled}
                            expandableRowsComponent={this.state.CaseList}
                        />
                        <div className="font-weight-bold rows-total">{this.state.data.length} client found.</div>
                    </Card>
                    {this.state.row
                            && <EditAttorney onRowEdit={this.onRowEdit} onDelete={this.onDelete} show={this.state.show} onHide={() => this.setState({show: false})} data={this.state.row}
                                countries={this.state.countries}
                                />}
                    <AlertModalComponent ref={this.am} />
                </Fragment>}
            </Fragment>
        );
    }
}

export default memo(Attorneys);