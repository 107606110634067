import React, { Fragment, memo, useMemo, } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Card, InputGroup, Form, FormControl, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync, faFilter, faCalendarWeek, faCog } from '@fortawesome/free-solid-svg-icons';
import CaseDetails from './CaseDetails';
import { CaseTypes, CaseTypesString, handleChangeRowsPerPage, searchAll, setCol } from '../../lib/helpers';
import { AlertComponent, AlertModalComponent, LoadingSpinner, PromptModalComponent } from '../../components/helpers';
import memoize from 'memoize-one';
import EditCase from './EditCase';
import DoCase from './DoCase';
import DWPage from '../../components/DWPage';
import CaseFilter from '../../components/CaseFilter';
import UserProfile from '../../lib/UserProfile';


const RowActions = ({onShowActivity, row}) => {
    const variant = "outline-primary";

    const showAct = useMemo(() => {
        return () => {
            onShowActivity(row);
        }
    });

    return (
        <div className="d-flex flex-row">
            <Button title="Show Correspondence History" size="sm" variant={variant} className="btn-light-border" onClick={showAct}><FontAwesomeIcon icon={faCalendarWeek} /></Button>
        </div>
    );
}

const TableActions = (props) =>  {
    const types = props.types;

    return (
        <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex justify-content-end">
                {UserProfile.canEdit() && <Button disabled={props.isLoading} className="btn-falcon-default mr-2" size="sm" onClick={props.onAddNewRow}><FontAwesomeIcon icon={faPlus} /> Add New Case</Button>}
                <Button disabled={props.isLoading} title={`Case Filter ${props.filterApplied ? "Applied" : ""}`} className={`mr-2 ${props.filterApplied ? "btn-danger text-white" : "btn-falcon-default"}`} size="sm" onClick={props.onFilter}><FontAwesomeIcon icon={faFilter} /></Button>
                <Dropdown>
                    <Dropdown.Toggle disabled={props.isLoading} size="sm" className="mr-2 btn-falcon-default" ><FontAwesomeIcon icon={faCog} /></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={props.onShowInactive}>{props.showInactive ? "Hide" : "Show"} Inactive Cases</Dropdown.Item>
                        <Dropdown.Item onClick={props.handleExport}>Export to CSV</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button disabled={props.isLoading} title="Refresh" onClick={props.onRefresh} size="sm" className="mr-2 btn-falcon-default"><FontAwesomeIcon icon={faSync} /></Button>
            </div>
            <div className="d-flex justify-content-between flex-nowrap">
                <InputGroup size="sm" className="mr-2">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Protection Type</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" onChange={props.onFilterByType} value={props.caseType}>
                        <option key="0" value="">All</option>
                        {types && types.map(x => (
                            <option key={x.typeNo} value={x.typeNo}>{x.name}</option>
                        ))}
                    </Form.Control>
                </InputGroup>
                <InputGroup size="sm">
                    <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-sm">Search</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl key="search-remd" aria-label="Search" value={props.search} aria-describedby="inputGroup-sizing-sm" onChange={props.onSearch} />
                </InputGroup>
            </div>
        </div>
    );
}

export const RowStyles = [
    {
      when: row => {
        return !row.active;
      },
      style: {
        backgroundColor: "#f7f7f7",
        fontStyle: "italic"
      }
    }
  ];
const ic = {name: "", sortable: true, selector: "", allowOverflow:true, center: false, wrap: true, style:{wordBreak: "normal"}};
const columns = memoize((onShowActivity) => [
    setCol({...ic, name: "Activity", selector: "activity", width: "50px", sortable: false, center: true, button: true, ignoreRowClick: true,
        cell: row => <RowActions onShowActivity={onShowActivity} row={row}/>}),
    setCol({...ic, name: "Type No", col: "TypeNo", selector: "type", omit: true}),
    setCol({...ic, name: "Case No", col: "CaseNo", selector: "caseno", omit: true}),
    setCol({...ic, name: "Matter", col: "Matter", selector: "matter", center: false}),
    setCol({...ic, name: "Country", col: "CountryName", selector: "country"}),
    setCol({...ic, name: "Status", col: "StatusName", selector: "status", center: false}),
    setCol({...ic, name: "Title", col: "Title", selector: "title", center: false, grow: 4, wrap: true,
            format: row => row.title && `${ row.title.length < 80 ? row.title : row.title.slice(0, 80) + "..."}`,
            style: { whiteSpace: 'wrap', textOverflow: 'ellipses', width: "max-content"} }),
    setCol({...ic, name: "Application No", col: "ApplicationNo", selector: "applicationno"}),
    setCol({...ic, name: "Filing Date", col: "FilingDate", selector: "filingdate"}),
    setCol({...ic, name: "Issue Date", col: "IssueDate", selector: "issuedate"}),
    setCol({...ic, name: "Client Matter", col: "ClientMatter", selector: "clientmatter", center: false}),
    setCol({...ic, name: "Client", col: "ClientName", selector: "client", center: false, wrap: true, grow:2,
            format: row => `${row.client ? (row.client.length < 45 ? row.client : row.client.slice(0, 45) + "...") : ""}`}),
    setCol({...ic, col: "ParentCase", selector: "parentcase", omit: true}),
    setCol({...ic, col: "Active", selector: "active", omit: true}),
    setCol({...ic, name:"Case Type", col: "UtilityDesign", selector: "utilitydesign", omit: false,
                format: row=>CaseTypesString[row.utilitydesign], useInExport: true}),
    setCol({...ic, name: "Reported Notes", col: "ReportedNotes", selector: "reportednotes", omit: true, useInExport: true}),
    setCol({...ic, name: "Priority Cases", col: "PriorityCases", selector: "prioritycases", omit: true, useInExport: true}),
    setCol({...ic, name: "Inventors", col: "Inventors", selector: "inventors", omit: true, useInExport: true}),
]);

class Cases extends DWPage {

    PAGE_NAME = "Cases";

    constructor(props) {
        super(props);
        if(!this.defaultSort.selector) {
            this.defaultSort = {selector: "matter", dir: true};
        }
        this.state = {data: [], show: false, editShow: false, ActRow: null, CaseRow: null, search: "", showInactive: UserProfile.getProp("cl_inactive") || false,
                        caseModal: null, init_data: [], isLoading: true, modalToExit: true, createNewCorresp: null,
                        caseType: "", types: [], statuses: [], countries: [], filterShow: false, filterApplied: false};
        this.columns = columns(this.onShowActivity);
        this.alert = React.createRef();
        this.am = React.createRef();
        this.pr = React.createRef();
    }

    componentDidMount() {
        this.setCaseDeps();
        this.getData();
    }

    setCaseDeps() {
        this.setState({...this.context});
    }

    getData() {
        let hasSavedFilter = UserProfile.getProp("cl_filter");
        if(hasSavedFilter) {
            this.setState({caseType: 0, filterApplied: true, search: ""});
        }
        DoCase.getCaseList(hasSavedFilter)
            .then(cases => {
                let rows = this.prepareRows(this.columns, cases);
                if(this.state.showInactive) {
                    this.setState({data: rows, init_data: rows, isLoading: false});
                } else {
                    this.setState({data: rows.filter(x => x.active), init_data: rows, isLoading: false});
                }
            })
            .catch(err => {
                console.error("Query error: ", err);
                let m = <span>An Error has occured. Please <a href="#" onClick={() => window.location.reload()}>reload the page</a> to try again.</span>;
                this.alert.current.showErrorMsg(m);
                this.setState({isLoading: false});
            });
    }

    searchData() {
        let type     = this.state.caseType;
        let term     = this.state.search;
        term = term.trim();

        if(!term && !type) {
            return this.state.showInactive ? this.state.init_data : this.state.init_data.filter(x => x.active);
        }
        var a = this.state.init_data.filter(el => {
            if(!term && type) {
                return this.state.showInactive ? el.type.toString() === type : el.active && el.type.toString() === type;
            }
            let l = searchAll(el, term);
            if(this.state.showInactive)
                return !type ? l : l && el.type.toString() === type;
            else
                return !type ? el.active && l : el.active && l && el.type.toString() === type;
        });

        return a;
    }

    onRefresh = () => {
        this.setState({isLoading: true, caseType: 0, filterApplied: false, search: ""});
        this.getData();
    }

    onCopyCase = () => {
        let row = this.state.CaseRow;
        this.pr.current.prompt("Copy Case: " + row.matter, "Enter a new Matter Number", row,
            async (matter) => {
                // Validation callback
                let isUnique = await DoCase.isMatterUnique(matter).catch(err => {
                    console.error("Query error: ", err);
                    let m = <span>An Error has occured. Please <a href="#" onClick={() => window.location.reload()}>reload the page</a> to try again.</span>;
                    this.alert.current.showErrorMsg(m);
                    return false;
                });
                if(!isUnique)
                    return "Matter " + matter + " already exists. Please enter a new one.";

            },
            (matter) => {
                // Submit callback
                DoCase.copyCase(matter, row.caseno).then(ccase => {
                    let ncase = {
                        isNew: true, matter: "", title: "", status: "", caseno: null
                    };
                    this.setState({CaseRow: ncase, editShow: false}, () => this.onRowEdit(ccase, null));
                }).catch(err => {
                    console.error("Mutation error:", err);
                    let m = "Copping Case " + row.matter + " failed!. Please contact support for more information.";
                    this.alert.current.showErrorMsg(m);
                })
        });
    }

    onRowSearch = evt => {
        let term = evt.target.value;
        this.setState({search: term});
        this.setState({search: term}, () => this.setState({data: this.searchData()}));
    }

    onRowClick = row => {
        this.setState({editShow: true, CaseRow: row});
    }

    onFilterByType = evt => {
        var type = evt.target.value;
        this.setState({caseType: type}, () => this.setState({data: this.searchData()}));
    }

    onFilter = evt => {
        this.setState({filterShow: true});
    }

    onApplyFilter = (values, hasValues) => {
        this.setState({isLoading: true, caseType: 0, filterApplied: hasValues});
        DoCase.getCaseList(values)
            .then(cases => {
                let rows = this.prepareRows(this.columns, cases);
                if(this.state.showInactive) {
                    this.setState({data: rows, init_data: rows, isLoading: false, search: ""});
                } else {
                    this.setState({data: rows.filter(x => x.active), init_data: rows, isLoading: false, search: ""});
                }
            })
            .catch(err => {
                console.error("Query error: ", err);
                let m = <span>Oops! Something went wrong. Could not filter cases.</span>;
                this.alert.current.showErrorMsg(m);
                this.setState({isLoading: false});
            });
    }

    onShowInactive = () => {
        let  a = this.state.showInactive ? this.state.init_data.filter(el => el.active) : this.state.init_data;
        UserProfile.persist("cl_inactive", !this.state.showInactive);
        this.setState({showInactive: !this.state.showInactive, data: a, search: "", caseType: 0});
    }

    onAddNewRow = () => {
        let ncase = {
            isNew: true, matter: "", title: "", status: "", caseno: null
        }
        this.setState({CaseRow: ncase, editShow: true});
    }

    onShowActivity = row => {
        row.caseno !== undefined && this.setState({ActRow: row, show: true});
    }

    onDelete = () => {
        let row = this.state.CaseRow;
        this.am.current.showConfirm("WARNING!", `Deleting case "${row.matter} - ${row.title}". Are you sure you want to proceed?`,
                () => {
                this.setState({editShow: false});
                DoCase.deleteCase(row.caseno)
                    .then(resp => resp.data.deleteCase || null)
                    .then(deleted => {
                        this.alert.current.showInfo(`Case "${row.matter} - ${row.title}" was successfully deleted.`);
                        this.setState({data: this.state.data.filter(x => x.caseno != row.caseno),
                                    init_data: this.state.init_data.filter(x => x.caseno != row.caseno), isLoading: true},
                                        () => this.setState({isLoading: false}));
                    })
                    .catch(resp => {
                        console.error("Mutation Error:", resp);
                        this.alert.current.showErrorMsg(`Deleting case "${row.matter} - ${row.title}" failed.`);
                    });
                });
    }

    onRowEdit = (ncase, saveNCreate) => {
        let alert = `Case \"${ncase.Matter} -- ${ncase.Title}\" was successfully `;
        alert += this.state.CaseRow.isNew ? "added." : "updated.";

        if(ncase.newStatus) {
            this.state.statuses.push(ncase.newStatus);
            this.setState({statuses: this.state.statuses});
            this.updateContext({newStatuses: this.state.statuses})
        }

        this.alert.current.showInfo(alert);

        this.state.CaseRow.matter = ncase.Matter;
        this.state.CaseRow.country = ncase.CountryName;
        this.state.CaseRow.status = ncase.StatusName;
        this.state.CaseRow.title = ncase.Title;
        this.state.CaseRow.applicationno = ncase.ApplicationNo;
        this.state.CaseRow.filingdate = ncase.FilingDate;
        this.state.CaseRow.issuedate = ncase.IssueDate;
        this.state.CaseRow.clientmatter = ncase.ClientMatter;
        this.state.CaseRow.client = ncase.ClientName;
        this.state.CaseRow.provisionalsn = ncase.ProvisionalSN;
        this.state.CaseRow.patentno = ncase.PatentNo;
        this.state.CaseRow.type = ncase.TypeNo;
        this.state.CaseRow.active = ncase.Active;
        this.state.CaseRow.reportednotes = ncase.ReportedNotes;

        this.state.CaseRow.prioritycases = ncase.PriorityCases;
        this.state.CaseRow.inventors = ncase.Inventors;

        if(this.state.CaseRow.isNew) {
            delete this.state.CaseRow.isNew;
            this.state.CaseRow.caseno = ncase.CaseNo;
            let a = [this.state.CaseRow, ...this.state.data];
            this.setState({data: a, init_data: a
                            , isLoading: true}, () => {
                                setTimeout(() => this.setState({isLoading: false}), 200);
                                if(saveNCreate !== null) {
                                    this.setState({createNewCorresp: saveNCreate, show: true, ActRow: this.state.CaseRow});
                                }
                            });
        } else {
            this.setState({data: this.state.data, isLoading: true}, () => setTimeout(() => this.setState({isLoading: false}), 200));
        }
    }

    render() {
        return (
            <Fragment>
                {this.isContextReady() && <Fragment>
                    {<AlertComponent ref={this.alert} />}
                    <Card body className="page-height no-table-header">
                        <div className="d-flex justify-content-between mb-3">
                            <h3 className="page-title">Case List</h3>
                            <div className="">
                                <TableActions onRefresh={this.onRefresh} types={this.state.types} filterApplied={this.state.filterApplied}
                                        search={this.state.search} onSearch={this.onRowSearch} isLoading={this.state.isLoading} onFilter={this.onFilter}
                                        onFilterByType={this.onFilterByType} caseType={this.state.caseType} onAddNewRow={this.onAddNewRow}
                                        onShowInactive={this.onShowInactive} showInactive={this.state.showInactive} handleExport={this.downloadCSV} />
                            </div>
                        </div>
                        <DataTable
                            key="cases-table"
                            keyField="caseno"
                            progressPending={this.state.isLoading}
                            progressComponent={<LoadingSpinner />}
                            columns={this.columns}
                            data={this.state.data}
                            conditionalRowStyles={RowStyles}
                            allowOverflow={true}
                            responsive
                            defaultSortFieldId={this.defaultSort.selector}
                            defaultSortAsc={this.defaultSort.dir}
                            onSort={this.handleTableSort}
                            noHeader
                            fixedHeader
                            pagination
                            paginationPerPage={UserProfile.getRowsPerPage()}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            paginationRowsPerPageOptions={[25, 50, 100, 200]}
                            pointerOnHover
                            highlightOnHover
                            onRowClicked={this.onRowClick}
                            paginationComponentOptions={{rowsPerPageText: 'Show cases: ', rangeSeparatorText: 'To', selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                        />
                        <div className="font-weight-bold rows-total">{this.state.data.length} cases found{this.state.showInactive ? " / Inactive cases included." : "."}</div>
                    </Card>
                    {this.state.ActRow && <CaseDetails createNew={this.state.createNewCorresp} show={this.state.show}
                                            onHide={() => this.setState({show: false, createNewCorresp: null})} data={this.state.ActRow} />}
                    { this.state.CaseRow && <EditCase onRowEdit={this.onRowEdit} show={this.state.editShow} onCopyCase={this.onCopyCase}
                                            onDelete={this.onDelete} onHide={() => this.setState({editShow: false})} data={this.state.CaseRow}/> }
                    <CaseFilter filterVar="cl_filter" types={CaseTypes} show={this.state.filterShow} onHide={() => this.setState({filterShow: false})} onApplyFilter={this.onApplyFilter} />
                    <AlertModalComponent ref={this.am} />
                    <PromptModalComponent ref={this.pr} />
                </Fragment>
                }
            </Fragment>
        );
    }
}

export default memo(Cases);