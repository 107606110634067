import React, { useState } from 'react';
import { Component } from 'react';
import { Alert, Button, Fade, Modal, Spinner, Form } from 'react-bootstrap';
import { Formik, Form as KiForm, Field } from 'formik';

export const RedAlert = (props) => {
    const [show, setShow] = useState(true);
    const head = props.title && <Alert.Heading>{props.title}</Alert.Heading>;

    return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible className={!show && "d-none" }>
            {head}
            {props.children}
        </Alert>
    );
};

export const AlertMessage = ({variant, ...props}) => {
    const [show, setShow] = useState(true);
    const head = props.title && <Alert.Heading>{props.title}</Alert.Heading>;

    return (
        <Alert variant={variant} onClose={() => setShow(false)} dismissible className={!show && "d-none" } {...props}>
            {head}
            {props.children}
        </Alert>
    );
};

export const AlertClass = () => {
    const [alertMsg, setAlertMsg] = useState("");

    const showErrorMsg = msg => {
        setAlertMsg("");
        let alert = <RedAlert>{msg}</RedAlert>;
        setAlertMsg(alert);
    };

    const showInfo = msg => {
        setAlertMsg("");
        let alert = <AlertMessage variant="primary">{msg}</AlertMessage>;
        setAlertMsg(alert);
    }

    return {
        showInfo: showInfo,
        showErrorMsg: showErrorMsg,
        alertMsg: alertMsg,
        hideAll: () => setAlertMsg("")
    };
}

export class AlertComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { alertMsg: "", isInfo: false, open: false, in: ""};
    }

    showErrorMsg = msg => {
        this.setState({isInfo: false, open: false},
                        () => this.setState({alertMsg: <RedAlert className="">{msg}</RedAlert>, open: true}),
                                    () => this.setState({in: "in"}));
    };

    showInfo = msg => {
        this.setState({isInfo: true, open: false}, () => this.setState({open: true, alertMsg: <AlertMessage variant="primary" className={` ${this.state.in}`}>
                    <div className="d-flex ">{this.state.isInfo &&
                        <div className="wrapper mr-2 align-self-center">
                            <div className="pie spinner"></div>
                            <div className="pie filler"></div>
                            <div className="mask"></div>
                        </div>}{msg}
                    </div>
                </AlertMessage>, in: "in"}));
        this._timeout();
    }

    _timeout() {
        setTimeout(() => this.setState({isInfo: false, open: false}, () => { this.setState({in: "out"}); setTimeout(() => this.setState({alertMsg: ""}), 300)}), 5000);
    }

    render() {
        return (
            <Fade in={this.state.open}>
                <div>
                    {this.state.alertMsg}
                </div>
            </Fade>
        )
    }
}

export const LoadingSpinner = (props) => (
    <div style={{ padding: '24px', textAlign: "center" }} {...props}>
        <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
        </Spinner>
        <div className="mt-2">Loading...</div>
        {props.children}
    </div>
);

export const AlertModal = props => {
    const [show, setShow] = useState(false);
    const [state, setState] = useState({title: "", message: "", onConfirm: null});

    const showConfirm = (title, message, onConfirm) => {
        setState({title: title, message: message, onConfirm: onConfirm});
        setShow(true);
    }

    return {
        modal: <Modal show={show} onHide={() => setShow(false)} className="alert-modal">
                        <Modal.Header className="bg-danger text-white font-weight-bold py-2" closeButton>{state.title}</Modal.Header>
                        <Modal.Body> {state.message} </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" size="sm" onClick={evt => {setShow(false); state.onConfirm();}}>Yes</Button>
                            <Button variant="secondary" size="sm" onClick={() => setShow(false)}>No</Button>
                        </Modal.Footer>
                    </Modal>,
        showConfirm: showConfirm
    }
};

export class AlertModalComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {show: false, state: {title: "", message: "", onConfirm: null}};
    }

    showConfirm = (title, message, onConfirm) => {
        this.setState({state: {title: title, message: message, onConfirm: onConfirm}, show: true});
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={() => this.setState({show: false})} className="alert-modal" dialogClassName="">
                <Modal.Header className="bg-danger text-white font-weight-bold py-2" closeButton>{this.state.state.title}</Modal.Header>
                <Modal.Body> {this.state.state.message} </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size="sm" onClick={evt => {this.setState({show: false});
                                                                        this.state.state.onConfirm();}}>Yes</Button>
                    <Button variant="secondary" size="sm" onClick={() => this.setState({show: false})}>No</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export class PromptModalComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {show: false, title: "", data: null, SubmitCallback: null, ValidationCallback: null, label: ""};
        this.input = React.createRef();
    }

    componentDidUpdate() {
        this.input.current && this.input.current.focus();
    }

    prompt = (title, label, data, ValidationCallback, SubmitCallback) => {
        this.setState({show: true, title: title, data: data, label: label, SubmitCallback: SubmitCallback, ValidationCallback: ValidationCallback});
    }

    onFormSubmit = (values, helpers) => {
        helpers.setSubmitting(true);
        this.state.ValidationCallback(values.newValue).then(errors => {
            if(errors) {
                helpers.setSubmitting(false);
                helpers.setFieldError("newValue", errors);
                helpers.setStatus({success: false});
                return;
            }
            this.state.SubmitCallback(values.newValue);
            this.setState({show: false});
            helpers.setSubmitting(false);
            helpers.resetForm({})
            helpers.setStatus({success: true})
        });
    }

    onValidate = values => {
        if(!values.newValue) {
            return {newValue: "This field is required"}
        }
    }

    handleInput = input => {
        this.input.current = input;
    }

    render() {
        return (
            <Formik isSubmitting={false} enableReinitialize initialValues={{newValue: ""}} onSubmit={this.onFormSubmit} validate={this.onValidate} >
                {({values, isSubmitting, errors, touched, ...props}) => (
                    <Modal centered show={this.state.show} onHide={() => this.setState({show: false})} className="alert-modal" dialogClassName="">
                        <KiForm noValidate>
                            <Modal.Header className="font-weight-bold" closeButton>{this.state.title}</Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                <Form.Label className={touched.newValue && errors.newValue ? "text-danger" : ""}>{this.state.label}*</Form.Label>
                                <Field disabled={isSubmitting} required innerRef={this.handleInput} name="newValue" className={`form-control ${touched.newValue && errors.newValue ? "is-invalid":""}`} />
                                {errors.newValue && touched.newValue &&
                                    <Form.Text className="text-danger">
                                        {errors.newValue}
                                    </Form.Text>}
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" variant="primary" size="sm" disabled={isSubmitting}>{isSubmitting ? <>Submitting <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" /></> : "Submit"}</Button>
                                <Button type="reset" variant="secondary" size="sm" disabled={isSubmitting} onClick={() => this.setState({show: false})}>Cancel</Button>
                            </Modal.Footer>
                        </KiForm>
                    </Modal>
                )}
            </Formik>
        )
    }
}
