import React, { Fragment, useRef, useState } from 'react';
import { useField } from 'formik';
import { Badge, Button, Form, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { calcDueDate, sortByDate } from '../lib/helpers';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import UserProfile from '../lib/UserProfile';

export const TextField = ({ label, as, helper, value, isHorizontal, groupClass, freeEdit, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group as={as} className={groupClass}>
      {label && <Form.Label className={meta.error ? "text-danger" : ""}>{label}</Form.Label>}
      <Form.Control disabled={!UserProfile.canEdit() && !freeEdit} {...field} {...props} isInvalid={meta.error} />
      {helper &&
        <Form.Text className="text-muted">
            {helper}
        </Form.Text>
      }
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}
    </Form.Group>
  );
};

export const RadioField = ({ label, as, helper, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Fragment>
      <Form.Check disabled={!UserProfile.canEdit() && !props.freeEdit} label={label} type="radio" {...field} {...props} />
      {helper &&
        <Form.Text className="text-muted">
            {helper}
        </Form.Text>
      }
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}
    </Fragment>
  );
};

export const CheckField = ({ label, as, helper, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Fragment>
      <Form.Check disabled={!UserProfile.canEdit() && !props.freeEdit} label={label} type="checkbox" {...field} {...props} isInvalid={meta.error}/>
      {helper &&
        <Form.Text className="text-muted">
            {helper}
        </Form.Text>
      }
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}
    </Fragment>
  );
};

export const TextArea = ({ label, as, rows, helper, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Form.Group as={as}>
      {label && <Form.Label className={meta.error ? "text-danger" : ""}>{label}</Form.Label>}
      <Form.Control as="textarea" disabled={!UserProfile.canEdit() && !props.freeEdit} rows={rows} {...field} {...props} isInvalid={meta.error} />
      {helper &&
        <Form.Text className="text-muted">
            {helper}
        </Form.Text>
      }
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}
    </Form.Group>
  );
};

export const SelectField = ({ label, as, helper, groupClass, freeEdit, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group as={as} className={groupClass}>
      {label && <Form.Label className={meta.error ? "text-danger" : ""}>{label}</Form.Label>}
      <Form.Control as="select" disabled={!UserProfile.canEdit() && !freeEdit} {...field} {...props} isInvalid={meta.error}>
          {props.children}
      </Form.Control>
      {helper &&
          <Form.Text className="text-muted">
              {helper}
          </Form.Text>}
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}

    </Form.Group>
  );
};

export const EditableSelect = ({ label, btnLabel, as, options, helper, ...props }) => {
  const [field, meta] = useField(props);
  const name = props.name;
  const [show, setShow] = useState(false);
  const [btnText, setBtnText] = useState(btnLabel);
  const newValue = useRef("");

  const handleNewValue = evt => {
    let target = evt.target;
    if (!target.classList.contains("in")) {
        setBtnText("Hide New " + props.name);
        setShow(true);
        target.classList.add("in");
        newValue.current.disabled = false
        setTimeout(() => {
          newValue.current.focus();
        }, 10);
    } else {
        setBtnText("New " + name);
        setShow(false);
        newValue.current.disabled = true;
        target.classList.remove("in");
    }
  };

  return (
    <Form.Group as={as}>
      <Form.Label className={meta.error ? "text-danger" : ""}>{label}</Form.Label>
      <div className="d-flex mb-2">
        <div className="flex-grow-1 mr-2">
            <Form.Control as="select" {...field} {...props} isInvalid={meta.error}>
                {props.children}
            </Form.Control>
        </div>
        <Button variant="outline-info" size="sm" onClick={handleNewValue}>{btnText}</Button>
      </div>
      <div id="nst" className={!show ? "d-none" : ""}>
          <Form.Control placeholder={`New ${btnLabel}`} type="text" name={`new-${name}`}
                        disabled ref={newValue} size={props.size} />
          <Form.Text className="text-muted">
              Please type in the new {name.toLowerCase()}.
          </Form.Text>
      </div>
      {helper &&
          <Form.Text className="text-muted">
              {helper}
          </Form.Text>}
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}
  </Form.Group>
  );
};

export const RemindersField = (props) => {

  const {curDate, rems, setRems, removedRems, setRemovedRems, values} = props;

  const handleRemoveRem = evt => {
    let val = parseInt(evt.currentTarget.value);
    let new_rems = rems.filter(x => val !== x.TicklerType);
    let del_rem = rems.find(x => val === x.TicklerType);
    if(del_rem) {
      setRems(sortByDate(new_rems, "DueDate"));
      removedRems.push(del_rem)
      setRemovedRems(sortByDate(removedRems, "DueDate"));
    }
  }

  const handleAddRem = evt => {
    let val = parseInt(evt.currentTarget.value);
    let new_rem = removedRems.find(x => val === x.TicklerType);
    let del_rems = removedRems.filter(x => val !== x.TicklerType);
    if(new_rem) {
      new_rem.DueDate = calcDueDate(new_rem, curDate);
      rems.push(new_rem);
      setRems(sortByDate(rems, "DueDate"));
      setRemovedRems(sortByDate(del_rems, "DueDate"));
    }
  }

  const getRemRule = x => {
    let s = k => k > 1 ? "s" : "";
    let rule = x.Months > 0 ? x.Months + " Month" + s(x.Months) + " ": "";
    rule += x.Weeks > 0 ? (rule && "& ") + x.Weeks + " Week" + s(x.Weeks) + " ": "";
    rule += x.Days > 0 ? (rule && "& ") + x.Days + " Day" + s(x.Days) + " ": "";
    return (<Badge variant="light" title={`This is a ${rule}reminder`}>{rule}</Badge>)
  }

  return (
    <Fragment>
      {props.children}
      <div className="d-flex justify-content-end">
          <div className="w-100">
              <ListGroup defaultActiveKey="#0" className="w-100 multiselect">
                  {rems && sortByDate(rems, "DueDate").map( (x, i) => (
                      <ListGroup.Item as="span" key={`${x.TicklerType}-${i}}`} action bsPrefix={`list-group-item list-group-item-action ${x.DateCompleted ? "font-italic rem-cmp" : ""}`}>
                          <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                {UserProfile.canEdit() && <Button value={x.TicklerType} onClick={handleRemoveRem} name={`rtr-${x.TicklerType}`} size="sm"
                                        variant="outline-secondary" className="xs mr-2"><FontAwesomeIcon icon={faMinus} /></Button>}
                                <FontAwesomeIcon icon={faCalendarCheck} />&nbsp;<span className="semi-bold text-nowrap">{x.ManualDate || x.DueDate}</span>&nbsp;
                                <span className="font-weight-normal">{x.Description} <small className="font-italic help">{x.DateCompleted && `- (completed at ${x.DateCompleted})`}</small></span>
                              </div>
                              {getRemRule(x)}
                          </div>
                      </ListGroup.Item>
                  ))}
                  {rems && rems.length === 0 && <ListGroup.Item >&nbsp;&nbsp;</ListGroup.Item>}
              </ListGroup>
              <SelectField className="d-none" name="reminderIds" multiple value={values.reminderIds || []}>
                  {rems && rems.map( (x, i) => (
                      <option key={`uds-${x.TicklerType}-${i}`} value={x.TicklerType}>{x.TicklerType}</option>
                  ))}
              </SelectField>
          </div>
      </div>
      <div className={removedRems.length > 0 ? "d-block" : "d-none"}>
          <Form.Label>Excluded Reminders</Form.Label>
          <div className="mt-2 d-flex justify-content-end">
              <div className="w-100">
                  <ListGroup defaultActiveKey="#0" className="w-100 multiselect">
                      {removedRems && sortByDate(removedRems, "DueDate").map( (x, i) => (
                          <ListGroup.Item as="div" key={`${i}`} action bsPrefix={`list-group-item list-group-item-action ${x.DateCompleted ? "font-italic rem-cmp" : ""}`}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  {UserProfile.canEdit() && <Button title="Add back to automatic reminders" name={`rta-${x.TicklerType}`} value={`${x.TicklerType}`} onClick={handleAddRem}
                                    size="sm" variant="outline-primary" className="xs mr-2"><FontAwesomeIcon icon={faPlus} /></Button>}
                                  <FontAwesomeIcon icon={faCalendarCheck} />&nbsp;<span className="semi-bold text-nowrap">{x.DueDate || calcDueDate(x, curDate)}</span>&nbsp;<span className="font-weight-normal">{x.Description} <small className="font-italic help">{x.DateCompleted && `- (completed at ${x.DateCompleted})`}</small></span>
                                </div>
                                {getRemRule(x)}
                              </div>
                          </ListGroup.Item>
                      ))}
                      {removedRems && removedRems.length === 0 && <ListGroup.Item >&nbsp;&nbsp;</ListGroup.Item>}
                  </ListGroup>
              </div>
          </div>
      </div>
    </Fragment>
  )
};

export const ReactAsyncSelect = ({component, label, as, helper, ...props}) => {
  const [field, meta, helpers] = useField(props);

  const onChange = selectedValue => {
    helpers.setValue(selectedValue);
  }

  return (
    <Form.Group as={as}>
      {label && <Form.Label className={meta.error ? "text-danger" : ""}>{label}</Form.Label> }
      <div className="form-control p-0 border-0 d-table" is-invalid={meta.error ? "true" : ""} >
        <AsyncSelect {...field} {...props} onChange={onChange} isDisabled={!UserProfile.canEdit() && !props.freeEdit}/>
      </div>
      {helper &&
        <Form.Text className="text-muted">
            {helper}
        </Form.Text>
      }
      {!helper && props.isSearchable && <Form.Text className="text-muted">Type in to search for elements not visible in the list</Form.Text>}
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}

    </Form.Group>
  );
}

export const ReactSelect = ({component, label, as, helper, ...props}) => {
  const [field, meta, helpers] = useField(props);

  const onChange = selectedValue => {
    helpers.setValue(selectedValue);
  }

  return (
    <Form.Group as={as}>
      {label && <Form.Label className={meta.error ? "text-danger" : ""}>{label}</Form.Label> }
      <div className="form-control p-0 border-0 d-table" is-invalid={meta.error ? "true" : ""}>
        <Select {...field} {...props} onChange={onChange} isDisabled={!UserProfile.canEdit() && !props.freeEdit}/>
      </div>
      {helper &&
        <Form.Text className="text-muted">
            {helper}
        </Form.Text>
      }
      {!helper && props.isSearchable && <Form.Text className="text-muted">Type in to search for elements not visible in the list</Form.Text>}
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}

    </Form.Group>
  );
}

export const ReactCreatableSelect = ({component, label, as, helper, ...props}) => {
  const [field, meta, helpers] = useField(props);

  const onChange = selectedValue => {
    helpers.setValue(selectedValue);
  }

  // const handleCreateOption = inputValue => {
  //   helpers.setValue({value: 0, label: inputValue});
  // }

  return (
    <Form.Group as={as}>
      {label && <Form.Label className={meta.error ? "text-danger" : ""}>{label}</Form.Label> }
      <div className="form-control p-0 border-0 d-table" is-invalid={meta.error ? "true" : ""}>
        <Creatable {...field} isDisabled={!UserProfile.canEdit() && !props.freeEdit} {...props} onChange={onChange} />
      </div>
      {helper &&
        <Form.Text className="text-muted">
            {helper}
        </Form.Text>
      }
      {!helper && props.isSearchable && <Form.Text className="text-muted">Choose 'Create' option to add a new element</Form.Text>}
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}

    </Form.Group>
  );
}

export const ReactAsyncCreatableSelect = ({component, label, as, helper, ...props}) => {
  const [field, meta, helpers] = useField(props);

  const onChange = selectedValue => {
    helpers.setValue(selectedValue);
  }

  return (
    <Form.Group as={as}>
      {label && <Form.Label className={meta.error ? "text-danger" : ""}>{label}</Form.Label> }
      <div className="form-control p-0 border-0 d-table" is-invalid={meta.error ? "true" : ""}>
        <AsyncCreatableSelect isDisabled={!UserProfile.canEdit() && !props.freeEdit} {...field} {...props} onChange={onChange} />
      </div>
      {helper &&
        <Form.Text className="text-muted">
            {helper}
        </Form.Text>
      }
      {!helper && props.isSearchable && <Form.Text className="text-muted">Type in to search for elements not visible in the list</Form.Text>}
      {meta.error ? (
        <Form.Text className="text-danger">
          {meta.error}
        </Form.Text>
      ) : null}

    </Form.Group>
  );
}