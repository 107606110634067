
export const EditCase = /* GraphQL */ `
    mutation EditCase($input: CaseInput) {
          editCase(input: $input) {
                CaseNo
                AccountId
                UserId
                Title
                ClientNo
                ClientName
	          ClientCode
                Notes
                Matter
                Examiner
                ArtUnit
                FilingDate
                ParentCase
                Active
                ApplicationNo
                PatentNo
                IssueDate
                Status
                StatusName
                ExpirationDate
                ClientMatter
                PubDate
                PubNo
                GoodsServices
                PriorityDate
                UtilityDesign
                ProvisionalSN
                Country
                CountryName
                IntlClass
                Reel
                Frame
                RecordationDate
                GroupNo
                TypeNo
                ConfirmationNo
                Class
                CustomerNumber
                InternationalRegistration
                EntityStatus
                InternationalPublicationDate
                AIA
                PTOStatus
                PTOStatusDate
                LastUpdate
                parentFilingDate
                inventor
                inventorPhone
                inventorAddress
                assignee
                GroupId
                foreignAgent
                subtechnology
                disclosureRequired
                ReportedNotes
          }
      }
`;

export const copyCase =  /* GraphQL */ `
      mutation copyCase($NewMatter: String!, $AccountId: Int!, $CaseNo: Int!) {
            copyCase(NewMatter: $NewMatter, AccountId: $AccountId, CaseNo: $CaseNo) {
                  CaseNo
                  AccountId
                  UserId
                  Title
                  ClientNo
                  ClientName
                  ClientCode
                  Matter
                  Examiner
                  ArtUnit
                  FilingDate
                  ParentCase
                  Active
                  ApplicationNo
                  PatentNo
                  IssueDate
                  Status
                  StatusName
                  ClientMatter
                  PriorityDate
                  UtilityDesign
                  ProvisionalSN
                  Country
                  CountryName
                  TypeNo
                  ConfirmationNo
            }
      }
`;

export const editCaseParties =  /* GraphQL */ `
      mutation editCaseParties($CaseNo: Int!, $AccountId: Int!, $Parties: String) {
            editCaseParties(CaseNo: $CaseNo, AccountId: $AccountId, Parties: $Parties)
      }
`;

export const editPriorityCases =  /* GraphQL */ `
      mutation editPriorityCases($input: EditPriorityCases) {
            editPriorityCases(input: $input)
      }
`;

export const editIntClasses =  /* GraphQL */ `
      mutation editIntClasses($input: EditIntClasses) {
            editIntClasses(input: $input)
      }
`;

export const deleteCaseParties =  /* GraphQL */ `
      mutation deleteCaseParties($CaseNo: Int!, $AccountId: Int!, $Parties: String) {
            deleteCaseParties(CaseNo: $CaseNo, AccountId: $AccountId, Parties: $Parties)
      }
`;

export const deletePriorityCases =  /* GraphQL */ `
      mutation deletePriorityCases($CaseNo: Int!, $AccountId: Int!, $AppNo: String!) {
            deletePriorityCases(CaseNo: $CaseNo, AccountId: $AccountId, AppNo: $AppNo)
      }
`;

export const deleteIntClasses =  /* GraphQL */ `
      mutation deleteIntClasses($CaseNo: Int!, $AccountId: Int!, $Id: Int!) {
            deleteIntClasses(CaseNo: $CaseNo, AccountId: $AccountId, Id: $Id)
      }
`;

export const editChildCases =  /* GraphQL */ `
      mutation editChildCases($input: EditChildCases) {
            editChildCases(input: $input)
      }
`;

export const createChildCase =  /* GraphQL */ `
      mutation createChildCase($CaseNo: Int!, $AccountId: Int!, $ChildCase: String) {
            createChildCase(CaseNo: $CaseNo, AccountId: $AccountId, ChildCase: $ChildCase)
      }
`;

export const deleteChildCases =  /* GraphQL */ `
      mutation deleteChildCases($CaseNo: Int!, $AccountId: Int!, $RelAppNo: String) {
            deleteChildCases(CaseNo: $CaseNo, AccountId: $AccountId, RelAppNo: $RelAppNo)
      }
`;

export const deleteCase =  /* GraphQL */ `
      mutation deleteCase($CaseNo: Int!, $AccountId: Int!) {
            deleteCase(CaseNo: $CaseNo, AccountId: $AccountId)
      }
`;

export const addGroup =  /* GraphQL */ `
      mutation addGroup($Group: String!, $AccountId: Int!) {
            addGroup(Group: $Group, AccountId: $AccountId)
      }
`;

export const addStatus =  /* GraphQL */ `
      mutation addStatus($Name: String!, $AccountId: Int!) {
            addStatus(Name: $Name, AccountId: $AccountId)
      }
`;

export const AddCorrespondence =  /* GraphQL */ `
      mutation addActivity($input: ActivityInput) {
            addActivity(input: $input) {
                  Type
                  ActNo
                  CaseNo
                  Category
                  ResponseCat
                  Description
                  ProtectionType
                  AccountId
                  ActDate
                  Notes
                  Sent
                  Received
                  RespondsTo
            }
      }
`;

export const EditCorrespondence =  /* GraphQL */ `
      mutation editCorrespondence($input: ActivityInput) {
            editCorrespondence(input: $input) {
                  ActNo
                  CaseNo
                  Category
                  Description
                  AccountId
                  ActDate
                  Notes
                  RespondsTo
            }
      }
`;

export const addReminder = /* GraphQL */ `
    mutation addReminder($input: TicklerInput) {
          addReminder(input: $input) {
            ActNo
            AccountId
            TicklerNo
            DateCompleted
            Description
            DueDate
            Notes
            ManualDate
            ActSetDate
            Priority
          }
      }
`;

export const editReminder = /* GraphQL */ `
    mutation editReminder($input: TicklerInput) {
          editReminder(input: $input) {
            ActNo
            AccountId
            TicklerNo
            DateCompleted
            Description
            DueDate
            Notes
            ManualDate
            ActSetDate
            Priority
          }
      }
`;

export const deleteReminder = /* GraphQL */ `
    mutation deleteReminder($TicklerNo: Int!, $AccountId: Int!) {
          deleteReminder(TicklerNo: $TicklerNo, AccountId: $AccountId)
      }
`;
export const deleteCorrespondence = /* GraphQL */ `
    mutation deleteCorrespondence($ActNo: Int!, $AccountId: Int!) {
          deleteCorrespondence(ActNo: $ActNo, AccountId: $AccountId)
      }
`;
export const editTemplate = /* GraphQL */ `
    mutation editTemplate($input: TemplateInput) {
          editTemplate(input: $input) {
            Category
            Type
            ProtectionTypeName
            Description
            ProtectionType
          }
      }
`;
export const editTemplateReminders = /* GraphQL */  `
      mutation editTemplateReminders($input: TemplateReminderInput) {
            editTemplateReminders(input: $input) { TicklerType AccountId Description Priority Months Weeks Days}
}`;
export const deleteTemplateReminders = /* GraphQL */  `
      mutation deleteTemplateReminders($TicklerType: Int!, $AccountId: Int!) {
            deleteTemplateReminders(TicklerType: $TicklerType, AccountId: $AccountId)
}`;
export const deleteTemplate = /* GraphQL */  `
      mutation deleteTemplate($Type: Int!, $AccountId: Int!) {
            deleteTemplate(Type: $Type, AccountId: $AccountId)
}`;
export const deleteParty = /* GraphQL */  `
      mutation deleteParty($Id: Int!, $AccountId: Int!) {
            deleteParty(Id: $Id, AccountId: $AccountId)
}`;
export const editParty = /* GraphQL */ `
mutation editParty($input: PartyInput) {
      editParty(input: $input) {
            Id
            ShortName
            FirstName
            LastName
            Company
            Organization
            Phone
            Mobile
            Notes
            City
            AddressCountry
            PartyType
      }
  }
`;
export const addCaseAttorneys =  /* GraphQL */ `
      mutation addCaseAttorneys($CaseNo: Int!, $AccountId: Int!, $Attorneys: [Int]) {
            addCaseAttorneys(CaseNo: $CaseNo, AccountId: $AccountId, Attorneys: $Attorneys)
      }
`;
export const trackLastLogin =  /* GraphQL */ `
      mutation trackLastLogin($aws_id: String) {
            trackLastLogin(aws_id: $aws_id)
      }
`;