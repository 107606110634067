import { API, graphqlOperation } from 'aws-amplify';
import { getUser as getDBUser } from '../graphql/queries';
import { trackLastLogin as dbtrackLastLogin } from '../graphql/mutations';

const UserProfile = (function() {
  var user = {};

  const setUser = ($user) => {
    user = $user;
    // localStorage.setItem(user.aws_id, user);
  };

  const getUser = () => {
    return user;
  };

  const persist = (key, value) => {
    user.storage[key] = JSON.stringify(value);
  }

  const getProp = (key) => {
    return user.storage[key] ? JSON.parse(user.storage[key]) : null;
  }

  const canEdit = () => {
    return user.RoleName === "Admin";
  }

  const getRowsPerPage = () => {
    return getProp("rdt-rows") || 50;
  }

  const unsetProp = key => {
    return user.storage && delete user.storage[key];
  }

  async function trackLastLogin(user) {
    return API.graphql(graphqlOperation(dbtrackLastLogin , {
      aws_id: user.username
    }))
    .catch(err => {
      console.log("Query error: ", err);
    });
  }

  async function queryUser(user) {
    return API.graphql(graphqlOperation(getDBUser, {
      aws_id: user.username
    }))
    .then(resp => {
      setUser({...resp.data.getUser, storage: user.storage});
      return user;
    })
    .catch(err => {
      console.log("Query error: ", err);
    });
  };

  return {
    getUser: getUser,
    setUser: setUser,
    queryUser: queryUser,
    persist: persist,
    getProp: getProp,
    unsetProp: unsetProp,
    canEdit: canEdit,
    getRowsPerPage: getRowsPerPage,
    trackLastLogin: trackLastLogin
  }
})();

export default UserProfile;