import React, { Component } from "react";
import { downloadCSV } from "../lib/Export";
import { getDefaultSortField } from "../lib/helpers";
import { CaseList } from "../pages/Parties/CaseList";
import { AlertComponent } from "./helpers";

export const AppContext = React.createContext({});

export default class DWPage extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.defaultSort = getDefaultSortField(this.getPageName());
        this.updateContext = this.updateContext.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
    }

    getPageName = () => this.PAGE_NAME || (Math.random() + 1).toString(36).substring(7);

    isContextReady() {return Object.keys(this.context).length > 0 }

    updateContext(value) {
        this.context.updateContext(this.context, value);
    }

    handleTableSort = (col, dir) => {
        this.defaultSort = {name: col.col, dir: dir !== "desc", selector: col.selector};
        this.state && this.state.curSort && this.setState({curSort: this.defaultSort.name});

        return localStorage.setItem("rdt-" + this.getPageName(),
                        JSON.stringify(this.defaultSort))
    }

    prepareRows(columns, data, hasAction) {
        return data.map(k => {
            let x = {}; columns.map( (c, i) => {if(c.selector !== "actions") x[c.selector] = k[c.col]; return x;});
            if(hasAction)
                x.actions = hasAction;
            return x;
        });
    }

    async downloadCSV() {
        if(!this.columns) {
            throw new Error("No columns to export");
        }

        let {data, columns} = {data: this.state.data, columns: this.columns};

        if(this.exportCallback) {
            let res = await this.exportCallback();
            data = res.data;
            columns = res.columns;
        }

        data = data.sort((a, b) => {
            const aField = a[this.defaultSort.selector] && a[this.defaultSort.selector].toLowerCase();
            const bField = b[this.defaultSort.selector] && b[this.defaultSort.selector].toLowerCase();

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return this.defaultSort.dir === false ? comparison * -1 : comparison;
        });

        return downloadCSV(data, columns, this.getPageName());
    }

}

export class Parties extends DWPage {

    constructor(props) {
        super(props);
        this.alert = React.createRef();
        this.alertCmp = <AlertComponent ref={this.alert} />;
    }

    componentDidMount() {
        this.setState({CaseList: <CaseList context={this.context} updateContext={this.updateContext} alert={this.alert} />})
    }

}